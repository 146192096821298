/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'

import { Primitive, SortOption, TableColumnConfig, TableState, TableToolEvent } from 'lib/types'
import {
  ControlledGenericPrimitiveDropdown,
  ControlledSortOptionDropdown,
  SearchBar,
  TableToolsWrapper,
} from 'components/widgets'
import { PaginatedTable } from 'components/partials/PaginatedTableV2'
import { ROWS_PER_PAGE } from 'lib/constants'
import { trans, TranslationGroup, TranslationKey } from 'lib/types/translation'
// import { Pagination } from 'models/api'
import { Pagination } from 'lib/types/tableTools'

/**
 * This component combines a paginated table with filter, sort, and search functionality.
 * Add additional ActionButtons in children.
 *
 * @param D Type of data to show.
 * @param F Data type of filter options (usually string)
 * @param C Columns enum. Should have string values.
 */
export const PreConfiguredTableView = <
  D extends { id: string }, // Data type
  F extends Primitive, // Filter type
  C extends unknown, // Columns enum
>({
  children,
  columnConfigs,
  hideFilter,
  filterOptions,
  rowsPerPage = ROWS_PER_PAGE,
  page = 1,
  sortOptions,
  sortedData,
  tableState,
  getRowToolTip,
  onHeaderClick,
  onRowClick,
  onToolEvent,
  hiddenColumns,
  selectedRowId,
  loading,
  disablePagination,
  noDataMessage,
  onPageChange,
  pagination,
}: {
  sortedData: D[]
  tableState: TableState<F, C>
  columnConfigs: TableColumnConfig<C, D>[]
  hideFilter?: boolean
  filterOptions: F[]
  sortOptions: SortOption<C>[]
  rowsPerPage?: number
  page?: number
  children?: React.ReactNode
  getRowToolTip?: (row: D) => string
  onHeaderClick: (header: C) => void
  onRowClick?: (row: D) => void
  onToolEvent?: (toolEvent: TableToolEvent<F, C>) => void
  hiddenColumns?: string[]
  selectedRowId?: string
  loading?: boolean
  disablePagination?: boolean
  noDataMessage?: string
  onPageChange?: (page: any, newPage: any) => void
  pagination?: Pagination
}) => {
  const leftStyles = {
    display: 'flex',
    flex: '1 0 auto',
  }

  const rightStyles = {
    display: 'flex',
    flex: '0 0 auto',
  }
  const translation: TranslationGroup = trans.merge(TranslationKey.TABLE_VIEW)

  return (
    <>
      <TableToolsWrapper>
        <div css={leftStyles}>
          {!hideFilter && (
            <ControlledGenericPrimitiveDropdown<F>
              prefix={`${translation.Filter}:`}
              options={filterOptions}
              value={tableState.filterBy}
              onChange={value => onToolEvent && onToolEvent({ filterByRequest: value })}
            />
          )}
          <ControlledSortOptionDropdown<C>
            prefix={`${translation.Sort}:`}
            options={sortOptions}
            value={tableState.sortBy}
            onChange={value => onToolEvent && onToolEvent({ sortByRequest: value })}
          />
          <SearchBar
            initialValue={tableState.searchBy}
            prefix={translation.Search}
            onChange={value => onToolEvent && onToolEvent({ searchByRequest: value })}
          />
        </div>
        <div css={rightStyles}>{children}</div>
      </TableToolsWrapper>
      <PaginatedTable<D, C>
        sortBy={tableState.sortBy}
        sortedData={sortedData}
        columnConfigs={columnConfigs}
        hiddenColumns={hiddenColumns}
        rowsPerPage={rowsPerPage}
        page={page}
        getRowHealthTooltip={getRowToolTip}
        onHeaderClick={onHeaderClick}
        onRowClick={onRowClick}
        selectedRowId={selectedRowId}
        loading={loading}
        disablePagination={disablePagination}
        noDataMessage={noDataMessage}
        onPageChange={onPageChange}
        pagination={pagination}
      />
    </>
  )
}
