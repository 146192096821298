/** @jsxImportSource @emotion/react */
import { HealthLabelsKeys, RespondersColumnKeys } from 'lib/constants'
import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import {
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  Device,
  TranslationGroup,
  trans,
  TranslationKey,
  ucfirst,
  ResponderType,
  HealthCode,
  ResponderV4,
} from 'lib/types'
import { createSortOption, formatDistanceToNow, generateCSV, getHealth, parseTime } from 'lib/utils'
import {
  HighlightOff as HighlightOffIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'
import { Devices, LiveResponders, Orgs, Responders } from 'models'
import { getBatteryIcon } from 'styles'
import { ResponderV5, AlertTargetStatus, AlertTargetForwardType } from 'lib/types'
import { TargetStatusValue } from 'models/Responders'
import { differenceInMilliseconds } from 'date-fns'
import { Tooltip } from '@material-ui/core'
import { getDeviceTooltip } from 'lib/utils/common'
import { isMyEmail } from 'lib/utils/auth'
import { getRespondersCSV } from 'models/api/responders'

export function getFilterOptions() {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  return [
    ucfirst(translation.all),
    ucfirst(translation.users),
    ucfirst(translation.devices),

    /* Health filters need backend implementation */
    // ucfirst(translation.offline),
    // ucfirst(translation.moderate),
    // ucfirst(translation.online_now),
  ]
}

export function getMembershipFromResponder(responder: ResponderV5, joinChar?: string) {
  const { orgsById } = Orgs.store.getState()
  return responder.orgIds.map(oid => orgsById[oid]?.name).join(joinChar || ', ')
}

export function getFilterOptionKeys() {
  // return ['all', 'users', 'devices', HealthLabelsKeys.CRITICAL, HealthLabelsKeys.MODERATE, HealthLabelsKeys.HEALTHY]
  return ['all', 'user', 'alert_source']
}

export function getResponderName(responder: ResponderV5): string {
  return (responder?.name ? responder.name.trim() : responder.email) || ' '
}

export function getResponderHealth(responder: ResponderV5) {
  // Show responder healthy without tooltip if the responder is logged in as self
  if (responder?.email) {
    if (isMyEmail(responder?.email)) return HealthLabelsKeys.HEALTHY
  }

  switch (responder?.health) {
    case HealthCode.CRITICAL:
      return HealthLabelsKeys.CRITICAL
    case HealthCode.MODERATE:
      return HealthLabelsKeys.MODERATE
    case HealthCode.HEALTHY:
      return HealthLabelsKeys.HEALTHY
    default:
      return HealthLabelsKeys.UNKNOWN
  }
}

function getResponderHealthTooltip(responder: ResponderV5): string {
  // Show responder healthy without tooltip if the responder is logged in as self
  if (responder?.email) {
    if (isMyEmail(responder?.email)) return ''
  }

  if (responder.responderType === ResponderType.ALERT_SOURCE) {
    const { devicesById } = Devices.store.getState()
    const device = devicesById[responder.id]
    return device ? getDeviceTooltip(device) : ''
  }
  const isOnline = LiveResponders.isResponderOnline(responder.id)
  const translation: TranslationGroup = trans.common()
  const warnings: TranslationGroup = trans.group(TranslationKey.HEALTH_WARNINGS)
  const tmp = (responder?.healthWarnings?.map(warning => warnings[warning]) || []).concat()

  if (isOnline) tmp.unshift(translation.responder_online)
  let last = ''
  if (tmp.length > 1) {
    last = `, ${translation.and} ${tmp.pop()}`
  }
  return tmp.length ? `${translation.responder} ${tmp.join(', ')}${last}` : ''
}

export function hasValidTarget(responder: ResponderV5) {
  return responder.targets.some(
    t =>
      t.status === AlertTargetStatus.ACCEPTED ||
      [AlertTargetForwardType.PUSH_ANDROID, AlertTargetForwardType.PUSH_IOS].includes(t.forwardType),
  )
}

export function getTargetStatus(responder: ResponderV5, forwardType: AlertTargetForwardType) {
  return responder.targets.find(t => t.forwardType === forwardType)?.status
}

function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export const getSortOptions: () => SortOption<RespondersColumnKeys>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.RESPONDER_TABLE_VIEW)
  return _.flatten(
    _.map(RespondersColumnKeys, c => {
      let desc = `${translation[c]} ↓`
      let asc = `${translation[c]} ↑`
      if (['push', 'SMS', 'email', 'membership'].includes(c)) {
        return []
      }
      return [createSortOption(c, SortOrder.Descending, desc), createSortOption(c, SortOrder.Ascending, asc)]
    }),
  )
}

export function searchResponders(responders: ResponderV5[], searchBy: string): ResponderV5[] {
  if (searchBy.length < 2) return responders
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  const searchArray = _.flatten(
    responders.map(responder => {
      const arr = [
        [responder.id, responder.id],
        [translation[getResponderHealth(responder)], responder.id],
        [getResponderName(responder), responder.id],
        [responder.responderType === 'user' ? translation.user : translation.device, responder.id],
      ]
      return arr
    }),
  )
  const searchResults = fuzzySearch(searchBy, searchArray)
  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)
  return responders.filter(responder => searchResultsIds.includes(responder.id))
}

export function getFilterResponderComparison(responder: ResponderV5, filterBy: string) {
  const opts = getFilterOptions()
  const keys = getFilterOptionKeys()
  if (!opts.includes(filterBy)) throw new Error()
  const idx = opts.indexOf(filterBy)
  switch (keys[idx]) {
    case 'all':
      return true
    case 'devices':
      return responder.responderType === ResponderType.ALERT_SOURCE
    case 'users':
      return responder.responderType === ResponderType.USER
    default:
      return getResponderHealth(responder) === keys[idx]
  }
}

export function filterResponderByHealthLabel(responders: ResponderV5[], healthLabel: string) {
  return responders.filter(a => getResponderHealth(a) === healthLabel)
}

export const getHealthIcon = (health: string) => {
  switch (health) {
    case HealthLabelsKeys.HEALTHY:
    case HealthLabelsKeys.ONLINE:
      return <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />

    case HealthLabelsKeys.MODERATE:
      return <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />

    case HealthLabelsKeys.OFFLINE:
    case HealthLabelsKeys.CRITICAL:
      return <HighlightOffIcon style={{ color: '#F44336' }} />

    default:
      return <></>
  }
}

export const getAlertTargetStatusIcon = (status: AlertTargetStatus | null) => {
  switch (status) {
    case AlertTargetStatus.ACCEPTED:
      return <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />

    case AlertTargetStatus.PENDING:
      return <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />

    case AlertTargetStatus.REFUSED:
      return <HighlightOffIcon style={{ color: '#F44336' }} />

    default:
      return <></>
  }
}
export interface AlertTargetStatusMap {
  email?: AlertTargetStatus | null
  push?: AlertTargetStatus | null
  sms?: AlertTargetStatus | null
}

const getAlertTargetStatusMap = (responder: ResponderV5): AlertTargetStatusMap => {
  const targets = responder.targets
  const map: AlertTargetStatusMap = {}
  targets.forEach(t => {
    if (
      t.forwardType === AlertTargetForwardType.PUSH_IOS ||
      t.forwardType === AlertTargetForwardType.PUSH_ANDROID ||
      t.forwardType === AlertTargetForwardType.PUSHER
    ) {
      map.push = t.status
    } else if (t.forwardType === AlertTargetForwardType.EMAIL) {
      map.email = t.status
    } else if (t.forwardType === AlertTargetForwardType.SMS) {
      map.sms = t.status
    }
  })
  return map
}

export const isConnected = (device: Device) => {
  return device.props.button?.connected ? true : false
}

export const getBatteryDisplay = (device: Device) => {
  return (
    <>
      {getBatteryIcon(device.batteryPercent)}
      {!_.isNil(device.batteryPercent) && `${device.batteryPercent}%`}
    </>
  )
}

export const getColumnConfigs: () => TableColumnConfig<RespondersColumnKeys, ResponderV5>[] = () => {
  const translation: TranslationGroup = trans.group(TranslationKey.RESPONDER_TABLE_VIEW)
  const healthTrans: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  const userTrans: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  const common: TranslationGroup = trans.common()

  return [
    {
      header: RespondersColumnKeys.Health,
      label: healthTrans[RespondersColumnKeys.Health],
      renderFn: (responder: ResponderV5) => {
        const health = getResponderHealth(responder)
        const icon = getHealthIcon(health)
        return (
          <Tooltip title={getResponderHealthTooltip(responder)}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  paddingRight: '10px',
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                {icon}
              </div>

              <div style={{ lineHeight: '10px' }}>{ucfirst(healthTrans[health])}</div>
            </div>
          </Tooltip>
        )
      },
    },
    {
      header: RespondersColumnKeys.Type,
      label: translation[RespondersColumnKeys.Type],
      renderFn: (responder: ResponderV5) => healthTrans[responder.responderType === 'user' ? 'user' : 'device'],
    },
    {
      header: RespondersColumnKeys.Name,
      label: translation[RespondersColumnKeys.Name],
      renderFn: (responder: ResponderV5) => getResponderName(responder),
    },
    {
      header: RespondersColumnKeys.Push,
      label: 'Push',
      renderFn: (responder: ResponderV5) => {
        const status = getAlertTargetStatusMap(responder)['push'] || null
        const icon = getAlertTargetStatusIcon(status)
        return <Tooltip title={getNotificationTooltip('push', status)}>{icon}</Tooltip>
      },
    },
    {
      header: RespondersColumnKeys.SMS,
      label: RespondersColumnKeys.SMS,
      renderFn: (responder: ResponderV5) => {
        const status = getAlertTargetStatusMap(responder)['sms'] || null
        const icon = getAlertTargetStatusIcon(status)
        return <Tooltip title={getNotificationTooltip(AlertTargetForwardType.SMS, status)}>{icon}</Tooltip>
      },
    },
    {
      header: RespondersColumnKeys.Email,
      label: common.email,
      renderFn: (responder: ResponderV5) => {
        const status = getAlertTargetStatusMap(responder)['email'] || null
        const icon = getAlertTargetStatusIcon(status)
        return <Tooltip title={getNotificationTooltip(AlertTargetForwardType.EMAIL, status)}>{icon}</Tooltip>
      },
    },
    {
      header: RespondersColumnKeys.Membership,
      label: healthTrans[RespondersColumnKeys.Membership],
      renderFn: (responder: ResponderV5) => {
        return getMembershipFromResponder(responder)
      },
    },
    {
      header: RespondersColumnKeys.LastSeen,
      label: translation[RespondersColumnKeys.LastSeen],
      renderFn: (responder: ResponderV5) => {
        /**
         * Right below is the legacy responder model. I am preserving this until the deploy proves stable
         */
        // const { respondersById } = LiveResponders.store.getState()
        // return !!respondersById[responder.id]
        //   ? userTrans.online_now
        //   : responder.lastCheckedInAt
        //   ? formatDistanceToNow(responder.lastCheckedInAt)
        //   : ''

        if (responder.pusherConnected) return userTrans.online_now
        else return formatDistanceToNow(responder.lastCheckedInAt)
      },
    },
  ]
}

// export function getSortResponderCompareFn(
//   sortBy: SortBy<RespondersColumnKeys>,
// ): (a: ResponderV4, b: ResponderV4) => number {
//   switch (sortBy.field) {
//     case RespondersColumnKeys.Health:
//       return (a: ResponderV4, b: ResponderV4) => {
//         const keys = getFilterOptionKeys()
//         const diff = keys.findIndex(h => getResponderHealth(a) === h) - keys.findIndex(h => getResponderHealth(b) === h)
//         return normalizeCompareResult(invertIfDescending(diff, sortBy.order))
//       }

//     case RespondersColumnKeys.Type:
//       return (a: ResponderV4, b: ResponderV4) =>
//         normalizeCompareResult(
//           invertIfDescending(simpleSort(a.attributes.responderType, b.attributes.responderType), sortBy.order),
//         )

//     case RespondersColumnKeys.Name:
//       return (a: ResponderV4, b: ResponderV4) => {
//         const aname = getResponderName(a)?.toLowerCase()
//         const bname = getResponderName(b)?.toLowerCase()
//         return normalizeCompareResult(invertIfDescending(simpleSort(aname, bname), sortBy.order))
//       }

//     case RespondersColumnKeys.Push:
//       return (a: ResponderV4, b: ResponderV4) => {
//         const { targetsByResponderId } = Responders.store.getState()
//         const atarget = targetsByResponderId[a.id]?.PUSH || TargetStatusValue.UNKNOWN
//         const btarget = targetsByResponderId[b.id]?.PUSH || TargetStatusValue.UNKNOWN
//         return normalizeCompareResult(invertIfDescending(simpleSort(atarget, btarget), sortBy.order))
//       }

//     case RespondersColumnKeys.Email:
//       return (a: ResponderV4, b: ResponderV4) => {
//         const { targetsByResponderId } = Responders.store.getState()
//         const atarget = targetsByResponderId[a.id]?.EMAIL || TargetStatusValue.UNKNOWN
//         const btarget = targetsByResponderId[b.id]?.EMAIL || TargetStatusValue.UNKNOWN
//         return normalizeCompareResult(invertIfDescending(simpleSort(atarget, btarget), sortBy.order))
//       }

//     case RespondersColumnKeys.SMS:
//       return (a: ResponderV4, b: ResponderV4) => {
//         const { targetsByResponderId } = Responders.store.getState()
//         const atarget = targetsByResponderId[a.id]?.SMS || TargetStatusValue.UNKNOWN
//         const btarget = targetsByResponderId[b.id]?.SMS || TargetStatusValue.UNKNOWN
//         return normalizeCompareResult(invertIfDescending(simpleSort(atarget, btarget), sortBy.order))
//       }

//     case RespondersColumnKeys.Membership:
//       return (a: ResponderV4, b: ResponderV4) => {
//         return normalizeCompareResult(
//           invertIfDescending(simpleSort(getMembershipFromResponder(a), getMembershipFromResponder(b)), sortBy.order),
//         )
//       }

//     case RespondersColumnKeys.LastSeen:
//       return (a: ResponderV4, b: ResponderV4) =>
//         normalizeCompareResult(
//           invertIfDescending(lastUpdatedSort(a.attributes.lastCheckedInAt, b.attributes.lastCheckedInAt), sortBy.order),
//         )

//     default:
//       console.warn(`Unhandled sort column ${sortBy.field}`)
//       return () => 0
//   }
// }

function getNotificationTooltip(ntype: string, status: AlertTargetStatus | null) {
  const translation: TranslationGroup = trans.common()
  switch (status) {
    case AlertTargetStatus.ACCEPTED:
      return translation[`${ntype}_alerts_enabled`]
    case AlertTargetStatus.PENDING:
      return translation[`${ntype}_alerts_pending`]
    case AlertTargetStatus.REFUSED:
      return translation[`${ntype}_alerts_not_enabled`]
    default:
      return ''
  }
}

function simpleSort(a: any, b: any) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function lastUpdatedSort(a: any, b: any) {
  if (!a) return -1
  if (!b) return 1
  return differenceInMilliseconds(parseTime(a), parseTime(b))
}

function normalizeCompareResult(r: number) {
  return simpleSort(r, 0)
}

export function downloadCsv(responders: ResponderV5[]) {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)

  const fields = [translation.id, translation.health, translation.type, translation.name, 'Push', translation.email]

  const data = responders.map(responder => {
    const emailStatus = getTargetStatus(responder, AlertTargetForwardType.EMAIL)
    const emailStatusString = (status: AlertTargetStatus | undefined) => {
      switch (status) {
        case AlertTargetStatus.ACCEPTED:
          return translation.yes
        case AlertTargetStatus.PENDING:
        case AlertTargetStatus.REFUSED:
          return ucfirst(translation[status])
        default:
          return ''
      }
    }

    // const emailStatusString = emailStatus ? translation[emailStatus] : ''
    return [
      responder.id,
      ucfirst(translation[getResponderHealth(responder)]),
      responder.responderType === ResponderType.USER ? translation.user : translation.device,
      responder.name,
      getTargetStatus(responder, AlertTargetForwardType.PUSH_ANDROID) ||
      getTargetStatus(responder, AlertTargetForwardType.PUSH_IOS)
        ? translation.yes
        : '',
      emailStatusString(emailStatus),
    ]
  })
  const name = `${Orgs.getSelectedOrgName()} ${translation.responders}`.trim()

  generateCSV(data, fields, name)
  return
}

export const handleDownloadCSV = (orgId: string) => {
  getRespondersCSV(orgId)
}
