import { shallowEqual } from 'react-redux'
import { createReduxModule } from 'hooks-for-redux'
import { Beacon } from 'lib/types'
import {
  getAllBeaconsWithPagination,
  BeaconsWithPagination,
  Pagination,
  EMPTY_PAGINATION,
  disableBeacon as _disableBeacon,
  unregisterBeacon as unregister,
  updateBeacon,
  getBeaconPage,
  BeaconParams,
  EMPTY_BEACONS,
} from './api'
import * as NavState from './NavState'
import { ALL_ORGS_SELECTED, API_DEFAULT_LIMIT, ROWS_PER_PAGE } from 'lib/constants'
import { getRecordsById, getRecordsByOrgId } from './modelUtils'
import { PopUpNotifications } from 'models'

export interface BeaconState {
  beacons: Beacon[]
  beaconsById: { [key: string]: Beacon }
  beaconsByOrgId: { [key: string]: Beacon[] }
  loading: boolean
  initialLoading: boolean
  pagination: Pagination
  error?: any
}

const initialState: BeaconState = {
  beacons: [],
  beaconsById: {},
  beaconsByOrgId: {},
  loading: false,
  initialLoading: false,
  pagination: EMPTY_PAGINATION,
}

const setBeaconState = (state: BeaconState, beaconsWithPagination: BeaconsWithPagination): BeaconState => {
  const beacons = beaconsWithPagination.beacons
  return {
    ...state,
    beacons,
    beaconsById: getRecordsById(beacons),
    beaconsByOrgId: getRecordsByOrgId(beacons),
    pagination: beaconsWithPagination.pagination,
  }
}

export const [
  use,
  { setBeaconsWithPagination, updateBeaconsWithPagination, setInitialLoading, setLoading, setError },
  store,
] = createReduxModule('beacons', initialState, {
  setBeaconsWithPagination: (state: BeaconState, beaconsWithPagination: BeaconsWithPagination): BeaconState => {
    return setBeaconState(state, beaconsWithPagination)
  },
  updateBeaconsWithPagination: (state: BeaconState, beaconsWithPagination: BeaconsWithPagination): BeaconState => {
    const updatedBeacons = beaconsWithPagination.beacons.reduce((acc: Beacon[], beacon: Beacon) => {
      if (!state.beaconsById[beacon.id]) acc.push(beacon)
      return acc
    }, state.beacons)
    const updatedBeaconsWithPagination: BeaconsWithPagination = {
      beacons: updatedBeacons,
      pagination: beaconsWithPagination.pagination,
    }
    return setBeaconState(state, updatedBeaconsWithPagination)
  },
  setInitialLoading: (state: BeaconState, initialLoading: boolean) => ({ ...state, initialLoading }),
  setLoading: (state: BeaconState, loading: boolean) => ({ ...state, loading }),
  setError: (state: BeaconState, error: any) => {
    return { ...state, error }
  },
})

export const useSelectedOrgsBeacons = () => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const { beacons, beaconsByOrgId } = use(({ beacons, beaconsByOrgId }) => {
    return { beacons, beaconsByOrgId }
  }, shallowEqual)
  return selectedOrgId === ALL_ORGS_SELECTED ? beacons : beaconsByOrgId[selectedOrgId] || []
}

const handleError = (errorObj: any) => {
  const msg = errorObj instanceof Error ? errorObj.message : errorObj.toString()
  const content = `Beacons ${msg.toLowerCase()}`
  PopUpNotifications.fireError({ content })
  setError(errorObj)
}

export const load = async (pagination: any, nextPage: boolean) => {
  const selectedOrgId = localStorage.getItem('selected_org_id')
  console.log('Loading Beacons data')
  if (!selectedOrgId || selectedOrgId === ALL_ORGS_SELECTED) {
    setBeaconsWithPagination(EMPTY_BEACONS)
    return
  }

  // const { pagination } = use(({ pagination }) => {
  //   return { pagination }
  // }, shallowEqual) // shallowEqual might not be necessary here, but it depends on your use case

  const params: BeaconParams = {
    limit: ROWS_PER_PAGE,
    beforeCursor: nextPage ? null : pagination.before,
    afterCursor: nextPage ? pagination.after : null, // Use the after cursor from the pagination state
    orgId: selectedOrgId,
  }

  setInitialLoading(true)
  setLoading(true)
  let page = EMPTY_BEACONS
  let errorObj

  try {
    page = await getBeaconPage(params)
    console.log('page:', page)
  } catch (err: any) {
    errorObj = err
  }

  setInitialLoading(false)

  if (errorObj) {
    if (errorObj.response.status >= 500) handleError(errorObj)
    setLoading(false)
    return
  }

  setBeaconsWithPagination(page)
  setLoading(false)
}

export const reload = async () => {
  const selectedOrgId = localStorage.getItem('selected_org_id')

  if (!selectedOrgId || selectedOrgId === ALL_ORGS_SELECTED) {
    setBeaconsWithPagination(EMPTY_BEACONS)
    return
  }

  // const { pagination } = use(({ pagination }) => {
  //   return { pagination }
  // }, shallowEqual) // shallowEqual might not be necessary here, but it depends on your use case

  const params: BeaconParams = {
    limit: ROWS_PER_PAGE,
    afterCursor: null, // Use the after cursor from the pagination state
    orgId: selectedOrgId,
  }

  setInitialLoading(true)
  setLoading(true)
  let pageOne = EMPTY_BEACONS
  let errorObj

  try {
    pageOne = await getBeaconPage(params)
  } catch (err: any) {
    errorObj = err
  }

  setInitialLoading(false)

  if (errorObj) {
    if (errorObj.response.status >= 500) handleError(errorObj)
    setLoading(false)
    return
  }

  // params.limit = ROWS_PER_PAGE
  // params.afterCursor = pageOne.pagination.after
  setBeaconsWithPagination(pageOne)
  setLoading(false)
}

const doUpdateAction = (f: () => any) => {
  setLoading(true)
  return Promise.resolve()
    .then(f)
    .then(result => {
      reload()
      return result
    }, setError)
}

export const disableBeacon = (beacon: Beacon): Promise<Beacon> =>
  doUpdateAction(() => _disableBeacon(beacon.id, beacon.orgId))

export const unregisterBeacon = (beacon: any): Promise<Beacon> =>
  // @ts-ignore
  doUpdateAction(() => unregister(beacon.id, beacon.orgId))

export const update = (beacon: any): Promise<Beacon> => {
  return doUpdateAction(() => updateBeacon(beacon, beacon.orgId))
}
