import { useEffect, useState } from 'react'
import _ from 'lodash'
import { ActionButton, ViewWrapper, PageWrapper, ViewSpacerWrapper, AddDeviceQRC } from 'components/widgets'
import { ALL_ORGS_SELECTED, MOBILE_APP_ENABLE, USERS_PATH } from 'lib/constants'
import { isAdmin, isInstaller, isSuperAdmin } from 'lib/utils/auth'
import { Users, NavState, DetailsDrawer, Grants, Orgs, Modal, PopUpNotifications } from 'models'
import * as utils from './utils'
import { PreConfiguredTableView } from 'components/partials/TableView'
import {
  RouteParams,
  SortOrder,
  TableState,
  TableToolEvent,
  trans,
  TranslationGroup,
  TranslationKey,
  User,
} from 'lib/types'
import { invertBinaryEnum } from 'lib/utils/common'
import { UserDetailDrawer } from 'components/partials/DrawerDetails'
import useModal from 'lib/hooks/useModal'
import { AddUserModal } from 'components/partials/AddUserModal'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'models/modelUtils'
import i18n from 'i18n.js'
import { RenderCounter } from 'components/widgets/RenderCounter'
import { UsersColumnKeys } from 'lib/constants/keys/users'
import { useGlobal } from 'contexts/GlobalProvider'

function UsersPage() {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  const common: TranslationGroup = trans.common()
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  const { selectedOrgId } = useGlobal()
  const orgs = Orgs.use(({ orgs }) => orgs)
  const userOrgsMap = Grants.useUserOrgMap()

  const {
    setModalContent,
    openModal: openQrcModal,
    closeModal: closeQrcModal,
  } = Modal.useStaticModal(translation.enable_sso)

  const getEnableAppURL = () => {
    const url = new URL(window.location.href)
    const base = `${url.protocol}//${url.hostname}`
    const port = url.port ? `:${url.port}` : ''
    return `${base}${port}${MOBILE_APP_ENABLE}`
  }

  useEffect(() => {
    setModalContent(
      <AddDeviceQRC
        responders={true}
        closeModal={closeQrcModal}
        value={getEnableAppURL()}
        subtitle={translation.enable_app_login}
      />,
    )
  }, [closeQrcModal, setModalContent])

  const hasAdminGrants = isAdmin(selectedOrgId)

  // const { Modal: UserModal, openModal, closeModal } = useModal(translation.add_user)
  const [AddUserModalOpen, setAddUserModalOpen] = useState(false)

  const [tableState, setTableState] = useState<TableState<string, UsersColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })

  const selectedUser = Users.useSelectedUser(id)
  const loading = Users.use(({ loading }) => loading)
  const associatedUsers = Users.useAssociatedUsers(selectedOrgId || ALL_ORGS_SELECTED) // users associated to group or child properties

  const filteredUsers = utils
    .searchUsers(associatedUsers, tableState.searchBy)
    .filter(user => utils.getFilterUserComparison(user, tableState.filterBy))
    .sort(utils.getSortUserCompareFn(tableState.sortBy))
    .map(u => {
      // This function adds the membership property to the user objects
      const userOrgs = userOrgsMap[u.id] || []
      u.attributes.membership = orgs.filter(o => userOrgs.includes(o.id))
      u.attributes.isOnline = false // !!respondersById[u.id]
      return u
    })

  useEffect(() => {
    if (id && !loading) {
      if (selectedUser) {
        handleRowClick(selectedUser)
      } else {
        PopUpNotifications.fireWarning({ content: `${common.user} ${id}${common.was_not_found}` })
        history.replace(USERS_PATH)
      }
    }
  }, [id, loading])

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  i18n.on('languageChanged', lng => {
    setTableState({
      ...tableState,
      filterBy: utils.getFilterOptions()[0],
      sortBy: utils.getSortOptions()[0].sortBy,
    })
  })

  function handleDrawerClose() {
    history.push({
      pathname: `${USERS_PATH}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: User) {
    if (row.id != id) {
      history.push({
        pathname: `${USERS_PATH}/${row.id}`,
        search: query.toString(),
      })
    }
    DetailsDrawer.show({
      drawerComponent: UserDetailDrawer,
      drawerProps: {
        user: row,
        close: handleDrawerClose,
      },
    })
  }

  function handleHeaderClick(header: string) {
    setTableState(state => {
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, UsersColumnKeys>) {
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: _.isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  const configs = utils.getAdminUsersColumnKeysConfig(selectedOrgId || ALL_ORGS_SELECTED, handleRowClick)
  const adminRequiredCols = hasAdminGrants ? [] : configs.filter(col => !!col.requiresAdmin)
  const orgRequiredCols = selectedOrgId !== ALL_ORGS_SELECTED ? [] : configs.filter(col => !!col.requiresOrg)
  const hiddenColumns = adminRequiredCols.map(c => c.header).concat(orgRequiredCols.map(c => c.header))
  const isAdminOrInstaller = isAdmin(selectedOrgId) || isInstaller(selectedOrgId) || isSuperAdmin()

  return (
    <PageWrapper>
      <ViewWrapper>
        <RenderCounter name={'UsersPage'} />
        <ViewSpacerWrapper>
          <PreConfiguredTableView
            sortedData={filteredUsers}
            tableState={tableState}
            columnConfigs={configs}
            filterOptions={utils.getFilterOptions()}
            sortOptions={utils.getSortOptions()}
            onHeaderClick={handleHeaderClick}
            onRowClick={() => null}
            onToolEvent={handleToolEvent}
            hiddenColumns={hiddenColumns}
            selectedRowId={id}
            loading={loading}
          >
            <ActionButton onClick={() => utils.downloadCsv(filteredUsers)}>{common.download_csv}</ActionButton>
            {hasAdminGrants && (
              <ActionButton
                onClick={
                  selectedOrgId === ALL_ORGS_SELECTED ? () => alert(translation.all_properties_selected) : () => setAddUserModalOpen(true)
                }
              >
                {translation.add_user}
              </ActionButton>
            )}
            {isAdminOrInstaller && (
              <ActionButton
                onClick={() => {
                  openQrcModal()
                }}
              >
                {translation.enable_app_login}
              </ActionButton>
            )}
            {AddUserModalOpen && (
              <AddUserModal orgId={selectedOrgId || ALL_ORGS_SELECTED} open={AddUserModalOpen} close={() => setAddUserModalOpen(false)} />
            )}

              
         
          </PreConfiguredTableView>
        </ViewSpacerWrapper>
      </ViewWrapper>
    </PageWrapper>
  )
}

export default UsersPage
