import { useState, useRef, ChangeEvent } from 'react'
import { StyledH2, LinkableText } from 'components/legacy-poorly-defined/Common'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import styled from '@emotion/styled'
import { ActionButton } from 'components/widgets'
import { Box } from '@material-ui/core'
import { BeaconUploadReport, trans } from 'lib/types'
import { useGlobal } from 'contexts/GlobalProvider'
import { ALL_ORGS_SELECTED, API_V5 } from 'lib/constants'
import { postRequest } from 'models/api/rest'
import { Orgs, PopUpNotifications } from 'models'
import { upload } from '@testing-library/user-event/dist/upload'

const ParentList = styled.ul({
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  listStyle: 'none',
  paddingLeft: 0,
})

const ChildList = styled.ul({
  listStyle: 'none',
  paddingLeft: '2.0rem',
})

const RowContainer = styled.div({
  width: '100%',
  marginBottom: '2.4rem',
  flexDirection: 'column',
})

const StyledBoldText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

const StyledText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 'auto',
    },
    heading: {
      fontSize: '1.5rem',
    },
  }),
)

export default function BulkBeaconInstall() {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const inputFile = useRef({} as any)
  const [expanded, setExpanded] = useState<string | false>(false)
  const { selectedOrgId } = useGlobal()
  const settings = trans.settings()
  const [uploaded, setUploaded] = useState(false)
  const [uploadReport, setUploadReport] = useState<BeaconUploadReport | null>(null)
  const location = Orgs.useSelectedOrg()

  const hasLocation = location && location?.name !== ALL_ORGS_SELECTED

  const handleChange = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const onChangeFile = async (event: any) => {
    event.stopPropagation()
    event.preventDefault()
    try {
      const file = event.target.files[0]

      if (!file) return

      setIsLoading(true)
      const formData = new FormData()
      formData.append('file', file)

      // Posting the file directly
      const response = await postRequest({
        endpoint: `${API_V5}/orgs/${location?.id}/beacon_import`,
        data: formData,
        header: {
          org_id: location?.id,
          'Content-Type': 'multipart/form-data',
        },
      })
      setUploaded(true)
      setUploadReport(response.data.data)
      // PopUpNotifications.fireInfo({ content: settings.beacons_created })
    } catch (error) {
      PopUpNotifications.fireError({
        content: settings.beacons_create_fail,
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadExampleFile = () => {
    const csvContent = [
      ['Type', 'Name', 'Floor', 'Room', 'Beacon Id'], // Headers
      ['CARFIT', 'Under desk', 'L', 'Galler NW', '1A:2B:3C:4D:5E:6F'],
      ['REACT', 'In Closet', '11', '1101', '1B:2C:3D:4E:5F:6G'],
      ['REACT2', 'Behind minibar', '12', '1201', '2A:3B:4C:5D:6E:7F'],
      ['ESTIMOTE', 'Rear wall', '12', '1201', 'ABCDEF0123456789ABCDEF0123456789'],
      ['IBEACON', 'Near smoke detector', '13', '1301', 'ABCDEF0123456789ABCDEF0123456789:1:1'],
      ['IBEACON', 'Under lamp', '13', '1302', 'ABCDEF0123456789ABCDEF0123456789:1:2'],
      ['IBEACON', 'Behind safe', '13', '1303', 'ABCDEF0123456789ABCDEF0123456789:2:1'],
      ['Other', 'Error: unsupported beacon type', '14', '1401', '1A:2B:3C:4D:5E:6F'],
      ['REACT2', 'Error: missing floor', '', '1402', '1A:2B:3C:4D:5E:70'],
      ['REACT', 'Error: missing room', '14', '', '1A:2B:3C:4D:5E:71'],
      ['CARFIT', 'Error: bad identifier', '14', '1404', '111:111:aaa:fff:qqq:xxx'],
      ['IBEACON', 'Error: bad identifier', '14', '1405', 'ABCDEF0123456789ABCDEF0123456789:a:b'],
    ]

    const csvString = csvContent.map(row => row.join(',')).join('\n')

    const blob = new Blob([csvString], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'example.csv')
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const generateCSVReport = () => {
    const errorTypeMap: { [key: string]: string } = {
      exactCopies: 'A copy of this beacon exists.',
      inDifferentOrg: 'This beacon exists in another org.',
      invalidId: 'Invalid id',
      invalidType: 'Invalid type',
      other: 'Failed with unknown error',
    }

    if (!uploadReport) return ''

    const { failed } = uploadReport
    let csvContent = 'id,error\n'

    // Iterate over the failed report and append each id with its corresponding error message
    for (const [errorType, ids] of Object.entries(failed) as [keyof typeof errorTypeMap, string[]][]) {
      ids.forEach((id: string) => {
        const errorMessage = errorTypeMap[errorType] || 'Unknown error'
        csvContent += `${id},${errorMessage}\n`
      })
    }

    return csvContent
  }

  const downloadCSV = () => {
    const csvContent = generateCSVReport()

    if (!csvContent || !inputFile.current.files[0]) return // Ensure a file is uploaded

    // Extract the uploaded file name without extension
    const uploadedFileName = inputFile.current.files[0].name
    // Get the current date and time for the suffix
    const now = new Date()
    const formattedDate = now.toISOString().split('T')[0] // Format as YYYY-MM-DD
    const formattedTime = now.toTimeString().split(' ')[0].replace(/:/g, '') // Format as HHMMSS

    // Build the file name with the "- uploaded at YYYY-MM-DD-HHmmss" suffix
    const fileName = `${uploadedFileName} - uploaded at ${formattedDate}-${formattedTime}.csv`

    // Create a blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
    const link = document.createElement('a')

    // Create a link to the file
    const url = URL.createObjectURL(blob)
    link.setAttribute('href', url)
    link.setAttribute('download', fileName) // Use the new file name

    // Append the link to the body and simulate a click to download
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getFailedCount = () => {
    if (!uploadReport) return 0

    const { failed } = uploadReport

    // Sum the lengths of all the arrays in the failed object
    const totalFailedCount = Object.values(failed).reduce((sum, ids: string[]) => sum + ids.length, 0)

    return totalFailedCount
  }

  return (
    <div>
      <StyledH2>{settings.beacons_bulk_installation}</StyledH2>
      <StyledBoldText>{settings.upload_csv}</StyledBoldText>
      <br />
      <input
        type="file"
        id="csvupload"
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={onChangeFile}
        accept=".csv"
      />
      <Box>
        <ActionButton style={{ marginTop: '0px' }} onClick={handleDownloadExampleFile}>
          {`Download Example File`}
        </ActionButton>
        <ActionButton
          style={{ marginTop: '0px' }}
          onClick={() => inputFile.current.click()}
          disabled={!hasLocation || isLoading}
        >
          {`Upload Beacons`}
        </ActionButton>
        <Box visibility={uploaded ? 'visible' : 'hidden'}>
          <Box marginLeft={'2px'}>
            <br />
            <StyledBoldText>{`Created: ${uploadReport?.inserted}`}</StyledBoldText>
            <StyledBoldText>{`Updated: ${uploadReport?.updated}`}</StyledBoldText>
            <StyledBoldText>{`Failed: ${getFailedCount()}`}</StyledBoldText>
          </Box>
          <ActionButton
            className={classes.heading}
            style={{ marginTop: '10px', fontSize: '1.2rem' }}
            onClick={downloadCSV}
            disabled={!uploadReport}
          >{`Download Detailed Report`}</ActionButton>
        </Box>
      </Box>
    </div>
  )
}
