/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { BarChart, Bar, XAxis, YAxis, Tooltip } from 'recharts'
import { ChartsWrapper } from 'components/widgets'
import { AlertSession, NameNumberValuePair, trans, TranslationGroup } from 'lib/types'
import { CHART_COLOR_BLUE, HealthLabelsKeys } from 'lib/constants'
import StyledPieChart from 'components/partials/StyledPieChart'
import StyledPieChartLegend from 'components/partials/StyledPieChartLegend'
import { chartPageContainer, chartCardText } from 'styles'
import * as utils from '../utils'
import { HealthKey, LegendLabel } from 'components/partials/MainSidebar/ChartsSection'

export const Charts = ({ alerts, legendLabel }: { alerts: any; legendLabel: LegendLabel }) => {
  // const alertBars = utils.getNumberOfAlertsLastWeek(alerts)
  const translation: TranslationGroup = trans.common()
  const green = legendLabel[HealthKey.GREEN]
  const yellow = legendLabel[HealthKey.YELLOW]
  const red = legendLabel[HealthKey.RED]

  const alertCounts: NameNumberValuePair[] = [
    {
      name: red,
      value: alerts.noResponse,
    },
    {
      name: yellow,
      value: alerts.inProgress,
    },
    {
      name: green,
      value: alerts.resolved,
    },
  ]

  return (
    <ChartsWrapper>
      <div css={chartPageContainer}>
        <StyledPieChart data={alertCounts} legendLabel={legendLabel} />
        <StyledPieChartLegend
          red={`${alertCounts[0].value} ${red}`}
          yellow={`${alertCounts[1].value} ${yellow}`}
          green={`${alertCounts[2].value} ${green}`}
          reverse
        />
      </div>
      {/* need endpoint for this */}
      {/* <div css={chartPageContainer}>
        <div css={chartCardText}>{translation.last_days}</div>
        <BarChart width={290} height={200} data={alertBars} style={{ margin: 'auto' }}>
          <XAxis dataKey="name" axisLine={false} tickMargin={5} tick={{ radius: 20 }} tickSize={3} />
          <YAxis padding={{ bottom: 10 }} hide />
          <Tooltip />
          <Bar dataKey="value" fill={CHART_COLOR_BLUE} barSize={10} radius={[10, 10, 10, 10]} />
        </BarChart>
      </div> */}
    </ChartsWrapper>
  )
}
