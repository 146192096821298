import { ALL_ORGS_SELECTED } from 'lib/constants'
import { Organization } from 'lib/types'
import { CreatePropertyModal } from 'lib/types/createPropertyModal'

import React, { createContext, useContext, useRef, useState, useEffect, ReactNode } from 'react'

// Define the context type
type AdminContextType = {
  loading: boolean
  selectedAdminOrgId: string | null
  modalInput: CreatePropertyModal
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAdminOrgId: React.Dispatch<React.SetStateAction<string | null>>
  setModalInput: React.Dispatch<React.SetStateAction<CreatePropertyModal>>
}

// Initialize
const AdminContext = createContext<AdminContextType>({
  loading: true,
  selectedAdminOrgId: null,
  modalInput: {
    name: '',
    phone: '',
    image: '',
    proximity: '',
    street1: '',
    street2: '',
    zipcode: '',
    city: '',
    state: '',
  },
  setLoading: () => {},
  setSelectedAdminOrgId: () => {},
  setModalInput: () => {},
})

type GlobalProviderProps = {
  children: ReactNode
}

export const useAdmin = () => {
  const context = useContext(AdminContext)
  if (!context) {
    throw new Error('useAdmin must be used within a AdminProvider')
  }
  return context
}

export const AdminProvider: React.FC<GlobalProviderProps> = ({ children }) => {

  console.log('rerender admin ctx')
  const [loading, setLoading] = useState(true)
  const [selectedAdminOrgId, setSelectedAdminOrgId] = useState<string | null>(null)
  const [modalInput, setModalInput] = useState<CreatePropertyModal>({
    name: '',
    phone: '',
    image: '',
    proximity: '',
    street1: '',
    street2: '',
    zipcode: '',
    city: '',
    state: '',
  })

  return (
    <AdminContext.Provider
      value={{
        loading,
        setLoading,
        selectedAdminOrgId,
        setSelectedAdminOrgId,
        modalInput,
        setModalInput,
        /* functions */
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}
