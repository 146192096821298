/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import _ from 'lodash'
import { Tooltip } from '@material-ui/core'
import { FirmwareVersionProps, trans, TranslationGroup, UpdateFirmwareProps } from 'lib/types'
import { FirmwareIcon } from 'styles'
import { getIcon } from 'components/partial-pages/ButtonsDevicesPage'

export function Firmware({
  fallback,
  update,
  version = {},
  appVersionOnly,
  appVersion,
}: {
  fallback?: string | undefined
  update?: UpdateFirmwareProps
  version?: FirmwareVersionProps
  appVersionOnly?: boolean
  appVersion?: string
}) {
  const translation: TranslationGroup = trans.common()
  if (_.isEmpty(version)) {
    return <span>{fallback}</span>
  }
  const versionTypes = Object.keys(version)

  const getFirmwareVersions = () => {
    return versionTypes.map((firmwareType, index) => {
      if (
        appVersionOnly &&
        [
          'wanPeerlessBle',
          'wanPeerlessModem',
          'wan_peerless_ble',
          'wan_peerless_modem',
          'wan_peerless2_ble',
          'wan_peerless2_modem',
          'wanPeerless2Ble',
          'wanPeerless2Modem',
        ].includes(firmwareType)
      )
        return null
      if (!version[firmwareType]) return null
      return (
        <Tooltip
          enterDelay={200}
          enterNextDelay={200}
          placement={`left`}
          title={`${translation.type}: ${firmwareType}`}
          key={firmwareType}
        >
          <div css={{ alignItems: 'center', display: 'flex' }} key={firmwareType + index}>
            {getIcon(firmwareType, update)}
            <p css={{ marginBottom: 0, marginTop: 0 }}>
              <span>{`${version[firmwareType]}`}</span>
            </p>
          </div>
        </Tooltip>
      )
    })
  }

  const firmware = getFirmwareVersions().filter(v => v !== null)
  const display = firmware.length ? firmware : fallback
  console.log({ display })
  return <>{display}</>
}
