import { shallowEqual } from 'react-redux'
import { createReduxModule } from 'hooks-for-redux'
import { HealthLabelsKeys } from 'lib/constants'
import { ResponderV4 } from 'lib/types/responders'
import { AlertTargetForwardType, AlertTargetStatus, trans, TranslationGroup } from 'lib/types'
import { EMPTY_PAGINATION, Pagination } from './api'
import { ALL_ORGS_SELECTED, API_DEFAULT_LIMIT, ROWS_PER_PAGE } from 'lib/constants'
import {
  EMPTY_RESPONDERS,
  getAllRespondersWithPagination,
  getRespondersPage,
  ResponderParams,
  RespondersWithPagination,
} from './api/responders'
import { NavState, PopUpNotifications } from 'models'
import { getRecordsById } from './modelUtils'
import { getResponderHealth } from 'components/partial-pages/RespondersPage/utils'
import * as LiveRespondersState from './LiveResponders'

export enum TargetStatusValue {
  UNKNOWN = 0,
  HEALTHY = 1,
  MODERATE = 2,
  CRITICAL = 3,
}

export interface TargetStatus {
  EMAIL: TargetStatusValue
  PUSH: TargetStatusValue
  SMS: TargetStatusValue
}

export interface ResponderState {
  responders: ResponderV4[]
  respondersById: { [key: string]: ResponderV4 }
  respondersByOrgId: { [key: string]: ResponderV4[] }
  targetsByResponderId: { [key: string]: TargetStatus }
  loading: boolean
  initialLoading: boolean
  pagination: Pagination
  error?: any
}

const initialState: ResponderState = {
  responders: [],
  respondersById: {},
  respondersByOrgId: {},
  targetsByResponderId: {},
  loading: false,
  initialLoading: false,
  pagination: EMPTY_PAGINATION,
}

const addRespondersToOrgs = (rs: ResponderV4[]) => {
  const orgs: { [key: string]: ResponderV4[] } = {}
  rs.forEach(responder => {
    responder.attributes.orgIds.forEach(orgId => {
      if (!orgs[orgId]) orgs[orgId] = []
      orgs[orgId].push(responder)
    })
  })
  return orgs
}

function getStatusValue(val: AlertTargetStatus | undefined): TargetStatusValue {
  switch (val) {
    case AlertTargetStatus.ACCEPTED:
      return TargetStatusValue.HEALTHY
    case AlertTargetStatus.PENDING:
      return TargetStatusValue.MODERATE
    case AlertTargetStatus.REFUSED:
      return TargetStatusValue.CRITICAL
    default:
      return TargetStatusValue.UNKNOWN
  }
}

const addTargetsToResponders = (rs: ResponderV4[]) => {
  const targets: { [key: string]: TargetStatus } = {}
  const init = {
    EMAIL: TargetStatusValue.UNKNOWN,
    PUSH: TargetStatusValue.UNKNOWN,
    SMS: TargetStatusValue.UNKNOWN,
  }
  rs.forEach(responder => {
    responder.attributes.targets.forEach(target => {
      if (!targets[responder.id]) targets[responder.id] = { ...init }
      switch (target.forwardType) {
        case AlertTargetForwardType.PUSH_IOS:
        case AlertTargetForwardType.PUSH_ANDROID:
          targets[responder.id].PUSH = TargetStatusValue.HEALTHY
          break
        case AlertTargetForwardType.EMAIL:
          targets[responder.id].EMAIL = getStatusValue(target.status)
          break
        case AlertTargetForwardType.SMS:
          targets[responder.id].SMS = getStatusValue(target.status)
          break
      }
    })
  })
  return targets
}

const setRespondersState = (state: ResponderState, respondersWithPagination: RespondersWithPagination) => {
  const responders = respondersWithPagination.responders
  return {
    ...state,
    responders,
    respondersById: getRecordsById<ResponderV4>(responders),
    respondersByOrgId: addRespondersToOrgs(responders),
    targetsByResponderId: addTargetsToResponders(responders),
    pagination: respondersWithPagination.pagination,
  }
}

export const [
  use,
  { setError, setInitialLoading, setLoading, setRespondersWithPagination, updateRespondersWithPagination },
  store,
] = createReduxModule('respondersState', initialState, {
  setError: (state: ResponderState, error: any) => ({ ...state, error }),
  setInitialLoading: (state: ResponderState, initialLoading: boolean) => ({ ...state, initialLoading }),
  setLoading: (state: ResponderState, loading: boolean) => ({ ...state, loading }),
  setRespondersWithPagination: (state: ResponderState, respondersWithPagination: RespondersWithPagination) => {
    return setRespondersState(state, respondersWithPagination)
  },
  updateRespondersWithPagination: (
    state: ResponderState,
    respondersWithPagination: RespondersWithPagination,
  ): ResponderState => {
    const updatedResponders = respondersWithPagination.responders.reduce(
      (acc: ResponderV4[], responder: ResponderV4) => {
        if (!state.respondersById[responder.id]) acc.push(responder)
        return acc
      },
      state.responders,
    )
    const updatedRespondersWithPagination: RespondersWithPagination = {
      responders: updatedResponders,
      pagination: respondersWithPagination.pagination,
    }
    return setRespondersState(state, updatedRespondersWithPagination)
  },
})

const handleError = (errorObj: any) => {
  const translation: TranslationGroup = trans.common()
  const msg = errorObj instanceof Error ? errorObj.message : errorObj.toString()
  const content = `${translation.devices} ${msg.toLowerCase()}`
  PopUpNotifications.fireError({ content })
  setError(errorObj)
}

export const reload = async () => {
  const selectedOrgId = localStorage.getItem('selected_org_id')
  // Don't send request to server?
  // if (!selectedOrgId || selectedOrgId === ALL_ORGS_SELECTED) {
  //   setRespondersWithPagination(EMPTY_RESPONDERS)
  //   return
  // }
  const params: ResponderParams = {
    afterCursor: null,
    orgId: selectedOrgId === ALL_ORGS_SELECTED || !selectedOrgId ? '' : selectedOrgId,
  }

  setInitialLoading(true)
  setLoading(true)
  let pageOne = EMPTY_RESPONDERS
  let errorObj
  try {
    pageOne = await getRespondersPage(params)
  } catch (err: any) {
    console.error(err)
    errorObj = err
  }
  setInitialLoading(false)
  if (errorObj) {
    if (errorObj.response.status >= 500) handleError(errorObj)
    setLoading(false)
    return
  }
  // params.limit = API_DEFAULT_LIMIT
  params.afterCursor = pageOne.pagination.after
  params.orgId = selectedOrgId === ALL_ORGS_SELECTED || !selectedOrgId ? '' : selectedOrgId
  setRespondersWithPagination(pageOne)

  let responders = EMPTY_RESPONDERS
  try {
    responders = await getAllRespondersWithPagination(params)
  } catch (err: any) {
    errorObj = err
  }
  if (errorObj) {
    if (errorObj.response?.status >= 500) handleError(errorObj)
    setLoading(false)
    return
  }
  updateRespondersWithPagination(responders)
  setLoading(false)
}

export const useSelectedOrgsResponders = (orgId?: string) => {
  const selectedOrgId = orgId ? orgId : NavState.use(({ selectedOrgId }) => selectedOrgId)
  const { responders, respondersByOrgId } = use(({ responders, respondersByOrgId }) => {
    return { responders, respondersByOrgId }
  }, shallowEqual)
  return selectedOrgId === ALL_ORGS_SELECTED ? responders : respondersByOrgId[selectedOrgId] || []
}

// export const useOrgResponderHealth = (orgId?: string) => {
//   const responders = useSelectedOrgsResponders(orgId)
//   const respondersByHealth: { [key: string]: ResponderV4[] } = {
//     [HealthLabelsKeys.HEALTHY]: [],
//     [HealthLabelsKeys.MODERATE]: [],
//     [HealthLabelsKeys.CRITICAL]: [],
//   }
//   responders.forEach(responder => {
//     const health = getResponderHealth(responder)
//     respondersByHealth[health].push(responder)
//   })
//   return respondersByHealth
// }

// LiveRespondersState.store.subscribe(({ responderAdded, responderRemoved }) => {
//   if (responderAdded || responderRemoved) {
//     const orgId = localStorage.getItem('selected_org_id')
//     if (orgId) {
//       console.log('Live responder reload')
//       reload()
//     }
//   }
// })

// let inited = false
