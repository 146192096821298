/** @jsxImportSource @emotion/react */
/* eslint-disable no-useless-escape */

import styled from '@emotion/styled/macro'
import { BRAND_COLOR, DISABLED_COLOR } from 'lib/constants'

import {
  Checkbox,
  CheckboxProps,
  DialogTitle,
  InputLabel,
  Input,
  Typography,
  Box,
  FormControlLabel,
} from '@material-ui/core'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { ERROR_COLOR, GRANTS, GRAY3_COLOR, TEXTFIELD_LABEL_COLOR } from 'lib/constants'
import {
  AlertTarget,
  AlertTargetForwardType,
  AlertTargetRequest,
  AlertTargetStatus,
  FlattenedGrant,
  trans,
  TranslationGroup,
  TranslationKey,
} from 'lib/types'
import { Grants, PopUpNotifications, Users } from 'models'
import { subscribe } from 'models/AlertTargets'
import { associateUser, sendConfirmEmailAlerts } from 'models/api'
import { isValidEmail } from 'models/modelUtils'
import { useState, useEffect, memo, ChangeEvent } from 'react'
import { AssignModal, updateGrants } from './AssignModal'
import PhoneInput, { Value } from 'react-phone-number-input'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { useGlobal } from 'contexts/GlobalProvider'
import { Dialog, DialogContent } from '@material-ui/core'
import { StyledDialogTitle } from 'components/widgets'

const UserWrapper = styled.div({
  width: '100%',
  alignContent: 'center',
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
})

const StyledFormControl = styled.div({
  width: '100%',
  marginBottom: '15px',
})

const StyledInputLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginBottom: '20px',
})
const StyledInputErrorLabel = styled(InputLabel)({
  color: ERROR_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  margin: '5px',
})

const StyledInput = styled(Input)({
  background: GRAY3_COLOR,
  borderRadius: '10px',
  padding: '5px 10px',
  fontFamily: 'Montserrat',
})

interface StyledCheckboxProps extends CheckboxProps {
  checkboxcolor: string
}

const StyledRow = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const StyledCheckbox = styled(Checkbox)<StyledCheckboxProps>(props => ({
  border: '1px solid #FFFFFF',
  padding: '0',
  '&.Mui-checked': {
    color: props.checkboxcolor,
  },
}))

const StyledText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '1.6rem',
})

const StyledListContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
})

const StyledPhoneInput = styled(PhoneInput)({
  height: '28px',
  width: '100%',
  '.PhoneInputInput': {
    backgroundColor: 'blue',
    height: '100%',
    border: 'none',
    background: GRAY3_COLOR,
    borderRadius: '10px',
    padding: '5px 10px',
    fontFamily: 'Montserrat',
    width: '100%',
  },
  '.PhoneInputCountryIcon': {
    height: '20px',
    width: '30px',
    border: 'none',
  },
})

type Props = {
  orgId: string
  close: Function
  open: boolean
}

export function AddUserModal({ orgId, close, open }: Props) {
  const translation: TranslationGroup = trans.group(TranslationKey.USER_TABLE_VIEW)
  const common: TranslationGroup = trans.common()

  const [email, setEmail] = useState('')
  const [subscribedEmail, setSubscribedEmail] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [phone, setPhone] = useState<string>('')
  const [subscribedSms, setSubscribedSms] = useState(false)
  const [smsConsentChecked, setSmsConsentChecked] = useState(false)
  const [smsError, setSmsError] = useState(false)
  const [grantsMap, setGrantsMap] = useState({})

  const { setRefreshFlag } = useGlobal()

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleSubmit()
    }
  }

  function subscribeAlerts(userId: string) {
    // if invite email is checked sub to email alerts
    if (subscribedEmail) subscribeTarget(userId, AlertTargetForwardType.EMAIL, email)
    // if invite sms is checked sub to sms alerts
    if (subscribedSms) subscribeTarget(userId, AlertTargetForwardType.SMS, phone)
  }

  function subscribeTarget(userId: string, forwardType: AlertTargetForwardType, forward: string) {
    const request: AlertTargetRequest = { id: undefined, userId, orgId, forwardType, forward: '' }
    if (forwardType === AlertTargetForwardType.SMS) {
      subscribe({ ...request, forward, status: AlertTargetStatus.ACCEPTED }, () => null)
    } else {
      subscribe({ ...request, forward, status: AlertTargetStatus.PENDING }, () => null)
    }
  }

  const handleSubmit = () => {
    // email validation
    if (email.length) {
      if (!isValidEmail(email)) {
        setEmailError(true)
        return
      } else {
        setEmailError(false)
      }
    }
    // phone validation
    const isValid = isPossiblePhoneNumber(phone || '')
    setSmsError(false)
    if (!isValid && subscribedSms) {
      setSmsError(true)
      return
    }

    if (subscribedSms && !smsConsentChecked) {
      setSmsError(true)
      return
    }
    close()

    associateUser(orgId, email)
      .then((result: FlattenedGrant) => {
        PopUpNotifications.fireSuccess({ content: translation.user_added })
        const newGrants = { ...grantsMap }

        if (newGrants[GRANTS.PORTAL_USER as keyof typeof newGrants])
          delete newGrants[GRANTS.PORTAL_USER as keyof typeof newGrants]
        // console.log('newGrants', newGrants)
        return (
          updateGrants(result.userId, orgId, {}, newGrants)
            .catch(error => PopUpNotifications.fireErrorObject(error))
            .then(() => Users.reload())
            .then(() => Grants.reload())
            .then(() => subscribeAlerts(result.userId))
            // .then(() => {
            //   if (subscribedEmail) {
            //     subscribeAlerts(result.userId)
            //   }
            // })
            .then(() => {
              setRefreshFlag(true)
            })
            .catch(err => console.error(err))
        )
      })

      .catch(error => PopUpNotifications.fireErrorObject(error))
  }

  function handlePhoneValueChange(num: Value) {
    setSmsError(false)
    setPhone(num)
  }

  function handleEmailValueChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setEmail(e.target.value.toLowerCase().replace(/\s/g, ''))
    setEmailError(false)
  }

  return (
    <Dialog style={{ animationFillMode: 'forwards' }} open={open} onClose={() => null} maxWidth="sm" fullWidth>
      <StyledDialogTitle>ADD USER</StyledDialogTitle>
      <DialogContent>
        {' '}
        <UserWrapper>
          <StyledContainer>
            <div>
              <form>
                <StyledFormControl>
                  <StyledInputLabel htmlFor="user-email">{common.email}</StyledInputLabel>
                  <StyledInput
                    id="user-email"
                    disableUnderline
                    fullWidth
                    placeholder={translation.enter_email}
                    value={email}
                    type="email"
                    onChange={e => handleEmailValueChange(e)}
                    onKeyDown={handleKeyDown}
                  />{' '}
                  <StyledInputErrorLabel style={{ visibility: emailError ? 'visible' : 'hidden' }}>
                    {translation.invalid_phone}
                  </StyledInputErrorLabel>
                  <StyledInputErrorLabel>{emailError ? common.email_error : ' '}</StyledInputErrorLabel>
                  <StyledRow>
                    <StyledText>Subscribe to email alerts</StyledText>
                    <StyledCheckbox
                      checkboxcolor={BRAND_COLOR}
                      checked={subscribedEmail}
                      onChange={() => setSubscribedEmail(!subscribedEmail)}
                      disabled={false}
                    />
                  </StyledRow>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledInputLabel htmlFor="user-phone">{common.phone}</StyledInputLabel>
                  <StyledPhoneInput
                    defaultCountry="US"
                    placeholder="Enter phone number"
                    value={phone}
                    onChange={handlePhoneValueChange}
                    international={false}
                  />
                  <StyledInputErrorLabel style={{ visibility: smsError ? 'visible' : 'hidden' }}>
                    {translation.invalid_phone + ' and consent'}
                  </StyledInputErrorLabel>
                  <StyledRow>
                    <StyledText>Subscribe to sms alerts</StyledText>
                    <StyledCheckbox
                      checkboxcolor={BRAND_COLOR}
                      checked={subscribedSms}
                      onChange={() => {
                        if (subscribedSms) setSmsError(false)
                        setSubscribedSms(!subscribedSms)
                      }}
                      disabled={false}
                    />
                  </StyledRow>
                  {subscribedSms && (
                    <>
                      <Typography variant="body1">
                        Please confirm you would like to receive SMS notifications through React Mobile. Please note,
                        message and data rates may apply, message frequency varies. Please visit reactmobile.com for
                        terms and conditions and privacy policy. You can reply STOP to 25378 at any time to cancel.
                        Reply HELP for help
                      </Typography>

                      <Box css={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '5px' }}>
                        <FormControlLabel
                          control={
                            <StyledCheckbox
                              checkboxcolor={BRAND_COLOR}
                              checked={smsConsentChecked}
                              onChange={() => setSmsConsentChecked(!smsConsentChecked)}
                              name="sms-consent-checkbox"
                            />
                          }
                          label="I consent"
                        />
                      </Box>
                    </>
                  )}
                  {smsError && <div className="div">error</div>}
                </StyledFormControl>
              </form>
              <AssignModal title={translation.assign_roles} orgId={orgId} setGrants={setGrantsMap} />
            </div>
          </StyledContainer>
          <StyledDialogActions>
            <ActionButton width="110px" height="28px" onClick={() => close()}>
              {common.cancel}
            </ActionButton>
            <ActionButton width="110px" height="28px" onClick={handleSubmit} disabled={emailError}>
              {translation.add_user}
            </ActionButton>
          </StyledDialogActions>
        </UserWrapper>
      </DialogContent>
    </Dialog>
  )
}
export default memo(AssignModal)
