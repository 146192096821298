/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import { Tooltip } from '@material-ui/core'
import { StdioNull } from 'child_process'
import { trans, TranslationGroup } from 'lib/types'

function SignalBar({ bars, isUnavailable }: { bars: number; isUnavailable: boolean }) {
  const SignalStrengthStyle = {
    iconSignalStrength: {
      display: 'inline-flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      width: 'auto',
      height: '25px',
    },
    iconSignalStrengthSpan: {
      display: 'inline-block',
      width: '6px',
      marginLeft: '2px',
      transformOrigin: '100% 100%',
      backgroundColor: isUnavailable ? 'rgb(175, 175, 175)' : 'rgb(95, 208, 120)',
      borderRadius: '2px',
      opacity: '0.2',
    },
    iconSignalStrengthBar1: {
      height: '25%',
      animationDuration: '0.3s',
      animationDelay: '0.1s',
    },
    iconSignalStrengthBar2: {
      height: '50%',
      animationDuration: '0.25s',
      animationDelay: '0.2s',
    },
    iconSignalStrengthBar3: {
      height: '75%',
      animationDuration: '0.2s',
      animationDelay: '0.3s',
    },
    iconSignalStrengthBar4: {
      height: '100%',
      animationDuration: '0.15s',
      animationDelay: '0.4s',
    },
    signalBar: {
      opacity: '1',
    },
  }

  return (
    <div css={{ ...SignalStrengthStyle.iconSignalStrength }}>
      {[1, 2, 3, 4].map((bar, index) => {
        // @ts-ignore
        const barStyle = SignalStrengthStyle[`iconSignalStrengthBar${bar}`]
        const visibilyStyle = bar <= bars ? SignalStrengthStyle.signalBar : {}
        return (
          <div key={index} css={{ ...SignalStrengthStyle.iconSignalStrengthSpan, ...barStyle, ...visibilyStyle }}></div>
        )
      })}
    </div>
  )
}

export function SignalStrengthIcon({ rssi }: { rssi: string | number }) {
  const translation: TranslationGroup = trans.common()
  let isUnavailable = false
  let bars = 4
  if (rssi === undefined || rssi === null || rssi === 0) {
    isUnavailable = true
    bars = 0
  } else if (rssi <= -120) {
    bars = 0
  } else if (rssi <= -110) {
    bars = 1
  } else if (rssi <= -100) {
    bars = 2
  } else if (rssi <= -90) {
    bars = 3
  }
  return (
    <Tooltip enterDelay={500} placement={'left'} title={isUnavailable ? translation.unavailable : rssi}>
      <div>
        <SignalBar isUnavailable={isUnavailable} bars={bars} />
      </div>
    </Tooltip>
  )
}
