/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { formatDistance } from 'date-fns'
import { AlertSession, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ReactComponent as ClockIcon } from 'static/images/alerts/clock-icon.svg'
import { ReactComponent as WhiteClockIcon } from 'static/images/alerts/white-clock-icon.svg'
import { ReactComponent as LocationFlowIcon } from 'static/images/alerts/location-flow-icons.svg'
import { LOCAL_TIME, getAppColors, HealthTypesKeys } from 'lib/constants'
import { formatTime, getAlertTimes } from 'lib/utils'
import { textAlign, boxSizing, fontWeight, flexDirection, overflowWrap, textTransform, justifyContent } from 'styles'

const clockInfoWrapper = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  ...fontWeight(600),
  fontSize: '1.2rem',
  ...textTransform('none'),
  svg: {
    marginRight: '5px',
  },
}

const isWhiteStyle = {
  color: getAppColors('--color-white'),
}

const alertLocationBackground = {
  cursor: 'pointer',
  marginBottom: '20px',
  backgroundColor: getAppColors('--color-gray-3'),
  '& > div': {
    minWidth: '400px',
  },
}

const isOpenStyle = {
  backgroundColor: getAppColors('--color-white'),
  margin: '15px 10px 15px 0',
}

const alertLocationInfoWrapper = {
  borderRadius: '4px',
  border: `1px solid ${getAppColors('--color-gray-2')}`,
  backgroundColor: getAppColors('--color-gray-3'),
  margin: '30px',
}

const locationInfoWrapper = {
  border: `1px solid ${getAppColors('--color-gray-2')}`,
  fontFamily: 'Montserrat',
  display: 'flex',
  ...flexDirection('column'),
}

const locationInfoContainer = {
  display: 'inlineFlex',
  margin: '5px',
  "svg[data-location-icon='flow-icon']": {
    margin: 'auto 8px',
  },
}

const locationInfoDetail = {
  margin: '10px',
  display: 'flex',
  justifyContent: 'space-between',
}

const responderInfoWrapper = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '15px',
  padding: '10px',
  fontFamily: 'Montserrat',
  'span:first-child': {
    ...fontWeight('bold'),
    display: 'flex',
    marginRight: '10px',
  },
  'span:last-child': {
    maxWidth: '270px',
  },
  span: {
    ...overflowWrap('break-word'),
  },
}

const alertLabel = {
  ...fontWeight('bold'),
}

const timeLabel = {
  margin: '10px 0 0 120px',
}

const separator = {
  border: `1px solid ${getAppColors('--color-gray-1')}`,
}

const alertHistoryTitle = {
  fontFamily: 'Montserrat',
  fontSize: '1.6rem',
  fontStyle: 'normal',
  ...fontWeight(900),
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  ...textAlign('left'),
  ...textTransform('uppercase'),
  padding: '20px',
  color: getAppColors('--color-primary'),
  display: 'flex',
  ...justifyContent('space-between'),
  backgroundColor: getAppColors('--color-gray-4'),
}

const isRedStyle = {
  backgroundColor: getAppColors('--color-error'),
}

const isYellowStyle = {
  backgroundColor: getAppColors('--color-warning'),
}

const renderResponders = (respondersQty: number) => {
  return (
    <div
      css={{
        border: '1px solid #e6e6e6',
        ...boxSizing('border-box'),
        borderRadius: '11.5px',
        width: '32px',
        height: '23px',
        fontFamily: 'Montserrat;',
        fontStyle: 'normal',
        ...fontWeight('bold'),
        fontSize: '1.2rem',
        ...textAlign('center'),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      {respondersQty}
    </div>
  )
}

const setCardTitleColor = (alertSession: AlertSession, responders: number) => {
  const { status } = alertSession
  if (status === HealthTypesKeys.OPEN && responders === 0) return isRedStyle
  if (status === HealthTypesKeys.OPEN && responders > 0) return isYellowStyle
  return {}
}

function CondensedInfoGadget({
  alertSession,
  title,
  handleClick,
}: {
  alertSession: AlertSession
  title?: string
  handleClick: any
}) {
  const translation: TranslationGroup = trans.merge(TranslationKey.ALERT_HISTORY)

  const { responders, status, resolverName, firstLocation, lastLocation, resolverUser } = alertSession
  const { startTime, endTime } = getAlertTimes(alertSession)
  const responderCount = responders ? responders.length : 0
  const isOpen = status === HealthTypesKeys.OPEN
  const locationStart = `${translation.room} ${firstLocation?.room || translation.na}, ${translation.floor} ${
    firstLocation?.floor || translation.na
  }`
  const locationEnd = `${translation.room}  ${lastLocation?.room || translation.na},  ${translation.floor}  ${
    lastLocation?.floor || translation.na
  }`
  const startingTime = formatTime(startTime, LOCAL_TIME)
  const endingTime = formatTime(endTime, LOCAL_TIME, translation.ongoing)
  const diffDates = endTime ? formatDistance(startTime, endTime) : ''
  const resolution =
    status === HealthTypesKeys.RESOLVED
      ? `${diffDates?.replace(translation.ago, '')}`
      : responders.length > 0
      ? translation.in_process
      : translation.ongoing

  return (
    <div css={isOpen ? { ...alertLocationBackground, ...isOpenStyle } : alertLocationBackground} onClick={handleClick}>
      <div css={isOpen ? { ...alertLocationInfoWrapper, ...isOpenStyle } : alertLocationInfoWrapper}>
        <div css={{ ...alertHistoryTitle, ...setCardTitleColor(alertSession, responderCount) }}>
          <span>{title}</span>
          <div css={isOpen ? { ...clockInfoWrapper, ...isWhiteStyle } : clockInfoWrapper}>
            {isOpen ? <WhiteClockIcon /> : <ClockIcon />}
            <span>{resolution}</span>
          </div>
        </div>
        <div>
          <div css={locationInfoWrapper}>
            <div css={locationInfoContainer}>
              <LocationFlowIcon data-location-icon="flow-icon" />

              <div>
                <div css={locationInfoDetail}>
                  <div>
                    <div css={alertLabel}>{translation.start_loc} </div>
                    <div>{locationStart}</div>
                  </div>
                  <div css={timeLabel}>{startingTime}</div>
                </div>

                {!isOpen && (
                  <div css={locationInfoDetail}>
                    <div>
                      <div css={alertLabel}>{translation.end_loc}</div>
                      <div>{locationEnd}</div>
                    </div>
                    <div css={timeLabel}>{endingTime}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div css={separator} />

          <div css={responderInfoWrapper}>
            <span>
              <span>{translation.Responder_s}</span>
              {renderResponders(responderCount)}
            </span>{' '}
            <span>
              {`${translation.resolved_by}
              ${resolverName || `${resolverUser?.firstName} ${resolverUser?.lastName}` || translation.na}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CondensedInfoGadget
