import { shallowEqual } from 'react-redux'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { AddUserModal } from 'components/partials/AddUserModal'
import { PreConfiguredTableView } from 'components/partials/TableView'
import { ActionButton, StyledSubHeader } from 'components/widgets'
import useModal from 'lib/hooks/useModal'
import {
  Organization,
  SortBy,
  SortOrder,
  TableState,
  TableToolEvent,
  trans,
  TranslationGroup,
  TranslationKey,
  User,
} from 'lib/types'
import { invertBinaryEnum } from 'lib/utils/common'
import { Grants, Users } from 'models'
import * as utils from '../utils'
import { useHistory } from 'react-router-dom'
import { useQuery } from 'models/modelUtils'

export const OrgUsersTable = ({ selectedOrg }: { selectedOrg: Organization }) => {
  const translation: TranslationGroup = trans.group(TranslationKey.ADMIN)
  const history = useHistory()
  const query = useQuery()
  const { Modal, openModal, closeModal } = useModal(translation.add_a_user)
  const orgUsersMap = Grants.useOrgUserMap()
  const { users, loading } = Users.use(({ users, loading }) => {
    return { users, loading }
  }, shallowEqual)
  const [tableState, setTableState] = useState<TableState<string, utils.UsersColumn>>({
    filterBy: utils.userFilterOptions()[0],
    sortBy: utils.usersSortOptions()[0].sortBy,
    searchBy: '',
  })

  const userIds = selectedOrg ? orgUsersMap[selectedOrg.id] : []
  const usersSelected = users.filter(user => userIds?.includes(user.id))

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  const filteredUsers: User[] = utils
    .searchUsers(usersSelected, tableState.searchBy)
    .filter(user => !!user)
    .sort(utils.getSortUserCompareFn(tableState.sortBy))

  function handleHeaderClick(header: utils.UsersColumn | utils.EmptyColumn) {
    if (header === utils.EmptyColumn.Empty) return

    setTableState(state => ({
      ...state,
      sortBy: {
        field: header,
        order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
      },
    }))
  }

  // TODO: user row click
  function handleRowClick() {}

  function handleToolEvent(toolEvent: TableToolEvent<string, utils.UsersColumn | utils.EmptyColumn>) {
    if (toolEvent.sortByRequest?.field === utils.EmptyColumn.Empty) return

    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: (toolEvent.sortByRequest as SortBy<utils.UsersColumn>) || state.sortBy,
      searchBy: _.isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  return (
    <>
      <StyledSubHeader>{translation.associated_users}</StyledSubHeader>
      <PreConfiguredTableView
        sortedData={filteredUsers}
        tableState={tableState}
        columnConfigs={utils.getAdminUsersColumnConfig(selectedOrg.id)}
        filterOptions={utils.userFilterOptions()}
        sortOptions={utils.usersSortOptions()}
        onHeaderClick={handleHeaderClick}
        onRowClick={handleRowClick}
        onToolEvent={handleToolEvent}
      >
        <ActionButton onClick={openModal}>{translation.add_user}</ActionButton>
      </PreConfiguredTableView>
      <Modal>
        <AddUserModal open={true} orgId={selectedOrg.id} close={closeModal} />
      </Modal>
    </>
  )
}
