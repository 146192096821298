export enum DevicesColumnKeys {
  Health = 'health',
  Battery = 'batteryPercent',
  Name = 'name',
  Type = 'deviceType',
  RSSI = 'rssi',
  Firmware = 'appVersion',
  LastCheckIn = 'lastSeenAt',
  ButtonConnected = 'buttonPaired',
}
