/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { ChartsWrapper } from 'components/widgets'
import { NameNumberValuePair } from 'lib/types'
import { HealthLabelsKeys } from 'lib/constants'
import * as utils from './utils'
import StyledPieChartLegend from 'components/partials/StyledPieChartLegend'
import { chartPageContainer } from 'styles'
import StyledPieChart from 'components/partials/StyledPieChart'
import { HealthKey, LegendLabel } from 'components/partials/MainSidebar/ChartsSection'

export const Charts = ({
  responders,
  legendLabel,
}: {
  responders: any
  width?: number
  height?: number
  legendLabel: LegendLabel
}) => {
  const green = legendLabel[HealthKey.GREEN]
  const yellow = legendLabel[HealthKey.YELLOW]
  const red = legendLabel[HealthKey.RED]
  const itemsData: NameNumberValuePair[] = [
    {
      name: red,
      value: responders.critical,
    },
    {
      name: yellow,
      value: responders.moderate,
    },
    {
      name: green,
      value: responders.healthy,
    },
  ]
  return (
    <ChartsWrapper>
      <div css={chartPageContainer}>
        <StyledPieChart data={itemsData} legendLabel={legendLabel} />
        <StyledPieChartLegend
          green={`${itemsData[2].value} ${green}`}
          yellow={`${itemsData[1].value} ${yellow}`}
          red={`${itemsData[0].value} ${red}`}
          reverse
        />
      </div>
    </ChartsWrapper>
  )
}
