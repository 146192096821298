export enum RespondersColumnKeys {
  Health = 'health',
  LastSeen = 'lastCheckedInAt',
  Type = 'responderType',
  Name = 'name',
  Email = 'email',
  Membership = 'membership',
  Push = 'push',
  SMS = 'SMS',
}
