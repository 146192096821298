/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import styled from '@emotion/styled/macro'
import { DialogTitle, DialogActions, FormControl, TextareaAutosize, Select } from '@material-ui/core'
import { getAppColors } from 'lib/constants'

// @ts-ignore
export const StyledDialogTitle = styled(DialogTitle)({
  fontFamily: 'Montserrat',
  h2: {
    fontFamily: 'Montserrat',
    fontSize: '1.8rem',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '2.9rem',
    letterSpacing: '0',
    textAlign: 'left',
  },
})

export const StyledFormControl = styled(FormControl)({
  width: '100%',
  marginBottom: '15px',
})

// @ts-ignore
export const StyledInputLabel = styled.label({
  color: getAppColors('--color-textfield-label'),
  fontFamily: 'Montserrat',
  fontSize: '1.2rem',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
})

export const StyledTextArea = styled(TextareaAutosize)({
  background: getAppColors('--color-gray-3'),
  borderRadius: '10px',
  padding: '5px 10px',
  border: '0',
  marginTop: '20px',
  fontFamily: 'Montserrat',
})

export const StyledSelect = styled(Select)({
  background: getAppColors('--color-gray-3'),
  borderRadius: '10px',
  padding: '5px 10px',
  border: '0',
  marginTop: '20px',
  fontFamily: 'Montserrat',
})

export const StyledDialogActions = styled(DialogActions)({
  justifyContent: 'center',
})
