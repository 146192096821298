import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core'
import {
  HighlightOff as HighlightOffIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'
import { getConfigFromSummary, viewSelectedOrgDetail } from './utils'
import styles from './GadgetInfoCard.module.css'
import { trans, TranslationGroup, TranslationKey } from 'lib/types/translation'
import { useGlobal } from 'contexts/GlobalProvider'

type Props = {
  gadget: any
  title: string
  organization: any
}

function GadgetInfoCard({ gadget, title, organization }: Props) {
  const history = useHistory()
  const summaryConfig = getConfigFromSummary(title)
  const translation: TranslationGroup = trans.group(TranslationKey.ORG_GLOBAL_CARD)
  const { setSelectedOrg } = useGlobal()
  return (
    <div className={styles.gadgetInfoWrapper}>
      <h2 className={styles.orgName}>{title}</h2>
      <div className={styles.gadgetLabelWrapper}>
        <HighlightOffIcon className={styles.highlightIconStyle} />
        {(gadget ? gadget[summaryConfig.keyRed] : 0) + summaryConfig.messageRed}
      </div>
      {summaryConfig.keyYellow && (
        <div className={styles.gadgetLabelWrapper}>
          <RemoveCircleOutlineIcon className={styles.circleIconStyle} />
          {(gadget ? gadget[summaryConfig.keyYellow] : 0) + summaryConfig.messageYellow}
        </div>
      )}
      <div className={styles.gadgetLabelWrapper}>
        <CheckCircleOutlineIcon className={styles.checkCircleIconStyle} />
        {(gadget ? gadget[summaryConfig.keyGreen] : 0) + summaryConfig.messageGreen}
      </div>
      <div className={styles.viewDetailButtonWrapper}>
        <Button
          className={styles.detailButton}
          onClick={() => {
            viewSelectedOrgDetail(history, organization, summaryConfig?.goPath)
              setSelectedOrg(organization)
              }}
        >
          {translation.view_details}
        </Button>
      </div>
    </div>
  )
}

export default GadgetInfoCard
