import { shallowEqual } from 'react-redux'
import { memo, useEffect, useState } from 'react'
import _ from 'lodash'
import { ALL_ORGS_SELECTED, RespondersColumnKeys } from 'lib/constants'
import {
  ActionButton,
  AddDeviceQRC,
  ViewWrapper,
  PageWrapper,
  ViewSpacerWrapper,
  StyledHeader,
} from 'components/widgets'
import {
  Responders,
  NavState,
  DetailsDrawer,
  Orgs,
  Modal,
  PopUpNotifications,
  LiveResponders,
  Users,
  Devices,
} from 'models'
import { isAdmin, isInstaller, isSuperAdmin } from 'lib/utils/auth'
import * as utils from './utils'
import { PreConfiguredTableView } from 'components/partials/TableViewV2'
import {
  SortOrder,
  TableState,
  TableToolEvent,
  TranslationGroup,
  trans,
  TranslationKey,
  ResponderV5,
  ResponderType,
} from 'lib/types'
import { invertBinaryEnum } from 'lib/utils/common'

import { useHistory, useParams } from 'react-router-dom'
import { RESPONDERS_PATH } from 'lib/constants'
import { useQuery } from 'models/modelUtils'
import { RenderCounter } from 'components/widgets/RenderCounter'

import { useGlobal } from 'contexts/GlobalProvider'
import { getAlertSourceById, getUserByEmail, getUserById } from 'models/api'
import { useResponders } from 'contexts/RespondersProvider'
import { getRequestV5 } from 'models/api/rest'
import { ResponderDetailDrawer } from 'components/partials/DrawerDetails/ResponderDetailDrawer'

interface RouteParams {
  id: string
}

export const RespondersPage = memo(() => {
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  const { selectedOrgId, selectedOrg } = useGlobal()

  const translation: TranslationGroup = trans.group(TranslationKey.DEVICES_TABLE_VIEW)
  const common: TranslationGroup = trans.common()

  const {
    setModalContent,
    openModal: openQrcModal,
    closeModal,
  } = Modal.useStaticModal(`${common.register_qr} ${selectedOrg && selectedOrg.name}`)
  const baseRoute = RESPONDERS_PATH

  const { responders, setResponders, loading, setLoading, tableState, setTableState, pagination, setPagination } =
    useResponders()
  const liveRespondersLoaded = LiveResponders.use(({ loading }) => loading)
  console.log({ responders })

  useEffect(() => {
    const page = query.get('page')
    if (page === '1' || loading) return
    query.set('page', '1')
    history.push({
      search: query.toString(),
    })
  }, [tableState])

  useEffect(() => {
    if (!loading) {
      console.log('Live responders change')
    }
  }, [loading, responders, tableState, query, liveRespondersLoaded])

  useEffect(() => {
    setModalContent(<AddDeviceQRC responders={true} closeModal={closeModal} />)
  }, [responders, closeModal, setModalContent])

  function handleDrawerClose() {
    history.push({
      pathname: `${baseRoute}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleRowClick(row: ResponderV5) {
    if (row.id != id) {
      history.push({
        pathname: `${baseRoute}/${row.id}`,
        search: query.toString(),
      })
    }
    showDetailsDraw(row)
  }

  async function showDetailsDraw(row: ResponderV5) {
    const u = row.responderType === ResponderType.USER
    DetailsDrawer.show({
      drawerComponent: ResponderDetailDrawer,
      drawerProps: {
        responder: row,
        close: handleDrawerClose,
      },
    })
  }

  function handleHeaderClick(header: string) {
    console.log({ header })
    // I will need to ignore certain header clicks as sorting is missing for some columns
    const excludeList = ['push', 'SMS', 'email', 'membership']
    if (excludeList.includes(header)) return

    setLoading(true)
    getRequestV5({
      endpoint: '/responders',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
            ? { field: 'responderType', value: tableState.filterBy == 'Users' ? 'user' : 'alert_source' } // patch
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: {
          field: header,
          order: tableState.sortBy.order === SortOrder.Ascending ? SortOrder.Descending : SortOrder.Ascending,
        },
      },
    }).then(response => {
      const data = response.data
      setResponders(data.data)
      setPagination(data.meta)
      setLoading(false)
    })
    setTableState(state => {
      return Object.assign({}, state, {
        sortBy: {
          field: header,
          order: state.sortBy.field === header ? invertBinaryEnum(state.sortBy.order) : SortOrder.Ascending,
        },
      })
    })
  }

  function handleToolEvent(toolEvent: TableToolEvent<string, RespondersColumnKeys>) {
    console.log({ toolEvent })
    if (toolEvent.filterByRequest) {
      setLoading(true)
      getRequestV5({
        endpoint: '/responders',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            toolEvent.filterByRequest != utils.getFilterOptions()[0] // not all responders
              ? { field: 'responderType', value: toolEvent.filterByRequest == 'Users' ? 'user' : 'alert_source' } // Patch - need to refactor filter options type
              : undefined,
          searchBy: { value: tableState.searchBy },
        },
      }).then(response => {
        const data = response.data
        setResponders(data.data)
        setPagination(data.meta)
        setLoading(false)
      })
    } else if (toolEvent.searchByRequest || toolEvent.searchByRequest == '') {
      setLoading(true)
      getRequestV5({
        endpoint: '/responders',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
              ? { field: 'responderType', value: tableState.filterBy == 'Users' ? 'user' : 'alert_source' } // patch
              : undefined,
          searchBy: { value: toolEvent.searchByRequest },
        },
      }).then(response => {
        const data = response.data
        setResponders(data.data)
        setPagination(data.meta)
        setLoading(false)
        // setTableTools({ ...tableTools, pagination: data.meta })
      })
    } else if (toolEvent.sortByRequest) {
      console.log('so', toolEvent.sortByRequest)

      setLoading(true)
      getRequestV5({
        endpoint: '/responders',
        headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
        params: {
          filterBy:
            tableState.filterBy != utils.getFilterOptions()[0] // not all beacons
              ? { field: 'responderType', value: tableState.filterBy == 'Users' ? 'user' : 'alert_source' } // patch
              : undefined,
          searchBy: { value: tableState.searchBy },
          sortBy: { field: toolEvent.sortByRequest.field, order: toolEvent.sortByRequest.order },
        },
      }).then(response => {
        const data = response.data
        setResponders(data.data)
        setPagination(data.meta)
        setLoading(false)
        // setTableTools({ ...tableTools, pagination: data.meta })
      })
    }
    setTableState(state => ({
      filterBy: toolEvent.filterByRequest || state.filterBy,
      sortBy: toolEvent.sortByRequest || state.sortBy,
      searchBy: _.isNil(toolEvent.searchByRequest) ? state.searchBy : toolEvent.searchByRequest,
    }))
  }

  function handlePageChange(page: any, newPage: any) {
    console.log('page:', page, 'np:', newPage)
    setLoading(true)
    getRequestV5({
      endpoint: '/responders',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        pagination: { currentPage: newPage },
        filterBy:
          tableState.filterBy != utils.getFilterOptions()[0] // all beacons selected in filter?
            ? { field: 'responderType', value: tableState.filterBy == 'Users' ? 'user' : 'alert_source' } // Patch - need to refactor filter options type
            : undefined,
        searchBy: { value: tableState.searchBy },
        sortBy: {
          field: tableState.sortBy.field,
          order: tableState.sortBy.order,
        },
      },
    }).then(response => {
      const data = response.data
      setResponders(data.data)
      setPagination(data.meta)
      setLoading(false)
    })
  }

  const isAdminOrInstaller = isAdmin(selectedOrgId) || isInstaller(selectedOrgId) || isSuperAdmin()

  return (
    <>
      <PageWrapper>
        <ViewWrapper>
          <RenderCounter name={'RespondersPage'} />
          <ViewSpacerWrapper>
            <StyledHeader>{common.responders}</StyledHeader>
            <PreConfiguredTableView
              sortedData={responders}
              tableState={tableState}
              columnConfigs={utils.getColumnConfigs()}
              filterOptions={utils.getFilterOptions()}
              sortOptions={utils.getSortOptions()}
              getRowToolTip={() => ''}
              onHeaderClick={handleHeaderClick}
              onRowClick={handleRowClick}
              onToolEvent={handleToolEvent}
              selectedRowId={id}
              loading={loading}
              pagination={pagination}
              onPageChange={handlePageChange}
            >
              <ActionButton onClick={() => utils.handleDownloadCSV(selectedOrgId || '')}>
                {common.download_csv}
              </ActionButton>
              {isAdminOrInstaller && (
                <ActionButton
                  onClick={() => {
                    if (selectedOrgId === ALL_ORGS_SELECTED) alert(translation.register_select_org)
                    else openQrcModal()
                  }}
                >
                  {common.register} {common.responders}
                </ActionButton>
              )}
            </PreConfiguredTableView>
          </ViewSpacerWrapper>
        </ViewWrapper>
      </PageWrapper>
    </>
  )
})

RespondersPage.displayName = 'RespondersPage'
