/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { ErrorOutline } from '@material-ui/icons'
import { ALERTS_PATH, getAppColors, HealthLabelsKeys } from 'lib/constants'
import { AlertSession, RouteParams, TranslationGroup, trans } from 'lib/types'
import { formatLocalTime } from 'lib/utils'
import * as utils from '../utils'
import { ReactComponent as LocationFlowIcon } from 'static/images/alerts/location-flow-icons.svg'
import { textAlign, boxSizing, textTransform, fontWeight } from 'styles'
import { NumberBubble } from 'components/widgets'
import { DetailsDrawer } from 'models'
import { AlertDetailDrawer } from 'components/partials/DrawerDetails'
import { useQuery } from 'models/modelUtils'
import { useHistory, useParams } from 'react-router-dom'

const pluralize = require('pluralize')

const alertInfoCardStyles = {
  cursor: 'pointer',
  width: '400px',
  minWidth: '400px',
  borderRadius: '4px',
  border: '1px solid var(--color-gray-2)',
  backgroundColor: getAppColors('--color-gray-3'),
}

const iconStyles = {
  color: 'white',
}

const statusStyles = {
  margin: 'auto 0 auto 5px',
}

const initiatorInfo = {
  ...statusStyles,
  overflow: 'hidden',
  maxWidth: '50%',
  textOverflow: 'ellipsis',
}

const bodyStyles = {
  label: 'Body',
  display: 'flex',
  borderBottom: '1px solid var(--color-gray-2)',
  borderTop: '1px solid var(--color-gray-2)',
  padding: '5px',
  ...boxSizing('border-box'),
}

const roomIconStyles = {
  flex: '0 0 auto',
  margin: 'auto 10px',
}

const locationText = {
  maxWidth: '265px',
}

const locationStyles = {
  flex: '1 0 auto',
  margin: 'auto 10px',
}

const locationLabelStyles = {
  ...fontWeight('bold'),
}

const timeStyles = {
  flex: '0 0 auto',
  margin: 'auto 10px auto 0',
}

const footerStyles = {
  label: 'Footer',
  display: 'flex',
  padding: '10px',
}

const responderStyles = {
  flex: '0 0 auto',
  marginRight: '10px',
}

const AlertInfoCard = ({ alertSession }: { alertSession: AlertSession }) => {
  const common: TranslationGroup = trans.common()
  const history = useHistory()
  const { id } = useParams<RouteParams>()
  const query = useQuery()
  const { initiatorName, lastLocation, firstTime, activeRespondersCount } = alertSession

  const isNoResponse = utils.getAlertHealth(alertSession) === HealthLabelsKeys.ACTIVE_NO_RESPONSE

  const headerStyles = {
    label: 'Header',
    color: getAppColors('--color-white'),
    fontFamily: 'Montserrat',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 900,
    lineHeight: '1',
    letterSpacing: '0.1rem',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    ...textAlign('right'),
    ...textTransform('uppercase'),
    backgroundColor: isNoResponse ? getAppColors('--color-error') : getAppColors('--color-warning'),
    borderRadius: '3px 3px 0 0',
  }

  function handleDrawerClose() {
    history.push({
      pathname: `${ALERTS_PATH}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  function handleCardClick(row: AlertSession) {
    if (row.id != id) {
      history.push({
        pathname: `${ALERTS_PATH}/${row.id}`,
        search: query.toString(),
      })
    }
    DetailsDrawer.show({
      drawerComponent: AlertDetailDrawer,
      drawerProps: {
        alertSession: row,
        close: handleDrawerClose,
      },
    })
  }
  return (
    <div
      css={alertInfoCardStyles}
      onClick={() => {
        handleCardClick(alertSession)
      }}
    >
      <div css={headerStyles}>
        <div css={initiatorInfo}>{initiatorName}</div>
        <div css={{ display: 'flex' }}>
          <ErrorOutline css={iconStyles} />
          <div css={statusStyles}>{isNoResponse ? common.no_response : common.in_progress}</div>
        </div>
      </div>

      <div css={bodyStyles}>
        <LocationFlowIcon css={roomIconStyles} />
        <div css={locationStyles}>
          <div css={locationLabelStyles}>{common.location}</div>
          <div css={locationText}>{`${common.room} ${lastLocation?.room || common.NA}, ${common.floor} ${
            lastLocation?.floor || common.NA
          }`}</div>
        </div>
        <div css={timeStyles}>{formatLocalTime(firstTime)}</div>
      </div>

      <div css={footerStyles}>
        <span css={responderStyles}>{`${pluralize(common.responder, activeRespondersCount)}:`}</span>
        <NumberBubble value={activeRespondersCount} />
      </div>
    </div>
  )
}

export default AlertInfoCard
