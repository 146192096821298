import { useState } from 'react'
import styled from '@emotion/styled'
import { MenuItem, Select, FormControl, Button } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'

import useModal from 'lib/hooks/useModal'
import FirmwareSettingsModal from './FirmwareSettingsModal'
import {
  getFirmwareUpdateOption,
  getHardwareTypeName,
  getApplyChangesButtonStatus,
  useAllowedHardware,
} from 'lib/utils/hardwareAndFirmware'
import { Firmware, FirmwareLTEDataRequest, Organization, SubTypeOfHardware, trans, TranslationGroup } from 'lib/types'
import { FirmwareGroup } from 'models/Firmware'
import {
  BORDER1_COLOR,
  BRAND_COLOR,
  GRAY3_COLOR,
  LONGLIFE_APP,
  PEERLESS_APP,
  PEERLESS_APP_V2,
  PRIMARY_COLOR,
} from 'lib/constants'
import { snakeToCamel } from 'lib/utils/casing'

const StyledHardwareTableContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const StyledContainer = styled.div({
  display: 'flex',
  span: {
    flex: 1,
  },
})

const StyledLinkableText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  color: '#29a096',
  texTransform: 'none',
})

const StyledTitleText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.4rem',
  letterSpacing: '0.1rem',
  lineHeight: '1.5rem',
  textTransform: 'uppercase',
  color: PRIMARY_COLOR,
})

const StyledSubText = styled.span({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

const StyledSeparator = styled.div({
  border: `1px solid ${BORDER1_COLOR}`,
  marginTop: '38px',
  marginBottom: '40px',
})

const StyledTableContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
})

const StyledFormControl = styled(FormControl)({
  background: GRAY3_COLOR,
  borderRadius: '10px',
  borderColor: BRAND_COLOR,
  textAlign: 'center',
  width: '250px',
})

const StyledButton = styled(Button)({
  color: PRIMARY_COLOR,
  padding: '8px 20px',
  border: `2px solid ${PRIMARY_COLOR}`,
  boxSizing: 'border-box',
  fontFamily: 'Montserrat',
  boxShadow: '0px 4px 8px rgba(20, 48, 72, 0.1)',
  fontSize: '1.0rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.2rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginRight: '5px',
  cursor: 'pointer',
  borderRadius: '2px',
})

type Props = {
  org: Organization | null
  subHardware: SubTypeOfHardware[]
  firmwares: Firmware[]
  firmwaresByTypeAuto: FirmwareGroup
}
export const HardwareTable = ({ org, subHardware, firmwares, firmwaresByTypeAuto }: Props) => {
  const allowedHardware: SubTypeOfHardware[] = useAllowedHardware()
  const [firmwareSetting, setFirmwareSetting] = useState<FirmwareLTEDataRequest>({})
  const settings: TranslationGroup = trans.settings()
  const common: TranslationGroup = trans.common()

  const {
    Modal: ConfirmationModal,
    openModal: openConfirmationModal,
    closeModal,
  } = useModal(settings.firmware_settings_change)

  const getFirmwareValue = (firmwareType: string) => {
    const savedOrgFirmware = org?.props?.firmware || {}
    const saved = savedOrgFirmware[snakeToCamel(firmwareType)] || 'notUpdate'
    console.log('fw type: ', firmwareType)
    console.log({ savedOrgFirmware })
    console.log({ saved })
    console.log({ firmwareSetting })
    return firmwareSetting[firmwareType] || saved
  }

  const renderHardwareRow = (sub: SubTypeOfHardware) => {
    const name =
      sub.name.toLowerCase() === PEERLESS_APP
        ? 'Peerless'
        : sub.name.toLowerCase() === PEERLESS_APP_V2
        ? 'Peerless2'
        : sub.name.toLowerCase() === LONGLIFE_APP
        ? 'Longlife'
        : sub.name
    const key = `${sub.hardwareType}_${sub.name}`
    const firmwareValue = getFirmwareValue(sub.firmwareType)

    return (
      <StyledContainer key={key}>
        <StyledSubText>{getHardwareTypeName(sub.hardwareType)}</StyledSubText>
        <StyledSubText>{name}</StyledSubText>
        <StyledSubText>
          <StyledFormControl>
            <Select
              id="hardware-select"
              value={firmwareValue}
              onChange={e => {
                setFirmwareSetting({
                  ...firmwareSetting,
                  [sub.firmwareType!]: e.target.value as string,
                })
              }}
              IconComponent={KeyboardArrowDownIcon}
              disableUnderline
            >
              <MenuItem value={'notUpdate'}>{settings.do_not_update}</MenuItem>
              {firmwaresByTypeAuto[sub.firmwareType] &&
                firmwaresByTypeAuto[sub.firmwareType].map(firmware => {
                  return (
                    <MenuItem value={firmware.version} key={`${key}_${firmware.environment}_${firmware.version}`}>
                      {getFirmwareUpdateOption(firmware?.version, firmware.environment)}
                    </MenuItem>
                  )
                })}
            </Select>
          </StyledFormControl>
        </StyledSubText>
      </StyledContainer>
    )
  }

  const hardwareRows = allowedHardware.map(sub => {
    return renderHardwareRow(sub)
  })

  return (
    <>
      {!hardwareRows.length ? (
        <StyledLinkableText>{settings.register}</StyledLinkableText>
      ) : (
        <StyledHardwareTableContainer>
          <ConfirmationModal>
            <FirmwareSettingsModal
              org={org}
              subType={subHardware}
              newSetting={firmwareSetting}
              firmwares={firmwares}
              closeModal={closeModal}
            />
          </ConfirmationModal>
          <StyledContainer>
            <StyledTitleText>{common.hardware_type}</StyledTitleText>
            <StyledTitleText>{common.sub_type}</StyledTitleText>
            <StyledTitleText>{settings.target_version}</StyledTitleText>
          </StyledContainer>
          <StyledSeparator />
          <StyledTableContent>
            {hardwareRows}
            <StyledContainer>
              <StyledButton onClick={() => setFirmwareSetting({})}>{common.cancel}</StyledButton>
              <StyledButton
                disabled={getApplyChangesButtonStatus(firmwareSetting)}
                onClick={() => openConfirmationModal()}
              >
                {common.apply_changes}
              </StyledButton>
            </StyledContainer>
          </StyledTableContent>
        </StyledHardwareTableContainer>
      )}
    </>
  )
}
