import { CreateProperty } from 'components/partials/CreateProperty'
import React, { useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import {
  StyledDialogTitle,
} from 'components/widgets'
import {  Firmware, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { group } from 'console'
import UploadFirmwareModalContent from 'components/partials/UploadFirmwareModalContent/UploadFirmwareModalContent'


type Props = {
  open: boolean
  setModalOpen: Function
  firmwares: Firmware[]
  firmwareType: string
  hardwareType: string
  subType: string
}

const UploadFirmwareModal = ({ open, setModalOpen, firmwares, firmwareType, subType, hardwareType}: Props) => {

  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN_FIRMWARE)

  return (
      <Dialog style={{ animationFillMode: 'forwards'}}  open={open} onClose={() => setModalOpen(false)} maxWidth="sm" fullWidth>
        <StyledDialogTitle>{translation.upload_new}</StyledDialogTitle>
        <DialogContent>    
          <UploadFirmwareModalContent           
          closeModal={() => setModalOpen(false)}
          firmwares={firmwares}
          firmwareType={firmwareType}
          hardwareType={hardwareType}
          subType={subType} />
        </DialogContent>
      </Dialog>

  )
}

export default UploadFirmwareModal