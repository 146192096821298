import { deleteRequest, getRequest, postRequest, putRequest } from './rest'
import { API_ALERT_TARGETS } from 'lib/constants'
import { AlertTarget, AlertTargetRequest, ResponderTarget } from 'lib/types/alertTarget'
import { isArguments } from 'lodash'
import { PopUpNotifications } from 'models'

export const getAlertTargets = (userId: string): Promise<AlertTarget[]> =>
  getRequest({
    endpoint: `${API_ALERT_TARGETS}?user_id=${userId}`,
  }).then(({ data }) => data.data)

export const createAlertTarget = (
  alertTarget: AlertTargetRequest,
  callbackFn: Function = () => null,
): Promise<AlertTarget> =>
  postRequest({
    endpoint: API_ALERT_TARGETS,
    data: { alertTarget },
    header: { org_id: alertTarget.orgId },
  }).then(({ data }) => {
    callbackFn()
    return data.data
  })

export const updateAlertTarget = (
  alertTarget: AlertTargetRequest,
  callbackFn: Function = () => null,
): Promise<AlertTarget> =>
  putRequest({
    endpoint: `${API_ALERT_TARGETS}/${alertTarget.id}`,
    data: { alertTarget },
    header: { org_id: alertTarget.orgId },
  }).then(({ data }) => {
    callbackFn()
    return data.data
  })

export const deleteAlertTarget = (id: string) =>
  deleteRequest({
    endpoint: `${API_ALERT_TARGETS}/${id}`,
  })

  // Function to delete multiple alert targets
export const deleteAlertTargets = async (targets: ResponderTarget[]) => {
  try {
    // Delete each target asynchronously
    await Promise.all(targets.map(target => deleteAlertTarget(target.id)));
    
    // After deletion is completed, fire a success message
    PopUpNotifications.fireSuccess({ content: 'Alert Targets Removed' });
  } catch (error) {
    // Handle errors if deletion fails
    console.error('Error deleting alert targets:', error);
    // Optionally, fire an error message
    PopUpNotifications.fireError({ content: 'Failed to remove alert targets' });
  }
}

// Triggers api to send confirmation for email alert notifications
export const sendConfirmEmailAlerts = (userId: string, email: string, orgId: string) => {
  postRequest({
    endpoint: `email_alert/${userId}`,
    data: {
      email: email,
      org_id: orgId,
    },
  })
}

// Updated alert target status to accepted
export const confirmEmailAlerts = (token: string) => {
  putRequest({
    endpoint: `email_alert/${token}`,
  })
}
