import { createReduxModule } from 'hooks-for-redux'
import { AlertTarget, AlertTargetRequest, AlertTargetStatus } from 'lib/types/alertTarget'
import { PopUpNotifications } from 'models'
import { createAlertTarget, getAlertTargets, updateAlertTarget, deleteAlertTarget } from './api/alertTargets'

interface AlertTargetResponse {
  userId: string
  alertTargets: AlertTarget[]
}

export interface UserAlertTargets {
  [userId: string]: AlertTarget[]
}

export interface AlertTargetState {
  alertTargetsByUserId: UserAlertTargets
  loading: boolean
  error: any
}

const initialState: AlertTargetState = {
  alertTargetsByUserId: {},
  loading: false,
  error: null,
}

export const [use, { setAlertTargets, setLoading, setError }, store] = createReduxModule('alertTargets', initialState, {
  setAlertTargets: (state: AlertTargetState, { userId, alertTargets }: AlertTargetResponse) => ({
    ...state,
    alertTargetsByUserId: { [userId]: alertTargets },
  }),
  setLoading: (state: AlertTargetState, loading: boolean) => ({ ...state, loading }),
  setError: (state: AlertTargetState, error: any) => ({ ...state, error }),
})

export const setErrorWithNotificationAndRethrow = (error: any) => {
  try {
    const tmp = error.response.data
    const content = tmp.errors[0].detail
    PopUpNotifications.fireError({ content })
  } catch (e) {
    console.warn(e)
    PopUpNotifications.fireErrorObject(error)
  }
  setError(error)
  throw error
}

export const reload = (userId: string) => {
  setLoading(true)
  Promise.resolve()
    .then(() => getAlertTargets(userId))
    .then(result => setAlertTargets({ userId, alertTargets: result }))
    .then(() => setLoading(false), setErrorWithNotificationAndRethrow)
}

const doUpdateAction = (userId: string, f: () => Promise<AlertTarget | any>) => {
  setLoading(true)
  return Promise.resolve()
    .then(f)
    .then(result => {
      reload(userId)
      return result
    }, setError)
    .then(result => {
      setLoading(false)
      return result
    })
}

export const subscribe = (target: AlertTargetRequest, callbackFn: Function) => {
  const f = target.id ? updateAlertTarget : createAlertTarget
  return doUpdateAction(target.userId!, () => f(target, callbackFn))
}

export const unsubscribe = (target: AlertTarget) => {
  return doUpdateAction(target.attributes.userId!, () => {
    if (target.attributes.forwardType === 'sms') return deleteAlertTarget(target.id)
    else return updateAlertTarget({ ...targetRequest(target), status: AlertTargetStatus.REFUSED })
  })
}


const targetRequest = (target: AlertTarget): AlertTargetRequest => {
  return { id: target.id, ...target.attributes }
}
