import React, { useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import {
  StyledDialogTitle,
} from 'components/widgets'
/**
 * Returns a MaterialUI modal component
 *
 * @param title Text displayed at the top of the modal
 * @param content React component to be rendered inside the modal
 */
export default function useModal(
  title: string,
  handleClose?: Function,
) {
  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

 const onClose = handleClose || closeModal

 console.log('Modal hook is called')

  const Modal = ({ children }: { children: React.ReactChild }) => (
    <Dialog style={{ animationFillMode: 'forwards'}}  open={open} onClose={() => onClose()} maxWidth="sm" fullWidth>
      <StyledDialogTitle>{title}</StyledDialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  )

  return { Modal, openModal, closeModal }
}