/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { InputLabel, Input } from '@material-ui/core'
import { ActionButton, StyledDialogActions } from 'components/widgets'
import { trans, TranslationGroup } from 'lib/types'
import { PopUpNotifications } from 'models'
import { useCallback, useState } from 'react'
import { GRAY3_COLOR, TEXTFIELD_LABEL_COLOR } from 'lib/constants'
import { Dialog, DialogContent } from '@material-ui/core'
import {
  StyledDialogTitle,
} from 'components/widgets'

const ElementWrapper = styled.div({
  width: '100%',
  alignContent: 'center',
})

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '25px',
})

const StyledFormControl = styled.div({
  width: '100%',
  marginBottom: '15px',
})

const StyledInputLabel = styled(InputLabel)({
  color: TEXTFIELD_LABEL_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.4rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.5rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginBottom: '20px',
})

const StyledInput = styled(Input)({
  background: GRAY3_COLOR,
  borderRadius: '10px',
  padding: '5px 10px',
  fontFamily: 'Montserrat',
})

export const EditElementModal = ({
  open,
  title,
  element,
  fieldName,
  close,
  action,
}: {
  open: boolean
  title: string
  element: any
  fieldName: string
  close: Function
  action: Function
}) => {
  const [fieldValue, setFieldValue] = useState(element[fieldName])
  const common: TranslationGroup = trans.common()

  const handleFormSubmit = useCallback((e) => {
  e.preventDefault(); // Prevent default form submission behavior
  close(); // Close the modal
  Object.defineProperty(element, fieldName, {
    value: fieldValue,
  });
  PopUpNotifications.tryWithErrorNotifications(action(element));
}, [close, element, fieldName, fieldValue, action]);

  return (
      <Dialog style={{ animationFillMode: 'forwards'}}  open={open} onClose={() => null} maxWidth="sm" fullWidth>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent>    
          <ElementWrapper>
          <StyledContainer>
            <div>
              <form>
                <StyledFormControl>
                  <StyledInputLabel htmlFor="field-value">{title}</StyledInputLabel>
                  <StyledInput
                    id="field-value"
                    disableUnderline
                    fullWidth
                    placeholder={fieldName}
                    value={fieldValue}
                    onChange={e => {
                      e.preventDefault()
                      setFieldValue(e.target.value)
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault(); // Prevent default behavior
                        // Optionally, you can handle the action here if needed
                        handleFormSubmit(e)
                      }
                    }}
                  />
                </StyledFormControl>
              </form>
            </div>
          </StyledContainer>
          <StyledDialogActions>
            <ActionButton width="110px" height="28px" onClick={() => close()}>
              {common.cancel}
            </ActionButton>
            <ActionButton
              width="110px"
              height="28px"
              onClick={handleFormSubmit}
            >
              {common.confirm}
            </ActionButton>
          </StyledDialogActions>
        </ElementWrapper>
      </DialogContent>
    </Dialog>
  )
}
