import GlobalDashboard from 'components/partials/_legacy/MainDashboardPage/components/GlobalDashboard/GlobalDashboard'
import GlobalDashboardLoader from './GlobalDashboard/GlobalDashboardLoader'
import { Orgs } from 'models'

function DashboardContentLoader() {
  // const loading = Orgs.use(({ loading }) => loading)
  const loading = false

  return loading ? <GlobalDashboardLoader /> : <GlobalDashboard />
}

export default DashboardContentLoader
