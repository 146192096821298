import { deleteRequest, getRequest } from './rest'
import { API_ALERT_SOURCES, API_DEFAULT_LIMIT, API_RESPONDER_LIST, API_USERS, API_V5_RESPONDERS } from 'lib/constants'
import { decodeJsonApiObject, getObjectsCSV } from './ApiLib'
import { Pagination, decodePagination, EMPTY_PAGINATION } from './pagination'
import { ResponderV4 } from 'lib/types/responders'

export const EMPTY_RESPONDERS: RespondersWithPagination = {
  responders: [],
  pagination: EMPTY_PAGINATION,
}

export interface RespondersWithPagination {
  responders: ResponderV4[]
  pagination: Pagination
}

export interface ResponderParams extends Record<string, any> {
  org_id?: string
  afterCursor?: string | null
  beforeCursor?: string | null
  limit?: number | string
}

const DEFAULT_ALERT_SOURCE_PARAMS: ResponderParams = {
  disabled: false,
  firmwareStatus: true,
  limit: API_DEFAULT_LIMIT,
}

export function getResponderById(type: string, id: string): Promise<ResponderV4> {
  const endpoint = type === 'alertSource' ? API_ALERT_SOURCES : API_USERS
  return getRequest({
    endpoint: `${endpoint}/${id}`,
  }).then(({ data }) => {
    return { ...decodeJsonApiObject(data.data), lastFetched: new Date().getTime() }
  })
}

export const getResponders = (params: ResponderParams = {}): Promise<ResponderV4[]> => {
  const qstr = new URLSearchParams(DEFAULT_ALERT_SOURCE_PARAMS)
  Object.entries(params).forEach(([key, value]: [key: string, value: any]) => {
    if (value) qstr.set(key, value.toString())
  })
  return getRequest({
    endpoint: `${API_RESPONDER_LIST}?${qstr.toString()}`,
    header: { org_id: params.orgId },
  }).then(({ data }) => data)
}

export const getRespondersPage = (params: ResponderParams = {}): RespondersWithPagination => {
  // @ts-ignore
  return (
    getResponders(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const pagination = decodePagination(meta, data)
        return {
          responders: data,
          pagination,
        }
      })
  )
}

export const getAllRespondersWithPagination = (
  params: ResponderParams,
  responders: RespondersWithPagination = { responders: [], pagination: EMPTY_PAGINATION },
): RespondersWithPagination => {
  // @ts-ignore
  return (
    getResponders(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const items = responders.responders.concat(data)
        const pagination = decodePagination(meta, data)
        if (pagination.after) {
          return getAllRespondersWithPagination(
            { ...params, afterCursor: pagination.after },
            { responders: items, pagination },
          )
        } else {
          return {
            responders: items,
            pagination,
          }
        }
      })
  )
}

export const getRespondersCSV = (orgId: string): Promise<void> => {
  return getObjectsCSV('responders', orgId)
}

export const deleteResponder = (id: string) => {
  return deleteRequest({
    endpoint: `${API_V5_RESPONDERS}/${id}`,
    header: {},
  }).then(({ data }) => data)
}