import { getRequest } from './rest'
import { AlertSession } from 'lib/types'
import { API_ALERT_SESSIONS, API_DEFAULT_LIMIT } from 'lib/constants'
import { Pagination, decodePagination, EMPTY_PAGINATION } from './pagination'
import { decodeJsonApiObject, getObjectsCSV } from './ApiLib'

const DEFAULT_ALERT_SESSION_PARAMS: AlertSessionParams = {
  limit: API_DEFAULT_LIMIT,
}

export const EMPTY_ALERT_SESSIONS: AlertSessionsWithPagination = {
  alertSessions: [],
  pagination: EMPTY_PAGINATION,
}
export interface AlertSessionsWithPagination {
  alertSessions: AlertSession[]
  pagination: Pagination
}

export interface AlertSessionParams extends Record<string, any> {
  org_id?: string
  afterCursor?: string | null
  beforeCursor?: string | null
  limit?: number | string
  open?: boolean | string
  resolved?: boolean | string
}

export const getAlertSessionsRaw = (params: AlertSessionParams = {}): Promise<AlertSession[]> => {
  const qstr = new URLSearchParams(DEFAULT_ALERT_SESSION_PARAMS)
  Object.entries(params).forEach(([key, value]: [key: string, value: any]) => {
    if (value) qstr.set(key, value.toString())
  })
  return getRequest({
    endpoint: `${API_ALERT_SESSIONS}?${qstr.toString()}`,
    header: params?.orgId ? { org_id: params.orgId } : {},
  }).then(({ data }) => data)
}

export const getAlertSessionPage = (params: AlertSessionParams = {}): AlertSessionsWithPagination => {
  // @ts-ignore
  return (
    getAlertSessionsRaw(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const pagination = decodePagination(meta, data)
        return {
          alertSessions: data.map((d: any) => decodeJsonApiObject<AlertSession>(d)),
          pagination,
        }
      })
  )
}

export const getAlertSessionsWithPagination = (
  params: AlertSessionParams,
  sessions: AlertSessionsWithPagination = { alertSessions: [], pagination: EMPTY_PAGINATION },
): AlertSessionsWithPagination => {
  // @ts-ignore
  return (
    getAlertSessionsRaw(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const retrievedSessions = sessions.alertSessions.concat(data)
        const pagination = decodePagination(meta, data)
        if (pagination.after) {
          return getAlertSessionsWithPagination(
            { ...params, afterCursor: pagination.after },
            { alertSessions: retrievedSessions, pagination },
          )
        } else {
          return {
            alertSessions: retrievedSessions,
            pagination,
          }
        }
      })
  )
}

export const getAllAlertSessionsWithPagination = (): AlertSessionsWithPagination =>
  // @ts-ignore
  getAlertSessionsRaw().then(({ data, meta }) => ({
    alertSessions: data.map((d: any) => decodeJsonApiObject<AlertSession>(d)),
    pagination: decodePagination(meta, data),
  }))

export const getAlertsCSV = (orgId: string): Promise<void> => {
  return getObjectsCSV('alert_sessions', orgId)
}
