export enum BeaconsColumnKeys {
  Health = 'health',
  LastSeenAt = 'lastSeenAt', // api uses lastSeenAt instead of last_check_in
  Battery = 'batteryPercent',
  Firmware = 'appVersion',
  Type = 'beaconType',
  // Location = 'location',
  Floor = 'floor',
  Room = 'room',
  Name = 'name',
  // LastCheckIn = 'last_check_in',
}
