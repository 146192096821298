import { shallowEqual } from 'react-redux'
import { Firmwares, PopUpNotifications } from 'models'
import {
  StyledContainer,
  StyledColumnContainer,
  StyledTitleText,
  StyledRegularText,
  StyledSeparator,
  StyledSubText,
  StyledTableContent,
  StyledFirmwareContainer,
  StyledAlternatingTableContent,
} from './Common'
import { getHardwareTypeName } from 'lib/utils'
import { Firmware, SubTypeOfHardware, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { PEERLESS_APP, PEERLESS_APP_V2 } from 'lib/constants'
import VersionsTable from 'components/partials/ExistingVersionsTable/VersionsTable'
import { LoaderOverlay } from 'components/widgets/LoaderOverlay'
import { useEffect } from 'react'
import { RenderCounter } from 'components/widgets/RenderCounter'

export const ManageFirmwareVersions = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN_FIRMWARE)
  const { firmwaresByType, subHardwareTypes, uploading, uploadSuccess } = Firmwares.use(
    ({ firmwaresByType, subHardwareTypes, uploading, uploadSuccess }) => {
      return { firmwaresByType, subHardwareTypes, uploading, uploadSuccess }
    },
    shallowEqual,
  )

  const allowedHardware = subHardwareTypes.filter(sub =>
    [
      'beacon_carfit_app',
      'beacon_react_app',
      'beacon_react2_app',
      'wan_peerless_app',
      'wan_peerless2_app',
      'wan_longlife_app',
      'wan_longlife_modem',
      'sidekick_react_app',
    ].includes(sub.firmwareType.toLowerCase()),
  )

  console.log({ subHardwareTypes })
  console.log({ allowedHardware })

  useEffect(() => {
    if (!uploading) {
      if (uploadSuccess === true) {
        PopUpNotifications.fireInfo({ content: translation.firmware_added })
      } else if (uploadSuccess === false) {
        PopUpNotifications.fireError({ content: translation.firmware_failure })
      }
    }
  }, [uploading, uploadSuccess])

  const renderHardwareRow = (firmwares: Firmware[], sub: SubTypeOfHardware) => {
    const name = sub.name
    const key = `${sub.hardwareType}_${sub.name}`
    return (
      <StyledAlternatingTableContent key={key}>
        <StyledSubText>{getHardwareTypeName(sub.hardwareType)}</StyledSubText>
        <StyledSubText>{name}</StyledSubText>
        <StyledSubText>
          <VersionsTable firmwares={firmwares} subHardware={sub} />
        </StyledSubText>
      </StyledAlternatingTableContent>
    )
  }

  return (
    <>
      <RenderCounter name={'ManageFirmwareVersions'} />
      {uploading && <LoaderOverlay size={100} />}
      <StyledContainer>
        <StyledColumnContainer>
          <StyledTitleText>{translation.manage}</StyledTitleText>
          <StyledRegularText>{translation.add_new}</StyledRegularText>
        </StyledColumnContainer>
      </StyledContainer>
      <StyledFirmwareContainer>
        <StyledContainer>
          <StyledTitleText>{translation.type}</StyledTitleText>
          <StyledTitleText>{translation.sub_type}</StyledTitleText>
          <StyledTitleText>{translation.versions}</StyledTitleText>
        </StyledContainer>
        <StyledSeparator />
        <StyledTableContent>
          {allowedHardware.map((sub: SubTypeOfHardware) => {
            return renderHardwareRow(firmwaresByType[sub.firmwareType], sub)
          })}
        </StyledTableContent>
      </StyledFirmwareContainer>
    </>
  )
}
