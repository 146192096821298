export const BEACON_TYPES = {
  IBEACON: 'IBEACON',
  REACT: 'REACT',
  CARFIT: 'CARFIT',
  ESTIMOTE: 'ESTIMOTE',
}

export const JWT_TOKEN = 'rm_token'
export const CURRENT_LOCATION = 'current_location'
export const SELECTED_ORG_ID_LOCAL_STORAGE_KEY = 'selected_org_id'
export const SELECTED_ALERT_SESSION_ID_LOCAL_STORAGE_KEY = 'selected_alert_session_id'
export const ORG_SORT_ORDER_LOCAL_STORAGE_KEY = 'org_sort_order'
export const ALL_ORGS_SELECTED = 'All Properties'
export const RELOGIN_PARAM = 'relogin'
export const EMAIL_PARAM = 'email'
export const REDIRECT_URL_PARAM = 'redirect_url'
export const RESET_TOKEN_PARAM = 'token'
export const UNAUTHENTICATED_PARAM = 'unauthenticated'

export const DEVICE_TYPES = {
  IOS: 'ios',
  ANDROID: 'android',
  WAN_BUTTON: 'wan_button',
  BUTTON: 'Button',
  CELLULAR: 'cellular',
  LTE: 'lte',
  RESPONDER: 'Responder',
  BT_BUTTON: 'BT-Button',
}

export enum INTEGRATIONS {
  HOTSOS = 'hotsos',
  KNOWCROSS = 'knowcross',
  PINEAPPLE = 'pineapple',
}

export const INTEGRATION_MAP = {
  [INTEGRATIONS.HOTSOS]: 'HotSoS',
  [INTEGRATIONS.KNOWCROSS]: 'Knowcross',
  [INTEGRATIONS.PINEAPPLE]: 'Pineapple',
}

export const INTEGRATIONS_DEFAULT_URLS = {
  [INTEGRATIONS.HOTSOS]: 'https://ifc.int.hot-sos.net/',
  [INTEGRATIONS.KNOWCROSS]: 'https://apigateway.knowcross.com',
  [INTEGRATIONS.PINEAPPLE]: 'http://highspeed.staypineapple.com/API/wt.php',
}

export const UPDATED_BY_DISPATCHER = '*Updated by Dispatcher'

export const ROLE_TYPE = {
  INITIATOR: 'initiator',
  RESPONDER: 'responder',
}

export const BT_STATUS = {
  CONNECTED: 'connected',
  DISCONNECTED: 'disconnected',
}

export const BATTERY_LEVELS = {
  CRITICAL: 20,
  LOW: 40,
  MEDIUM: 90,
}

export const DASHBOARD = {
  SORT_OPTION: 'SortOption',
  SORT_OPTIONS: ['Alphabetical', 'Address', 'Health (ascending)', 'Health (descending)'],
}

export const HARDWARE_TYPE_OPTIONS = [
  {
    name: 'WAN Buttons',
    value: 'wan_button',
  },
  {
    name: 'Beacons',
    value: 'beacon',
  },
  {
    name: 'BT Buttons (sidekicks)',
    value: 'sidekick',
  },
  {
    name: 'Mobile',
    value: 'mobile',
  },
]

export const US_STATES_ABBREV = [
  'AK',
  'AL',
  'AR',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DE',
  'FL',
  'GA',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MI',
  'MN',
  'MO',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY',
]

export const ENVIRONMENT_OPTIONS = [
  {
    name: 'Production',
    value: 'prod',
    code: 'prod',
  },
  {
    name: 'Development',
    value: 'dev',
    code: 'dev',
  },
  {
    name: 'Staging',
    value: 'staging',
    code: 'stage',
  },
  {
    name: 'Test',
    value: 'test',
    code: 'test',
  },
]

export const PEERLESS_APP = 'peerless (app)'
export const PEERLESS_APP_V2 = 'peerless v2 (app)'
export const LONGLIFE_APP = 'longlife (app)'
