import { createReduxModule } from 'hooks-for-redux'
import { ALL_ORGS_SELECTED, LOGIN_MESSAGES, UNAUTHENTICATED_PARAM } from 'lib/constants'
import { trans, TranslationGroup, TranslationKey, User } from 'lib/types'
import { PopUpNotifications } from 'models'
import { getCurrentUser, getUsers, createOrUpdateUser, getUserById } from './api'
import { useChildOrgMap, useParentOrgMap } from './ChildOrgMap'
import * as Grants from './Grants'
import { getRecordsById } from './modelUtils'
import { getOrg } from './Orgs'
import * as RespondersState from './LiveResponders'
import { isSuperAdmin } from 'lib/utils/auth'

export interface UsersState {
  users: User[]
  usersById: { [key: string]: User }
  currentUser: User | null
  loading: boolean
  error: any
}

const initialState: UsersState = {
  users: [],
  usersById: {},
  currentUser: null,
  loading: false,
  error: null,
}

export const [use, { setUser, setUsers, setCurrentUser, setLoading, clearLoading, setError }, store] =
  createReduxModule('users', initialState, {
    setUser: (state: UsersState, user: User) => {
      let newDevices = [...state.users]
      if (!state.usersById[user.id]) {
        newDevices.push(user)
      } else {
        const index = state.users.findIndex(u => u.id === user.id)
        newDevices[index] = user
      }
      return {
        ...state,
        users: newDevices,
        usersById: {
          ...state.usersById,
          [user.id]: user,
        },
      }
    },
    setUsers: (state: UsersState, users: User[]) => ({ ...state, users, usersById: getRecordsById<User>(users) }),
    setCurrentUser: (state: UsersState, currentUser: User) => ({
      ...state,
      currentUser,
    }),
    setLoading: (state: UsersState) => ({ ...state, loading: true }),
    clearLoading: (state: UsersState) => ({ ...state, loading: false }),
    setError: (state: UsersState, error: any) => ({ ...state, error }),
  })

export const setErrorWithNotificationAndRethrow = (error: any) => {
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  clearLoading()
  try {
    const tmp = error.response.data
    const code = tmp.errors[0].code || UNAUTHENTICATED_PARAM
    const content = translation[LOGIN_MESSAGES[code]]
    PopUpNotifications.fireError({ content })
  } catch (e) {
    console.warn(e)
    PopUpNotifications.fireErrorObject(error)
  }
  setError(error)
  throw error
}

const doUpdateAction = (f: any) => {
  setLoading()
  return Promise.resolve()
    .then(f)
    .then(result => {
      reload()
      return result
    }, setErrorWithNotificationAndRethrow)
}

export const useSelectedUsers = () => {
  // selected users is users for currently selected org
  const grantsByUserId = Grants.useSelectedGrantsByUserId()
  const users = use(({ users }) => users)
  return users.filter(user => grantsByUserId[user.id])
}

export const useAssociatedUsers = (orgId: string): User[] => {
  // gets users from a group org and users from children properties of the group org || returns only users of property if orgType is property
  const users = use(({ users }) => users)

  const userOrgMap = Grants.useUserOrgMap()
  const childOrgMap = useChildOrgMap() // key: groups, value: child property org array
  const parentOrgMap = useParentOrgMap() // key: property, value: parent group org array

  if (orgId === ALL_ORGS_SELECTED) return users
  const org = getOrg(orgId)

  // Patch for org not being set when user go's directly to url: BASE_URL/dashboard/users
  if (!org) {
    return []
  }
  
  const orgSet =
    org.orgType === 'group'
      ? org.id in childOrgMap
        ? new Set([...childOrgMap[org.id], org.id])
        : new Set([org.id])
      : org.id in parentOrgMap
      ? new Set([...parentOrgMap[org.id], org.id])
      : new Set([org.id])

  const associatedUsers = users.filter(user => {
    return userOrgMap[user.id]?.some(orgId => orgSet.has(orgId))
  })

  return associatedUsers
}

export const useSelectedUser = (id: string): User | undefined => {
  const usersById = use(({ usersById }) => usersById)
  const users = useSelectedUsers()
  if (isSuperAdmin()) return usersById[id]
  const userList = users.filter(user => user.id === id)
  return userList.length ? userList[0] : undefined
}

/** Update an specific organization, them refresh the current H4R state. Super Admins can see all Organizations. */
export const createOrUpdate = (user: any): Promise<User> =>
  // @ts-ignore
  doUpdateAction(() => createOrUpdateUser(user))

export const reloadUser = (id: string) => {
  const state = store.getState()
  const user = state.usersById[id]
  const now = new Date().getTime()
  if (user && now - user.lastFetched! < 60000) {
    return Promise.resolve().then(() => user)
  }
  if (user) setUser({ ...user, lastFetched: now })
  return Promise.resolve()
    .then(() => getUserById(id))
    .then(setUser)
}

export const reload = () => {
  return Promise.resolve()
    .then(setLoading)
    .then(getCurrentUser)
    .then(setCurrentUser)
    .then(getUsers)
    .then(setUsers)
    .then(clearLoading, setErrorWithNotificationAndRethrow)
}

RespondersState.store.subscribe(({ responderAdded }) => {
  if (responderAdded && responderAdded.responder.info!.resourceType === RespondersState.ResourceType.USER) {
    reloadUser(responderAdded?.responder.id)
  }
})
