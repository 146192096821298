/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react'
import { jsx } from '@emotion/react'
import {
  User,
  Organization,
  TranslationGroup,
  trans,
  TranslationKey,
  ResponderV5,
  AlertTargetForwardType,
} from 'lib/types'
import { ConfirmationDialog, DetailSliderContent } from 'components/partials'
import { DetailRow } from './utils'

import { getNameFromUser, formatDistanceToNow, formatTime, capitalizeFirstLetter } from 'lib/utils'
import { LOCAL_DATE } from 'lib/constants'
import { Tooltip } from '@material-ui/core'
import { detailStyles } from 'styles'
import { ActionButton } from 'components/widgets'
import { deleteAlertTargets } from 'models/api'
import { useGlobal } from 'contexts/GlobalProvider'
import { AlertTargets, Users } from 'models'
import { deleteResponder } from 'models/api/responders'
import { isSuperAdmin } from 'lib/utils/auth'
const pluralize = require('pluralize')

export const ResponderDetailDrawer = ({ responder, close }: { responder: ResponderV5; close: Function }) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  const deviceTranslation: TranslationGroup = trans.merge(TranslationKey.DEVICES_DRAWER)

  const { setRefreshFlag } = useGlobal()
  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const [selectedTargetType, setSelectedTargetType] = useState<'push' | 'sms' | 'email' | null>(null)
  const [rmResponderSelected, setRmResponderSelected] = useState(false) // removeResponderSelected?
  const [modalTitle, setModalTitle] = useState('')
  const [modalText, setModalText] = useState('')

  const handleRemoveTargets = async () => {
    let targets = responder.targets

    /* We decided against deleting the associated targets when
      a responder is being deleted. I'm just commenting it out for now
    */

    if (rmResponderSelected) {
      // remove targets
      // await deleteAlertTargets(targets)

      // remove responder
      await deleteResponder(responder.id)
      return
    }

    /****
     * The push target logic is obsolete. We decided to remove the "Stop Push" button. I want to keep the code in here
     * for reference in case we have a design change. Eventually, this can be ripped out once the design is stable.
     */
    const pushTypes = [
      AlertTargetForwardType.PUSH_ANDROID,
      AlertTargetForwardType.PUSH_IOS,
      AlertTargetForwardType.PUSHER,
    ]

    switch (selectedTargetType) {
      case 'push':
        targets = targets.filter(t => {
          return pushTypes.includes(t.forwardType)
        })
        deleteAlertTargets(targets)
        break
      case 'sms':
        targets = targets.filter(t => {
          return t.forwardType === AlertTargetForwardType.SMS
        })
        deleteAlertTargets(targets)
        break
      case 'email':
        targets = targets.filter(t => {
          return t.forwardType === AlertTargetForwardType.EMAIL
        })
        deleteAlertTargets(targets)
        break
      default:
        break
    }

    return
  }

  const hasTargetType = (type: AlertTargetForwardType) => {
    const targetTypes = responder.targets.map(t => t.forwardType)
    return targetTypes.some(targetType => targetType === type)
  }

  const lastCheckedIn = responder.lastCheckedInAt ? (
    formatDistanceToNow(responder.lastCheckedInAt)
  ) : (
    <Tooltip enterDelay={1000} enterNextDelay={1000} placement={'left'} title={translation.no_checkin}>
      <span>–</span>
    </Tooltip>
  )
  const renderOrgs = (orgDisplayType: string, orgs: Organization[], showName?: boolean) =>
    orgs.length > 0 && (
      <DetailRow
        title={pluralize(orgDisplayType, orgs.length)}
        content={
          <div css={{ display: 'inline' }}>
            {orgs.map((org, i) => (
              <div key={i}>{showName ? org.name : org.id}</div>
            ))}
          </div>
        }
      />
    )

  return (
    <>
      <DetailSliderContent title="Responder Details" close={close}>
        <ul css={detailStyles}>
          <DetailRow title={'id'} content={responder.id} />
          <DetailRow
            title={translation.type}
            content={responder.responderType ? capitalizeFirstLetter(responder.responderType) : ''}
          />
          <DetailRow title={translation.Name} content={responder.name} />
          {responder.email && <DetailRow title={translation.email} content={responder.email} />}
          {responder.userId && <DetailRow title={translation.User_ID} content={responder.userId} />}
          <DetailRow title={translation.Last_Check_In} content={lastCheckedIn} />
          <DetailRow
            title={deviceTranslation.Push_Enabled}
            content={responder.pushEnabled ? responder.pushEnabled.toString() : ''}
          />
          {/* <DetailRow title={translation.Contact} content={user.attributes.email} /> */}
          {/* <DetailRow
            title={translation.User_Ceated}
            content={formatTime(user.attributes.insertedAt, LOCAL_DATE) as string}
          />
          {user.attributes.superAdmin && <DetailRow title={translation.super_admin} content="true" />}
          {user.attributes.provider && (
            <DetailRow title={translation.login_provider} capitalize={true} content={user.attributes.provider} />
          )}
          <DetailRow title={translation.User_ID} content={user.id} /> */}
          <li css={{ display: 'flex', marginTop: '15px' }}>
            {/* <ActionButton
              disabled={
                !(
                  hasTargetType(AlertTargetForwardType.PUSH_ANDROID) ||
                  hasTargetType(AlertTargetForwardType.PUSH_IOS || hasTargetType(AlertTargetForwardType.PUSHER))
                )
              }
              onClick={() => {
                setSelectedTargetType('push')
                setConfirmModalOpen(true)
              }}
            >{`Stop Push Alerts`}</ActionButton> */}
            <ActionButton
              disabled={!hasTargetType(AlertTargetForwardType.SMS)}
              onClick={() => {
                setSelectedTargetType('sms')
                setModalTitle('Removing SMS Alerting')
                setModalText(`Are you sure you want to stop receiving SMS alerts?`)
                setConfirmModalOpen(true)
              }}
            >{`Stop SMS Alerts`}</ActionButton>
            <ActionButton
              disabled={!hasTargetType(AlertTargetForwardType.EMAIL)}
              onClick={() => {
                setSelectedTargetType('email')
                setModalTitle('Removing Email Alerting')
                setModalText(`Are you sure you want to stop receiving email alerts?`)
                setConfirmModalOpen(true)
              }}
            >{`Stop Email Alerts`}</ActionButton>
            {isSuperAdmin() && (
              <ActionButton
                onClick={() => {
                  setRmResponderSelected(true)
                  setModalTitle('Removing Responder')
                  setModalText('Are you sure you want to remove this responder?')
                  setConfirmModalOpen(true)
                }}
              >{`Remove Responder`}</ActionButton>
            )}
          </li>
        </ul>

        {confirmModalOpen && (
          <ConfirmationDialog
            title={modalTitle}
            description={modalText}
            open={confirmModalOpen}
            handleClose={() => setConfirmModalOpen(false)}
            onConfirm={async () => {
              await handleRemoveTargets()
              setConfirmModalOpen(false)
              setRmResponderSelected(false)
              setModalTitle('')
              setModalText('')
              setRefreshFlag(true)
            }}
          />
        )}
      </DetailSliderContent>
    </>
  )
}
