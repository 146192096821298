/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react'
import {
  headerCellStyles,
  headerCellNoSortStyles,
  headerContentStyles,
  tableRowStyles,
  tableCellStyles,
} from './styles'
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from '@material-ui/core'
import PaginatedTableActions from './PaginatedTableActions'
import { Sort } from '@material-ui/icons'
import { PaginationWrapper } from 'components/widgets/table'
import { SortBy, SortOrder, TableColumnConfig, TableState, trans, TranslationGroup } from 'lib/types'
import { textAlign } from 'styles'
import { getAppColors } from 'lib/constants'
import { useHistory, useLocation } from 'react-router-dom'
import { getRecordPageDetails, usePath, useQuery } from 'models/modelUtils'
import { scrollToTop } from 'lib/utils'
import { NavState } from 'models'
// import { Pagination } from 'models/api'
import { Pagination } from 'lib/types/tableTools'

// const AutoBlurTableCell = (props: any) => {
//   const ref = useRef()
//   const Children = props.children
//   return (
//     <TableCell css={tableCellStyles} onBlur={() => ref.current?.onBlur()}>
//       <Children ref={ref} />
//     </TableCell>
//   )
// }

export const PaginatedTable = <
  D extends { id: string }, // Data type
  S extends unknown, // Sort field type (columns)
>({
  sortBy,
  sortedData,
  columnConfigs,
  rowsPerPage: rowsPerPageProp,
  page: pageProp,

  getRowHealthTooltip,
  onHeaderClick,
  onRowClick,
  hiddenColumns = [],
  selectedRowId,
  loading,
  disablePagination,
  noDataMessage,
  onPageChange,
  pagination,
}: {
  sortBy: SortBy<S>
  sortedData: D[]
  columnConfigs: TableColumnConfig<S, D>[]
  rowsPerPage: number
  page: number

  selectedId?: string
  getRowHealthTooltip?: (row: D) => string
  onHeaderClick: (header: S) => void
  onRowClick?: (row: D) => void
  hiddenColumns?: string[]
  selectedRowId?: string
  loading?: boolean
  disablePagination?: boolean
  noDataMessage?: string
  onPageChange?: (page: any, newPage: any) => void
  pagination?: Pagination
}) => {
  const selectedOrgId = NavState.use(({ selectedOrgId }) => selectedOrgId)
  const history = useHistory()
  const location = useLocation()
  const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageProp)
  const [pageData, setPageData] = useState<D[]>([])
  const visibleColumnsConfig = columnConfigs.filter(
    // @ts-ignore
    ({ header }) => 0 > hiddenColumns.indexOf(header),
  )

  const query = useQuery(pageProp)
  const pathname = usePath()
  const [page, setPage] = useState<number>(1)
  const [prevPage, setPrevPage] = useState<number>(page)
  const common: TranslationGroup = trans.common()

  const queryParams = new URLSearchParams(location.search)

  // Set the 'before' and 'after' query parameters based on the pagination object
  queryParams.set('page', `${pagination?.currentPage}`) // Replace with the actual value

  // Convert the modified query parameters to a new search string
  const newSearch = queryParams.toString()

  // Use history.push to update the URL when pagination changes

  useEffect(() => {
    history.push({
      search: newSearch,
    })
  }, [newSearch, history])

  function updatePage(p: string | number) {
    if (prevPage !== p) {
      setPrevPage(page)
      query.set('page', p.toString())
      history.push({
        search: query.toString(),
      })
      scrollToTop()
    }
  }

  useEffect(() => {
    let newPage = parseInt(query.get('page')!) || page
    if (selectedRowId) {
      const paginationDetails = getRecordPageDetails(selectedRowId, sortedData)
      newPage = paginationDetails.page ??= page
    }
    if (newPage !== page) {
      setPrevPage(page)
      setPage(newPage)
    }
    // const startIndex = (newPage - 1) * rowsPerPage
    // const endIndex = newPage * rowsPerPage
    setPageData(sortedData)
  }, [selectedRowId, sortedData])

  useEffect(() => {
    updatePage(page)
    // loadData ? loadData() : null
  }, [page])

  useEffect(() => {
    if (loading) {
      setPageData([])
    }
  }, [loading])

  useEffect(() => {
    if (!loading) {
      history.push({
        pathname,
        search: 'page=1',
      })
    }
    setPage(1)
  }, [selectedOrgId])

  // Effect to correct page back to 1 after a tool event
  // useEffect(() => {
  //   if (pagination?.before == null) setPage(1)
  // }, [pagination])

  const getTableHeaderCell = (config: TableColumnConfig<S, D>, index: number) => {
    const header = (
      <span css={headerContentStyles}>
        {config.label !== undefined ? config.label : config.header}
        {sortBy.field === config.header && (
          <Sort
            style={{
              transform: sortBy.order === SortOrder.Ascending ? 'scaleY(-1)' : 'none',
            }}
          />
        )}
      </span>
    )
    return config.sortable !== false ? (
      <TableCell
        key={`header${index}`}
        onClick={() => {
          onHeaderClick(config.header)
        }}
        css={headerCellStyles}
      >
        {header}
      </TableCell>
    ) : (
      <TableCell key={`header${index}`} css={headerCellNoSortStyles}>
        {header}
      </TableCell>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>{visibleColumnsConfig.map((config, index) => getTableHeaderCell(config, index))}</TableRow>
        </TableHead>
        <>
          {!pageData.length ? (
            <TableBody>
              <TableRow
                css={{
                  ...tableRowStyles,
                  cursor: onRowClick ? 'pointer' : 'default',
                }}
              >
                {visibleColumnsConfig.map((config, j) => (
                  <TableCell key={`TableCell-${j}`} css={tableCellStyles}>
                    {j === 0 ? (loading ? common.loading : noDataMessage || common.no_records_returned) : ''}
                  </TableCell>
                ))}
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {pageData.map((row, i) => {
                const isSelected = selectedRowId === row.id
                return (
                  <Tooltip
                    // @ts-ignore
                    key={`Tooltip-${i}`}
                    title={getRowHealthTooltip ? getRowHealthTooltip(row) : ''}
                  >
                    <TableRow
                      // @ts-ignore
                      className={isSelected ? 'selected' : ''}
                      key={`TableRow-${i}`}
                      css={{
                        ...tableRowStyles,
                        cursor: onRowClick ? 'pointer' : 'default',
                      }}
                      onClick={() => onRowClick && onRowClick(row)}
                    >
                      {visibleColumnsConfig.map((config, j) => (
                        <TableCell key={`TableCell-${j}`} css={tableCellStyles}>
                          {config.renderFn(row)}
                        </TableCell>
                      ))}
                    </TableRow>
                  </Tooltip>
                )
              })}
            </TableBody>
          )}
        </>
        {!disablePagination && (
          <TableFooter>
            <PaginationWrapper>
              <td colSpan={visibleColumnsConfig.length}>
                <TablePagination
                  labelRowsPerPage=""
                  rowsPerPageOptions={[]}
                  component="div"
                  page={pagination?.currentPage || 1}
                  count={pagination?.totalCount || 0}
                  rowsPerPage={rowsPerPage}
                  onPageChange={(_, newPage) => {
                    onPageChange ? onPageChange(page, newPage) : null
                    setPage(newPage)
                  }}
                  onRowsPerPageChange={e => {
                    setRowsPerPage(+e.target.value)
                    setPage(1)
                  }}
                  ActionsComponent={PaginatedTableActions}
                />
              </td>
            </PaginationWrapper>
          </TableFooter>
        )}
      </Table>
    </TableContainer>
  )
}
