export enum AlertsColumnKeys {
  Name = 'initiatorName',
  Health = 'status',
  Responders = 'responders',
  Resolution = 'responseTime',
  Time = 'Time',
  Date = 'firstTime',
  StartLocation = 'start_loc',
  EndLocation = 'end_loc',
}
