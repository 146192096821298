import { FilterBy, SortBy, SearchBy, Pagination, TableTools } from 'lib/types/tableTools'
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { AlertEvent, AlertSession, SortOrder, TableState } from 'lib/types'
import { useGlobal } from './GlobalProvider'
import { getRequestV5 } from 'models/api/rest'
import { ALL_ORGS_SELECTED, AlertsColumnKeys } from 'lib/constants'
import * as utils from '../components/partial-pages/AlertsView/utils'
import { AlertSessions } from 'models'
// Define the Beacon type (modify this based on your actual data structure)

// Define the context type
type AlertsContextType = {
  alertSessions: AlertSession[]
  selectedAlert: AlertSession | null
  alertEvents: AlertEvent[]
  loading: boolean
  tableState: TableState<string, AlertsColumnKeys>
  pagination: Pagination

  // Define your functions and their types here
  setAlertSessions: React.Dispatch<React.SetStateAction<AlertSession[]>>
  setSelectedAlert: React.Dispatch<React.SetStateAction<AlertSession | null>>
  setAlertEvents: React.Dispatch<React.SetStateAction<AlertEvent[]>>
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setTableState: React.Dispatch<React.SetStateAction<TableState<string, AlertsColumnKeys>>>
  setPagination: React.Dispatch<React.SetStateAction<Pagination>>
}

// Initialize
const AlertsContext = createContext<AlertsContextType>({
  alertSessions: [],
  selectedAlert: null,
  alertEvents: [],
  loading: false,
  tableState: {
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  },
  pagination: {
    currentPage: null,
  },
  setAlertSessions: () => {},
  setSelectedAlert: () => {},
  setAlertEvents: () => {},
  setLoading: () => {},
  setTableState: () => {},
  setPagination: () => {},
})

type AlertsProviderProps = {
  children: ReactNode
}

export const useAlerts = () => {
  const context = useContext(AlertsContext)
  if (!context) {
    throw new Error('useAlerts must be used within a AlertsProvider')
  }
  return context
}

export const AlertsProvider: React.FC<AlertsProviderProps> = ({ children }) => {
  const [alertSessions, setAlertSessions] = useState<AlertSession[]>([])
  const [selectedAlert, setSelectedAlert] = useState<AlertSession | null>(null)
  const [alertEvents, setAlertEvents] = useState<AlertEvent[]>([])
  const [loading, setLoading] = useState(false)
  const [tableState, setTableState] = useState<TableState<string, AlertsColumnKeys>>({
    filterBy: utils.getFilterOptions()[0],
    sortBy: utils.getSortOptions()[0].sortBy,
    searchBy: '',
  })
  const [pagination, setPagination] = useState<Pagination>({ currentPage: null })

  const { selectedOrgId, setReportStatus } = useGlobal()

  const alertSessionsLegacy = AlertSessions.use().alertSessions

  // Avoid loading for alert session updates
  useEffect(() => {
    // setLoading(true)
    getRequestV5({
      endpoint: '/reports/status',
      headers: selectedOrgId === null || selectedOrgId === ALL_ORGS_SELECTED ? {} : { org_id: selectedOrgId },
      params: {},
    }).then(response => {
      setReportStatus(response.data.data)
    })
    getRequestV5({
      endpoint: '/alert_sessions',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        sortBy: { field: tableState.sortBy.field, order: tableState.sortBy.order },
        searchBy: { value: tableState.searchBy },
      },
    }).then(response => {
      const data = response.data
      console.log('Loading alert sessions for context')
      setAlertSessions(data.data)
      setPagination(data.meta)
      // setLoading(false)
      // setTableTools({ ...tableTools, pagination: data.meta })
    })
  }, [alertSessionsLegacy])

  useEffect(() => {
    setLoading(true)
    getRequestV5({
      endpoint: '/reports/status',
      headers: selectedOrgId === null || selectedOrgId === ALL_ORGS_SELECTED ? {} : { org_id: selectedOrgId },
      params: {},
    }).then(response => {
      setReportStatus(response.data.data)
    })
    getRequestV5({
      endpoint: '/alert_sessions',
      headers: selectedOrgId && selectedOrgId != ALL_ORGS_SELECTED ? { org_id: selectedOrgId } : {},
      params: {
        sortBy: { field: tableState.sortBy.field, order: tableState.sortBy.order },
        searchBy: { value: tableState.searchBy },
      },
    }).then(response => {
      const data = response.data
      console.log('Loading alert sessions for context')
      setAlertSessions(data.data)
      setPagination(data.meta)
      setLoading(false)
      // setTableTools({ ...tableTools, pagination: data.meta })
    })
  }, [selectedOrgId])

  return (
    <AlertsContext.Provider
      value={{
        alertSessions,
        setAlertSessions,
        selectedAlert,
        setSelectedAlert,
        alertEvents,
        setAlertEvents,
        loading,
        setLoading,
        pagination,
        setPagination,
        tableState,
        setTableState,
      }}
    >
      {children}
    </AlertsContext.Provider>
  )
}
