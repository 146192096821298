export interface FilterBy<FVE> {
  field: string
  value: FVE // filter value enum
}

export interface SearchBy {
  value: string
}

export enum SortOrder {
  Ascending,
  Descending,
}

/** Specify sort order of a field of type S */
export interface SortBy<E> {
  field: E // sort field enum
  order: SortOrder
}

export interface Pagination {
  currentPage?: number | null
  totalCount?: number | null
}

export interface TableTools<FVE, E> {
  searchBy?: SearchBy | null
  sortBy?: SortBy<E> | null
  filterBy?: FilterBy<FVE> | null // This should be filters, but for now we are constraining to a single filter
  pagination: Pagination
}
