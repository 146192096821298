/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { useEffect, useState } from 'react'
import { FormControl, Select, MenuItem, IconButton, TextField } from '@material-ui/core'
import { KeyboardArrowDown as KeyboardArrowDownIcon, Search as SearchIcon } from '@material-ui/icons'
import { flexDirection, position } from 'styles'
import { getAppColors } from 'lib/constants'
import { Orgs } from 'models'
import { trans, TranslationGroup, TranslationKey } from 'lib/types/translation'
import { useGlobal } from 'contexts/GlobalProvider'

const headerWrapper = {
  marginBottom: '20px',
  '@media (max-width: 1300px)': {
    display: 'flex',
    ...flexDirection('row'),
  },
}

const formControl = {
  margin: '0 15px',
  alignSelf: 'center',
  background: getAppColors('--color-gray-3'),
  borderRadius: '15px',
  color: getAppColors('--color-secondary'),
  fontFamily: 'Montserrat',

  "div[id='sort-select'], div[id='show-select']": {
    padding: '16px 30px 15px 15px',
    color: getAppColors('--color-secondary'),
    fontFamily: 'Montserrat',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },

  '&:first-child': {
    marginLeft: '0',
    marginRight: '15px',
  },
  'svg.MuiSvgIcon-root.MuiSelect-icon': {
    paddingRight: '5px',
  },
}

const filterPrefix = {
  top: '17px',
  ...position('relative'),
  marginLeft: '10px',
  height: '2px',
}

const roundedTextField = {
  div: {
    borderRadius: '15px',
    fontFamily: 'Montserrat',
  },
  ':global(.MuiInputBase-root)': {
    borderRadius: '15px',
    '&:global(.Mui-focused)': {
      fieldset: {
        borderColor: getAppColors('--color-textfield-label'),
      },
    },
  },
  input: {
    padding: '15px',
  },
}

const buttonsWrapper = {
  margin: 'auto',
}

export const TableFilterHeader = ({
  sortOptions,
  showOptions,
  onShowChange,
  onSortChange,
  onSearchChange,
  buttons,
  currentSort,
}: {
  sortOptions: Array<string>
  showOptions: Array<string>
  onShowChange?: Function
  onSortChange?: Function
  onSearchChange?: Function
  buttons?: Array<any>
  currentSort?: string
}) => {
  const [defaultSortOption] = sortOptions
  const [show, setShow] = useState(showOptions[0])
  const [sort, setSort] = useState(currentSort || defaultSortOption)

  const [search, setSearch] = useState('')
  const selectedOrg = Orgs.useSelectedOrg()
  const common: TranslationGroup = trans.common()
  const translationTable: TranslationGroup = trans.group(TranslationKey.TABLE_VIEW)

  useEffect(() => {
    setSort(currentSort || defaultSortOption)
  }, [currentSort])

  // useEffect(() => {
  //   const orgName = selectedOrg?.name
  //   if (orgName && onSearchChange) {
  //     onSearchChange(orgName)
  //     setSearch(orgName)
  //   }
  // }, [selectedOrg])

  return (
    <div css={headerWrapper}>
      {onShowChange && (
        <FormControl css={formControl}>
          <span css={filterPrefix}>Show: </span>
          <Select
            id="show-select"
            value={show}
            onChange={e => {
              setShow(e.target.value as string)
              onShowChange(e.target.value as string)
            }}
            IconComponent={KeyboardArrowDownIcon}
            disableUnderline
            autoWidth={false}
            style={{ marginLeft: '45px' }}
          >
            {showOptions.map(option => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {onSortChange && (
        <FormControl css={formControl} style={{ flexDirection: 'row' }}>
          <span css={filterPrefix}>{translationTable.sort}: </span>
          <Select
            id="sort-select"
            value={sort}
            onChange={e => {
              setSort(e.target.value as string)
              onSortChange(e.target.value as string)
            }}
            IconComponent={KeyboardArrowDownIcon}
            disableUnderline
            autoWidth={false}
          >
            {sortOptions.map(option => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {onSearchChange && (
        <FormControl css={formControl}>
          <TextField
            css={roundedTextField}
            id="search-input"
            placeholder={common.search}
            InputLabelProps={{
              shrink: true,
            }}
            value={search}
            onChange={event => {
              setSearch(event.target.value)
              onSearchChange(event.target.value)
            }}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton name="Filter table" aria-label="Search">
                  <SearchIcon />
                </IconButton>
              ),
            }}
          />
        </FormControl>
      )}
      {buttons &&
        buttons.map(b => (
          <span css={buttonsWrapper} key={b.key}>
            {b}
          </span>
        ))}
    </div>
  )
}
