import { getRequest, deleteRequest, postRequest, putRequest } from './rest'
import { Device } from 'lib/types'
import { API_ALERT_SOURCES, API_DEFAULT_LIMIT, API_MICRO_JWT, API_UNREGISTER_ALERT_SOURCE } from 'lib/constants'
import { decodeJsonApiObject, getObjectsCSV } from './ApiLib'
import { Pagination, decodePagination, EMPTY_PAGINATION } from './pagination'

export interface AlertSourcesWithPagination {
  alertSources: Device[]
  pagination: Pagination
}

export interface AlertSourceParams extends Record<string, any> {
  disabled?: boolean
  firmwareStatus?: boolean
  responder?: boolean
  org_id?: string
  afterCursor?: string | null
  beforeCursor?: string | null
  limit?: number | string
}

const DEFAULT_ALERT_SOURCE_PARAMS: AlertSourceParams = {
  disabled: false,
  firmwareStatus: true,
  limit: API_DEFAULT_LIMIT,
}

export const EMPTY_ALERT_SOURCES: AlertSourcesWithPagination = {
  alertSources: [],
  pagination: EMPTY_PAGINATION,
}

export function createMicroJWT(responder: boolean, org_id: string): Promise<Device> {
  return postRequest({
    endpoint: `${API_MICRO_JWT}`,
    data: { responder: responder },
    header: { org_id },
  }).then(({ data }) => decodeJsonApiObject<Device>(data.data))
}

export function getAlertSourceById(id: string): Promise<Device> {
  return getRequest({
    endpoint: `${API_ALERT_SOURCES}/${id}`,
  }).then(({ data }) => {
    return { ...decodeJsonApiObject(data.data), lastFetched: new Date().getTime() }
  })
}

export const getAlertSourcesRaw = (params: AlertSourceParams = {}): Promise<Device[]> => {
  const qstr = new URLSearchParams(DEFAULT_ALERT_SOURCE_PARAMS)
  Object.entries(params).forEach(([key, value]: [key: string, value: any]) => {
    if (value) qstr.set(key, value.toString())
  })
  return getRequest({
    endpoint: `${API_ALERT_SOURCES}?${qstr.toString()}`,
    header: { org_id: params.orgId },
  }).then(({ data }) => data)
}

export const getAlertSourcePage = (params: AlertSourceParams = {}): AlertSourcesWithPagination => {
  // @ts-ignore
  return (
    getAlertSourcesRaw(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const pagination = decodePagination(meta, data)
        return {
          alertSources: data.map((d: any) => decodeJsonApiObject<Device>(d)),
          pagination,
        }
      })
  )
}

export const getAllAlertSourcesWithPagination = (
  params: AlertSourceParams,
  alertSources: AlertSourcesWithPagination = { alertSources: [], pagination: EMPTY_PAGINATION },
): AlertSourcesWithPagination => {
  // @ts-ignore
  return (
    getAlertSourcesRaw(params)
      // @ts-ignore
      .then(({ data, meta }) => {
        const items = alertSources.alertSources.concat(data)
        const pagination = decodePagination(meta, data)
        if (pagination.after) {
          return getAllAlertSourcesWithPagination(
            { ...params, afterCursor: pagination.after },
            { alertSources: items, pagination },
          )
        } else {
          return {
            alertSources: items.map((d: any) => decodeJsonApiObject<Device>(d)),
            pagination,
          }
        }
      })
  )
}

export const deleteAlertSource = (deviceId: string) =>
  deleteRequest({
    endpoint: `${API_ALERT_SOURCES}/${deviceId}`,
  })

export const updateAlertSource = (deviceWithId: Device): Promise<Device> => {
  const { id, ...alertSource } = deviceWithId
  return putRequest({
    endpoint: `${API_ALERT_SOURCES}/${id}`,
    data: { alertSource },
  }).then(({ data }) => decodeJsonApiObject<Device>(data.data))
}

export const unregisterAlertSource = (id: string, orgId: string): Promise<[]> =>
  putRequest({
    endpoint: `${API_UNREGISTER_ALERT_SOURCE}/${id}`,
    header: { org_id: orgId },
  }).then(({ data }) => data.data)

export const disableAlertSource = (id: string): Promise<[]> =>
  putRequest({
    endpoint: `${API_ALERT_SOURCES}/${id}`,
    data: { alertSource: { disabled: true } },
  }).then(({ data }) => data.data)

export const getDevicesCSV = (orgId: string): Promise<void> => {
  return getObjectsCSV('alert_sources', orgId)
}
