/** @jsxImportSource @emotion/react */
import { DevicesColumnKeys, HealthLabelsKeys } from 'lib/constants'
import _ from 'lodash'
import { fuzzySearch } from '@art-suite/art-fuzzy-search'
import { Firmware, AppFirmware } from 'components/partials'
import {
  SortBy,
  SortOption,
  SortOrder,
  TableColumnConfig,
  Device,
  TranslationGroup,
  trans,
  TranslationKey,
  ucfirst,
  DeviceType,
  UpdateFirmwareProps,
} from 'lib/types'
import { createSortOption, generateCSV, isWan } from 'lib/utils'
import { getHealth } from 'lib/utils/common'
import {
  HighlightOff as HighlightOffIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
} from '@material-ui/icons'
import { Orgs } from 'models'
import { getBatteryIcon, batteryWrapper } from 'styles'
import { formatDistanceToNow, parseTime } from 'lib/utils'
import { isValidVersion, normalizeVersion } from 'models/modelUtils'
import semver from 'semver'
import { SignalStrengthIcon } from 'components/partials/DrawerDetails/SignalStrengthIcon'
import { getDevicesCSV } from 'models/api'
import { FirmwareDetail } from 'components/partials/DrawerDetails'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid'
import { FirmwareIcon } from 'styles'

export function getFilterOptions() {
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_TABLE_VIEW)
  const common = trans.common()
  return [ucfirst(translation.all_devices), DeviceType.Android, DeviceType.IOS, DeviceType.WAN]
}

export function getFilterOptionKeys() {
  return [HealthLabelsKeys.UNKNOWN, HealthLabelsKeys.CRITICAL, HealthLabelsKeys.MODERATE, HealthLabelsKeys.HEALTHY]
}

function invertIfDescending(diff: number, sortOrder: SortOrder) {
  return sortOrder === SortOrder.Descending ? diff * -1 : diff
}

export const getSortOptions: () => SortOption<DevicesColumnKeys>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_TABLE_VIEW)
  return _.flatten(
    _.map(
      _.filter(DevicesColumnKeys, c => (c === DevicesColumnKeys.ButtonConnected ? false : true)), // 'buttonConnected' key
      c => [
        createSortOption(c, SortOrder.Descending, `${translation[c]} ↓`),
        createSortOption(c, SortOrder.Ascending, `${translation[c]} ↑`),
      ],
    ),
  )
}

export function searchDevices(devices: Device[], searchBy: string): Device[] {
  // This function is very expensive, only execute if necessary
  // TODO: Look for ways to improve efficiency
  if (searchBy.length < 2) return devices
  const searchArray = _.flatten(
    devices.map(device => {
      const arr = [
        [device.id, device.id],
        [getHealth(device), device.id],
        [device.name, device.id],
        [device.deviceType, device.id],
      ]
      const tmp = new Set()
      Object.entries(device.props?.version || {}).forEach(([key, value]) => {
        if (isValidVersion(value)) tmp.add(value)
      })
      if (tmp.size) arr.push([Array.from(tmp).join(', '), device.id])
      return arr
    }),
  )

  const searchResults = fuzzySearch(searchBy, searchArray)
  const searchResultsIds = searchResults.map(result => result[1] || null).filter(result => result !== null)
  return devices.filter(device => searchResultsIds.includes(device.id))
}

export function getFilterDeviceComparison(device: Device, filterBy: string) {
  // if (!getFilterOptions().includes(filterBy)) throw new Error()
  // if (filterBy === getFilterOptions()[0]) return true
  // return getHealth(device) === filterBy
  const opts = getFilterOptions()
  const keys = getFilterOptionKeys()
  if (!opts.includes(filterBy)) throw new Error()
  const idx = opts.indexOf(filterBy)
  return idx === 0 ? true : getHealth(device) === keys[idx]
}

export function filterDeviceByHealthLabel(devices: Device[], healthLabel: string) {
  return devices.filter(a => getHealth(a) === healthLabel)
}

export const getHealthIcon = (health: string) => {
  switch (health) {
    case HealthLabelsKeys.HEALTHY:
      return <CheckCircleOutlineIcon style={{ color: '#5FD078' }} />

    case HealthLabelsKeys.MODERATE:
      return <RemoveCircleOutlineIcon style={{ color: '#E89F0B' }} />

    case HealthLabelsKeys.OFFLINE:
    case HealthLabelsKeys.CRITICAL:
      return <HighlightOffIcon style={{ color: '#F44336' }} />

    default:
      return '?'
  }
}

export const isConnected = (device: Device) => {
  return device.props.button?.connected ? true : false
}

export const hasSidekickFirmware = (device: Device) => {
  return device.props.button?.version ? true : false
}

export const getBatteryDisplay = (device: Device) => {
  return (
    <>
      {getBatteryIcon(device.batteryPercent)}
      {!_.isNil(device.batteryPercent) && `${device.batteryPercent}%`}
    </>
  )
}

export const getColumnConfigs: () => TableColumnConfig<DevicesColumnKeys, Device>[] = () => {
  const translation: TranslationGroup = trans.merge(TranslationKey.DEVICES_TABLE_VIEW)
  const healthLabelsTranslation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)

  return [
    {
      header: DevicesColumnKeys.Health,
      label: translation[DevicesColumnKeys.Health],
      renderFn: (device: Device) => {
        const health = getHealth(device)
        let icon = getHealthIcon(health)
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                paddingRight: '10px',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              {icon}
            </div>
            <div style={{ lineHeight: '10px' }}>{healthLabelsTranslation[health]}</div>
          </div>
        )
      },
    },
    {
      header: DevicesColumnKeys.Type,
      label: translation[DevicesColumnKeys.Type],
      renderFn: (device: Device) => device.deviceType,
    },
    {
      header: DevicesColumnKeys.Name,
      label: translation[DevicesColumnKeys.Name],
      renderFn: (device: Device) => device.name,
    },
    {
      header: DevicesColumnKeys.Battery,
      label: translation[DevicesColumnKeys.Battery],
      renderFn: (device: Device) => <div css={batteryWrapper}>{getBatteryDisplay(device)}</div>,
    },
    {
      header: DevicesColumnKeys.RSSI,
      label: translation[DevicesColumnKeys.RSSI],
      renderFn: (device: Device) => {
        if (device.deviceType == 'wan_button') {
          return <SignalStrengthIcon rssi={device.props.rssi} />
        } else {
          return <></>
        }
      },
    },
    {
      header: DevicesColumnKeys.ButtonConnected,
      label: translation.button_paired,
      renderFn: (device: Device) => (isConnected(device) ? translation.yes : ''),
    },
    {
      header: DevicesColumnKeys.Firmware,
      label: translation[DevicesColumnKeys.Firmware],
      renderFn: (device: Device) => (
        <>
          {hasSidekickFirmware(device) && (
            <Firmware update={device.updateFirmware} fallback={''} version={device.props?.button?.version} />
          )}

          <AppFirmware
            update={device.updateFirmware}
            appVersion={device.appVersion || ''}
            firmwareType={device.deviceType}
          />
        </>
      ),
    },
    {
      header: DevicesColumnKeys.LastCheckIn,
      label: translation.last_check_in,
      renderFn: (device: Device) => formatDistanceToNow(device.lastSeenAt),
    },
  ]
}

export function getSortDeviceCompareFn(sortBy: SortBy<DevicesColumnKeys>): (a: Device, b: Device) => number {
  const keys = getFilterOptionKeys()
  switch (sortBy.field) {
    case DevicesColumnKeys.Health:
      return (a: Device, b: Device) => {
        let diff = keys.findIndex(h => getHealth(a) === h) - keys.findIndex(h => getHealth(b) === h)
        return normalizeCompareResult(invertIfDescending(diff, sortBy.order))
      }

    case DevicesColumnKeys.Type:
      return (a: Device, b: Device) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.deviceType, b.deviceType), sortBy.order))

    case DevicesColumnKeys.Name:
      return (a: Device, b: Device) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.name, b.name), sortBy.order))

    case DevicesColumnKeys.Battery:
      return (a: Device, b: Device) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.batteryPercent, b.batteryPercent), sortBy.order))

    case DevicesColumnKeys.ButtonConnected:
      return (a: Device, b: Device) =>
        normalizeCompareResult(invertIfDescending(simpleSort(isConnected(a), isConnected(b)), sortBy.order))

    case DevicesColumnKeys.Firmware:
      return (a: Device, b: Device) => {
        const keyA = Object.keys(a.props?.version || {}).find(v => v !== 'android_app') || ''
        const keyB = Object.keys(b.props?.version || {}).find(v => v !== 'android_app') || ''
        const firmwareA = Object.keys(a.props?.button?.version || {})?.[0] || keyA
        const firmwareB = Object.keys(b.props?.button?.version || {})?.[0] || keyB
        const versionA = normalizeVersion(
          a.props?.button?.version?.[firmwareA] || a.props?.version?.[firmwareA],
          '0.0.0',
        )
        const versionB = normalizeVersion(
          b.props?.button?.version?.[firmwareB] || b.props?.version?.[firmwareB],
          '0.0.0',
        )
        return normalizeCompareResult(invertIfDescending(semver.compare(versionA, versionB), sortBy.order))
      }

    case DevicesColumnKeys.LastCheckIn:
      return (a: Device, b: Device) =>
        normalizeCompareResult(invertIfDescending(simpleSort(a.updatedAt, b.updatedAt), sortBy.order))

    case DevicesColumnKeys.RSSI:
      return (a: Device, b: Device) => {
        const aVal =
          a.deviceType === 'wan_button'
            ? a.props?.rssi === null || a.props?.rssi === undefined
              ? Number.MIN_SAFE_INTEGER
              : Number(a.props?.rssi)
            : Number.NEGATIVE_INFINITY
        const bVal =
          b.deviceType === 'wan_button'
            ? b.props?.rssi === null || b.props?.rssi === undefined
              ? Number.MIN_SAFE_INTEGER
              : Number(b.props?.rssi)
            : Number.NEGATIVE_INFINITY
        const rssiA = isNaN(aVal) ? Number.MIN_SAFE_INTEGER : aVal
        const rssiB = isNaN(bVal) ? Number.MIN_SAFE_INTEGER : bVal
        const sorted = simpleSort(rssiA, rssiB)
        return normalizeCompareResult(invertIfDescending(sorted, sortBy.order))
      }

    default:
      console.warn(`Unhandled sort column ${sortBy.field}`)
      return () => 0
  }
}

function simpleSort(a: any, b: any) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}

function normalizeCompareResult(r: number) {
  return simpleSort(r, 0)
}

export function downloadCsv(devices: Device[], responders: boolean) {
  const translation: TranslationGroup = trans.group(TranslationKey.DEVICES_TABLE_VIEW)
  const common: TranslationGroup = trans.common()

  const fields = responders
    ? [common.id, common.health, common.name, common.last_check_in]
    : [
        common.id,
        common.health,
        common.type,
        common.name,
        common.battery,
        common.rssi,
        common.firmware,
        common.last_check_in,
      ]

  const data = responders
    ? devices.map(device =>
        [device.id, getHealth(device), device.name, parseTime(device.updatedAt).toISOString()].filter(a => !!a),
      )
    : devices.map(device =>
        [
          device.id,
          getHealth(device),
          device.deviceType,
          device.name,
          device.batteryPercent ? device.batteryPercent : '-',
          device.props.rssi ? device.props.rssi : '-',
          device.props.version ? JSON.stringify(device.props.version) : '-',
          parseTime(device.updatedAt).toISOString(),
        ].filter(a => !!a),
      )

  const name = `${Orgs.getSelectedOrgName()} ${responders ? common.responders : common.devices}`.trim()

  generateCSV(data, fields, name)
}

export const handleDownloadCSV = (orgId: string) => {
  getDevicesCSV(orgId)
}

export function getIcon(deviceType: string, update?: UpdateFirmwareProps) {
  switch (deviceType) {
    case 'wan_button':
      return <FirmwareIcon iconStyle={{ padding: '0 8px 0' }} update={update} />
    case 'ios':
      return <PhoneIphoneIcon style={{ color: ' rgb(78, 93, 120)', padding: '0 5px 0' }} />
    case 'android':
      return <PhoneAndroidIcon style={{ color: ' rgb(78, 93, 120)', padding: '0 5px 0' }} />
    default:
      return <FirmwareIcon iconStyle={{ padding: '0 8px 0' }} update={update} />
  }
}
