import { ALL_ORGS_SELECTED, API_BEACONS, API_DEFAULT_LIMIT, API_VERSION } from 'lib/constants'
import { getRequest, putRequest, getRequestV5 } from './rest'

export const decodeJsonApiObject = <RecordType>(jsonApiObject: any): RecordType => ({
  ...jsonApiObject.attributes,
  id: jsonApiObject.id,
})

export const getObjectsCSV = (endpoint: string, orgId: string): Promise<void> => {
  return getRequest({
    endpoint: `${API_VERSION}/${endpoint}/download`,
    header: orgId === ALL_ORGS_SELECTED ? {} : { org_id: orgId },
  })
    .then((response: any) => {
      const csvData: string = response.data

      const blob = new Blob([csvData], { type: 'text/csv' })

      const link = document.createElement('a')
      link.download = `${endpoint}.csv`
      link.href = window.URL.createObjectURL(blob)

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
    .catch((error: Error) => {
      console.error('Error fetching CSV data:', error)
      throw error
    })
}
