/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled/macro'
import { useEffect, useState } from 'react'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import c from 'classnames'
import ImgModal from './ImgModal/ImgModal'
import GadgetInfoCard from './GadgetInfoCard/GadgetInfoCard'
import styles from './OrgGlobalCard.module.css'
import { getImageUrl, getErrorImage, updatePlaceholderImage, updateToOriginalImage } from './utils'
import { Address, Organization } from 'lib/types'
import { format, intervalToDuration } from 'date-fns'
import { formatDistanceToNow } from 'lib/utils'
import { IconButton } from '@material-ui/core'
import { trans, TranslationGroup, TranslationKey } from 'lib/types/translation'
import { ConditionalRender } from 'components/widgets'

function OrgGlobalCard({ organization }: { organization: Organization }) {
  const [imgSrc, setImgSrc] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [open, setOpen] = useState(true)

  const translation: TranslationGroup = trans.group(TranslationKey.ORG_GLOBAL_CARD)
  const common: TranslationGroup = trans.common()

  const handleClick = () => {
    setOpen(!open)
  }

  useEffect(() => {
    setImgSrc(organization?.props?.image)
  }, [organization?.props?.image])

  return (
    <>
      <ImgModal handleClose={() => setOpenModal(false)} open={openModal} orgId={organization.id} />
      <div className={styles.orgContainer}>
        <div className={styles.orgSection}>
          <div className={styles.orgDetailsContainer}>
            <div className={styles.orgDetailsChildContainer}>
              <div className={styles.orgImageWrapper}>
                <img
                  className={styles.orgImage}
                  src={getImageUrl(imgSrc)}
                  data-test-id="locationImage"
                  alt={translation.orgavatar}
                  onError={e => getErrorImage(e, setImgSrc)}
                  onClick={() => setOpenModal(true)}
                  onMouseOver={e => updatePlaceholderImage(e, setImgSrc)}
                  onMouseOut={e => updateToOriginalImage(e, organization?.props?.image, setImgSrc)}
                />
              </div>
              <div className={styles.locationInfoWrapper}>
                <div className={styles.locationName} data-testid="locationName">
                  {organization?.name}
                </div>
                <OrgAddress value={organization.props.address} />
                <div className={styles.locationPhoneWrapper} data-testid="locationPhone">
                  {organization.props.phone}
                </div>
              </div>
            </div>
            <ConditionalRender condition={organization.orgType !== 'group'}>
              <div className={styles.orgDetailsChildContainer}>
                <TimeReport title={translation.response_time} value={organization?.responseTime} labels={translation} />
              </div>
              <div className={styles.orgDetailsChildContainer}>
                <TimeReport
                  title={translation.test_interval}
                  value={organization?.testInterval}
                  labels={translation}
                  test
                />
              </div>
            </ConditionalRender>
            <div className={styles.collapseWrapper}>
              <IconButton onClick={handleClick}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
            </div>
          </div>
        </div>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className={c(styles.gadgetContainer)}>
            <GadgetInfoCard title={common.alerts} gadget={organization?.summary?.alerts} organization={organization} />
            <GadgetInfoCard
              title={common.devices}
              gadget={organization?.summary?.alertSources}
              organization={organization}
            />
            <GadgetInfoCard
              title={common.responders}
              gadget={organization?.summary?.responders}
              organization={organization}
            />
            <GadgetInfoCard
              title={common.beacons}
              gadget={organization?.summary?.beacons}
              organization={organization}
            />
          </div>
        </Collapse>
      </div>
    </>
  )
}

const isDefined = (comp: string | undefined) => {
  return !!(comp && comp !== '' && comp !== 'undefined')
}

const OrgAddress = ({ value }: { value?: Address }) => {
  const { street1, street2, city, state, zipcode } = value ?? {}

  return (
    <div className={styles.locationAddressWrapper} data-testid="locationAddress">
      {!!value ? (
        <>
          {isDefined(street1) && <div>{street1}</div>}
          {isDefined(street2) && <div>{street2}</div>}
          <div>
            {isDefined(city) && `${city}, `}
            {isDefined(state) && `${state} `}
            {isDefined(zipcode) && zipcode}
          </div>
          {/* <div>{`${city}, ${state} ${zipcode}`}</div> */}
        </>
      ) : (
        ''
      )}
    </div>
  )
}

const TimeReport = ({
  title,
  value,
  test = false,
  labels,
}: {
  title: string
  value: number | undefined
  test?: boolean
  labels: TranslationGroup
}) => (
  <div className={styles.timeReport}>
    <div className={styles.timeReportLabel}>{title}</div>
    <div className={styles.timeReportValueWrapper}>
      <div
        className={styles.timeReportIndicatorDot}
        style={{
          backgroundColor: getDotColor(value, test),
        }}
      />
      <div className={styles.timeReportValue}>{value ? formatTime(value, labels) : 'No data'}</div>
      {/* <div className={styles.timeReportValue}>{getHumanTime(value || 0, test, labels)}</div> */}
    </div>
  </div>
)

function getDotColor(time: number | undefined, test: boolean) {
  const green = '#5FD078'
  const yellow = '#E89F0B'
  const red = '#F44336'

  if (!time) {
    return yellow
  }

  if (test) {
    return time <= 1000 * 3600 * 24 * 7 // 7 days in ms
      ? green
      : time <= 1000 * 3600 * 24 * 7 // 14 days in ms
      ? yellow
      : red
  }

  return time <= 1000 * 60 * 5 // 5 minutes in ms
    ? green
    : time <= 1000 * 60 * 10 // 10 minute in ms
    ? yellow
    : red
}
function formatTime(seconds: any, labels: any) {
  const MINUTE = 60
  const HOUR = MINUTE * 60
  const DAY = HOUR * 24

  if (seconds < 5 * MINUTE) {
    return seconds + ' second' + (seconds > 1 ? 's' : '')
  } else if (seconds < 5 * HOUR) {
    const minutes = Math.floor(seconds / MINUTE)
    return minutes + ` ${labels.mins}` //+ (minutes > 1 ? 's' : '')
  } else if (seconds < DAY) {
    const hours = Math.floor(seconds / HOUR)
    return hours + ` ${labels.hrs}` //+ (hours > 1 ? 's' : '')
  } else {
    const days = Math.floor(seconds / DAY)
    return days + ` ${labels.days.slice(0, -1)}` + (days > 1 ? 's' : '')
  }
}

/* Old function - removed 3/28/23 - consider removing. */
//
// function getHumanTime(time: number, test: boolean, labels: TranslationGroup) {
//   if (!time) return labels.nodata
//   if (test) return formatDistanceToNow(time)
//   if (!isNaN(time) && time < 60000) return `< ${labels.min}`
//   const duration = intervalToDuration({ start: 0, end: time ?? 0 })
//   return !!duration.days && duration.days >= 1
//     ? `${duration.days} ${labels.days}`
//     : `${format(time, 'm:ss')} ${labels.mins}`
// }

export default OrgGlobalCard
