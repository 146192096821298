/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled'
import BulkBeaconInstall from './BulkBeaconInstall'
import FirmwareSettings from './FirmwareSettings'
import { StyledHeader, StyledMetaData } from 'components/widgets'
import { ExternalIntegrationsSettings } from './ExternalIntegrationsSettings'
import { isSuperAdmin } from 'lib/utils/auth'
import { Orgs } from 'models'
import { BORDER1_COLOR } from 'lib/constants'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import GeofenceSettings from './GeofenceSettings'
import { useGlobal } from 'contexts/GlobalProvider'

const StyledSettingsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  fontFamily: 'Montserrat',
  lineHeight: '1.5em',
  button: {
    marginTop: '25px',
  },
})

const StyledSeparator = styled.div({
  border: `1px solid ${BORDER1_COLOR}`,
  marginTop: '38px',
  marginBottom: '40px',
})

export const GeneralSettingsContent = () => {
  const translation: TranslationGroup = trans.group(TranslationKey.HEADER)

  // const selectedOrg = Orgs.useSelectedOrg()
  const { selectedOrg } = useGlobal()
  return (
    <StyledSettingsWrapper>
      {selectedOrg && (
        <div css={{ marginBottom: '16px' }}>
          <StyledHeader>{selectedOrg.name}</StyledHeader>
          <StyledMetaData>id: {selectedOrg.id}</StyledMetaData>
        </div>
      )}
      <StyledHeader>{translation.settings}</StyledHeader>
      <FirmwareSettings />
      <StyledSeparator />
      <GeofenceSettings org={selectedOrg} />
      <StyledSeparator />
      {isSuperAdmin() && (
        <>
          <ExternalIntegrationsSettings />
          <StyledSeparator />
        </>
      )}
      <BulkBeaconInstall />
    </StyledSettingsWrapper>
  )
}
