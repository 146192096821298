import styled from '@emotion/styled/macro'
import { Button } from '@material-ui/core'
import { Grants } from 'models'
import useModal from 'lib/hooks/useModal'
import { Grant, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { USER_ASSIGNABLE_GRANTS, getHumanGrantName, GRANTS } from 'lib/constants'
import { isDispatcher } from 'models/Grants'
import { isAdmin } from 'lib/utils/auth'
import { Dialog, DialogContent } from '@material-ui/core'
import { EditUserModal } from './EditUserDialog'
import {
  StyledDialogTitle,
} from 'components/widgets'
import { useState } from 'react'

const StyledWrapper = styled.div({
  display: 'flex',
  width: '200px',
})

const StyledRoleNameContainer = styled.div({
  overflow: 'normal',
  margin: 'auto 0',
  flex: '1 0 auto',
  width: '20px',
})

const StyledButtonContainer = styled.div({
  flex: '0 0 auto',
})

type Props = {
  userId: string
  orgId: string
}

export const EditUserRoleButton = ({ userId, orgId }: Props) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.USER_TABLE_VIEW)
  const [modalOpen, setModalOpen] = useState(false)
  // const { Modal: AssignRolesModal, openModal: openAssignModal, closeModal } = useModal(translation.edit)
  const usersGrants = Grants.useUserOrgGrants(userId, orgId)
  const hasAdminGrants = isAdmin(orgId)

  const UserRoleList = ({ usersGrants }: { usersGrants: Grant[] }) => {
    let grants = usersGrants
      .filter(grant => USER_ASSIGNABLE_GRANTS.includes(grant.attributes.name))
      .map(grant => grant.attributes.name)

    if (isDispatcher(grants)) {
      grants = grants.filter(grant => ![GRANTS.PORTAL_USER, GRANTS.RESPONDER].includes(grant))
      grants.push(GRANTS.DISPATCHER)
    }

    return <StyledRoleNameContainer>{grants.map(grant => getHumanGrantName(grant)).join(', ')}</StyledRoleNameContainer>
  }

  return (
    <>
      <Dialog style={{  animationFillMode: 'forwards'}}  open={modalOpen} onClose={() => setModalOpen(false)} maxWidth="sm" fullWidth>
        <StyledDialogTitle>{translation.edit}</StyledDialogTitle>
        <DialogContent>
            <EditUserModal orgId={orgId} userId={userId} close={() => setModalOpen(false)} />
        </DialogContent>
      </Dialog>    
      <StyledWrapper>
      <UserRoleList usersGrants={usersGrants} />
      {hasAdminGrants && (
        <StyledButtonContainer>
          <Button onClick={() => setModalOpen(true)}>{translation.edit}</Button>
        </StyledButtonContainer>
      )}
    </StyledWrapper>
  </>
  )
}
