import { isAdmin, isSuperAdmin } from 'lib/utils/auth'
import { ALL_ORGS_SELECTED } from 'lib/constants'
import { Orgs } from 'models'
import { PageWrapper, ViewWrapper } from 'components/widgets'
import { trans, TranslationGroup } from 'lib/types'
import { useGlobal } from 'contexts/GlobalProvider'

function AdminRequired(props: any) {
  const translation: TranslationGroup = trans.admin()
  const { selectedOrg } = useGlobal()
  const location = selectedOrg
  return (
    <PageWrapper>
      <ViewWrapper>
        {isSuperAdmin() || (location && location.name !== ALL_ORGS_SELECTED && isAdmin(location.id)) ? (
          props.children
        ) : (
          <>{translation.admin_required}</>
        )}
      </ViewWrapper>
    </PageWrapper>
  )
}

export default AdminRequired
