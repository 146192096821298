import { formatDistance } from 'date-fns'
import { HealthTypesKeys } from 'lib/constants'
import { AlertSession, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { getDateLocale, parseTime } from 'lib/utils'

interface AlertTimes {
  startTime: Date
  endTime?: Date
  timeSpan: string
}

export function getAlertTimes(alertSession: AlertSession): AlertTimes {
  const { firstTime, firstArrivedTime, resolveTime } = alertSession
  const startTime = parseTime(firstTime, false)|| new Date('1970-01-01')

  const endTime = parseTime(firstArrivedTime || resolveTime, false)
  const timeSpanLan: string = formatDistance(startTime, endTime || new Date(), { locale: getDateLocale() })

  return {
    startTime,
    endTime,
    timeSpan: timeSpanLan,
  }
}

// TODO: remove getAlertHealth from '../_legacy/AlertsPage/AlertsPage'; only use this one
// Didn't change it right now to avoid merge conflicts.
export const getAlertHealth = (alertSession: AlertSession) => {
  const translation: TranslationGroup = trans.group(TranslationKey.HEALTH_LABELS)

  const { status, responders } = alertSession
  if (status === HealthTypesKeys.OPEN && (!responders || responders.length < 1)) return translation.no_response_critical
  if (status === HealthTypesKeys.OPEN && responders?.length > 0) return translation.no_response
  if (status === HealthTypesKeys.RESOLVED) return translation.resolved
  return ''
}

export const getAlertHealthTooltip = (health: string) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_TOOLTIPS)
  return translation[`alerts_${health}`] || ''
}
