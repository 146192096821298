import { useState } from 'react'
import styled from '@emotion/styled'
import { Tooltip } from '@material-ui/core'
import { isEmpty } from 'lodash'
import { DISABLED_COLOR, GRAY3_COLOR } from 'lib/constants'
import { Firmware, SubTypeOfHardware, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { Firmwares } from 'models'
import { getFirmwareUpdateOption } from 'lib/utils/hardwareAndFirmware'
import { ConfirmationDialog } from '../ConfirmationDialog'
import useModal from 'lib/hooks/useModal'
import UploadFirmwareModalContent from 'components/partials/UploadFirmwareModalContent/UploadFirmwareModalContent'
import { SmallMarginStyledSeparator } from 'components/partial-pages/GeneralSettingsPage/Common'
import UploadFirmwareModal from 'components/partial-pages/AdminView/Partials/UploadFirmwareModal'

const StyledTableContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  height: '140px',
  overflow: 'auto',
  background: GRAY3_COLOR,
  borderRadius: '10px',
})

const StyledFirmwareContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '5px 8px',
})

const StyledRegularText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '2.9rem',
  color: '#242424',
})

const StyledButton = styled.button(props => ({
  background: 'none !important',
  border: 'none',
  padding: '0 !important',
  fontFamily: 'Montserrat',
  color: props.disabled ? DISABLED_COLOR : '#069',
  cursor: 'pointer',
  marginTop: '0px !important',
  lineHeight: '2.9rem',
}))

type Props = {
  firmwares: Firmware[]
  subHardware: SubTypeOfHardware
}

function VersionsTable({ firmwares, subHardware }: Props) {
  const [firmwareId, setFirmwareId] = useState('')
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN_FIRMWARE)

  const renderFirmwareList = (firmwares: Firmware[]) => {
    const upload = (
      <StyledFirmwareContainer key={`${subHardware}_upload`}>
        <StyledRegularText></StyledRegularText>
        <Tooltip title={`${translation.upload_new} ${subHardware.name} ${translation.firmware}`}>
          <StyledButton onClick={() => setModalOpen(true)}>{translation.upload_new}</StyledButton>
        </Tooltip>
      </StyledFirmwareContainer>
    )

    const firmwareList = firmwares.map((firmware, i) => {
      const option = getFirmwareUpdateOption(firmware.version, firmware.environment)
      return (
        <StyledFirmwareContainer key={`${subHardware}_${i}`}>
          <StyledRegularText>{option}</StyledRegularText>
          <Tooltip title={`${translation.delete_version} ${option}`}>
            <div>
              <StyledButton
                disabled={isEmpty(firmware.id)}
                onClick={() => {
                  setFirmwareId(firmware.id)
                  setOpenConfirmationModal(true)
                }}
              >
                {translation.delete_version}
              </StyledButton>
            </div>
          </Tooltip>
        </StyledFirmwareContainer>
      )
    })
    return (
      <>
        {upload}
        <SmallMarginStyledSeparator />
        {firmwareList}
      </>
    )
  }

  return (
    <StyledTableContainer>
      <UploadFirmwareModal
        open={modalOpen}
        setModalOpen={setModalOpen}
        firmwares={firmwares}
        firmwareType={subHardware.firmwareType}
        hardwareType={subHardware.hardwareType}
        subType={subHardware.name}
      />
      <ConfirmationDialog
        title={translation.delete_firmware}
        description={translation.confirm_delete_firmware}
        open={openConfirmationModal}
        onConfirm={() => {
          setOpenConfirmationModal(false)
          Firmwares.deleteFirmwareWithNotifications(firmwareId)
        }}
        handleClose={() => setOpenConfirmationModal(false)}
      />
      {renderFirmwareList(firmwares || [])}
    </StyledTableContainer>
  )
}

export default VersionsTable
