/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react'
import _ from 'lodash'
import { Tooltip } from '@material-ui/core'
import { Device, FirmwareVersionProps, trans, TranslationGroup, UpdateFirmwareProps } from 'lib/types'
import { FirmwareIcon } from 'styles'
import { getIcon } from 'components/partial-pages/ButtonsDevicesPage'

export function AppFirmware({
  appVersion,
  update,
  firmwareType,
}: {
  appVersion: string
  update?: UpdateFirmwareProps
  firmwareType: string
}) {
  const translation: TranslationGroup = trans.common()

  return (
    <>
      {appVersion && (
        <Tooltip
          enterDelay={200}
          enterNextDelay={200}
          placement={`left`}
          title={`${translation.type}: ${firmwareType}`}
          key={firmwareType}
        >
          <div css={{ alignItems: 'center', display: 'flex' }}>
            {/* <FirmwareIcon iconStyle={{ padding: '0 8px 0' }} update={update} />
            <PhoneIphoneIcon style={{ color: ' rgb(78, 93, 120)' }} /> */}
            {getIcon(firmwareType, update)}
            <p css={{ marginBottom: 0, marginTop: 0 }}>
              <span>{appVersion}</span>
            </p>
          </div>
        </Tooltip>
      )}
    </>
  )
}
