import { AlertSession, trans, TranslationGroup, TranslationKey } from 'lib/types'

export const displayDesktopNotification = (alertSession: AlertSession, locationKnown: boolean) => {
  const IMAGE_PATH = 'https://ucarecdn.com/679c18a9-ad6f-4f1b-bc98-bd3705e8410c/'
  if (!window?.Notification) {
    console.log('This browser does not support notifications.')
  } else {
    // your code using Notification API here
    const translation: TranslationGroup = trans.group(TranslationKey.NOTIFICATIONS)
    if (locationKnown) {
      const note = new Notification('React Mobile', {
        body: `Alert located in Room ${alertSession.firstLocation?.room} Floor ${alertSession.firstLocation?.floor} of ${alertSession.firstLocation?.property}`,
        icon: IMAGE_PATH,
      })

      note.onclick = e => {
        window.focus()
        window.location = (window.location.origin + `/dashboard/alerts/${alertSession.id}`) as string & Location
      }
    } else {
      // initial notification
      const note = new Notification('React Mobile', {
        body: 'User requested help, waiting for location',
        icon: IMAGE_PATH,
      })

      note.onclick = e => {
        window.focus()
        window.location = (window.location.origin + `/dashboard/alerts/${alertSession.id}`) as string & Location
      }
    }
  }
}
