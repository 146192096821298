export enum UsersColumnKeys {
  Name = 'Name',
  Email = 'Email',
  Provider = 'Provider',
  LastCheckIn = 'Last_check_in',
  Membership = 'Membership',
  Roles = 'Roles',
  Remove = 'Remove',
  View = 'View',
}
