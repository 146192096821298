import { Sort } from '@material-ui/icons'
import axios from 'axios'
import { BASE_URL, BASE_ALERTS_URL, JWT_TOKEN, RELOGIN_PARAM, API_V5 } from 'lib/constants'
import { Error, RestParamsV5, SortOrder } from 'lib/types'
import { pusher } from 'models/api'

declare module 'axios' {
  export interface AxiosResponse<T = any> extends Promise<T> {}
}

export interface ApiResponseV5 {
  data: any // Define the actual type of 'data' here
  meta: any // Define the actual type of 'meta' here
}

const API = axios.create({
  baseURL: BASE_URL,
})

export const AxiosInstanceForMocking = API

API.interceptors.request.use((config: any) => {
  const token = localStorage.getItem(JWT_TOKEN)
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`
  }
  return config
})

/***  function for retrying failed api calls - revisit ***/
// const sleepRequest = (milliseconds: any, originalRequest: any) => {
//   return new Promise((resolve, reject) => {
//     setTimeout(() => resolve(API(originalRequest)), milliseconds)
//   })
// }
// useful link: https://gist.github.com/edmondburnett/38ed3451de659dc43fa3f24befc0073b

API.interceptors.response.use(
  res => res,
  err => {
    if (err?.response?.status === 401) {
      logout(err.response.data?.errors)
      console.log('Unauthorized Call')
    } else {
      throw err
    }
    console.log('logging out')
  },
)

function logout(errors: Array<Error>) {
  const code = errors[0]?.code || 40100
  localStorage.clear()
  pusher?.disconnect()
  window.location.href = `/?${RELOGIN_PARAM}=${code}`
}

export const putRequest = ({ endpoint, data = {}, header }: { endpoint: string; data?: any; header?: any }) =>
  API.put(`${BASE_URL}${endpoint}`, data, { headers: header })

export const postRequest = ({ endpoint, data = {}, header }: { endpoint: string; data: any; header?: any }) =>
  API.post(`${BASE_URL}${endpoint}`, data, { headers: header })

export const postAlertRequest = ({ endpoint, data = {}, header }: { endpoint: string; data: any; header?: any }) =>
  API.post(`${BASE_ALERTS_URL}${endpoint}`, data, { headers: header })

export const getRequest = ({ endpoint, header = {} }: { endpoint: string; header?: any }) =>
  API.get(`${BASE_URL}${endpoint}`, {
    headers: header,
  })

export const getRequestV5 = <FVE, SE>({ endpoint, headers = {}, params = {} }: RestParamsV5<FVE, SE>) => {
  // let filters ? { params?.filters } : {}; // Create params object with filters if defined

  let finalEndpoint = endpoint + '?'
  if (params.filterBy) {
    finalEndpoint += `filter[${params.filterBy.field}]=${params.filterBy.value}&`
  }
  if (params.searchBy?.value) {
    finalEndpoint += `search=${params.searchBy.value}&`
  }
  if (params.sortBy) {
    switch (params.sortBy.order) {
      case SortOrder.Ascending:
        finalEndpoint += `sort=${params.sortBy.field}`
        break
      case SortOrder.Descending:
        finalEndpoint += `sort=-${params.sortBy.field}`
        break
      default:
        console.log('No sort order applied')
        break
    }
  }
  // Add pagination params
  if (params?.pagination) {
    finalEndpoint += `&page=${params.pagination.currentPage}&`
  }

  if (params?.custom) {
    for (const [key, val] of Object.entries(params.custom)) {
      finalEndpoint += `&${key}=${val}`
    }
  }
  console.log('endpoint: ', `${BASE_URL}${API_V5}${finalEndpoint}`)
  console.log('headers: ', headers)

  return API.get<any>(`${BASE_URL}${API_V5}${finalEndpoint}`, {
    headers: headers,
  })
}

export const deleteRequest = ({ endpoint, header }: { endpoint: string; header?: any }) =>
  API.delete(`${BASE_URL}${endpoint}`, {
    headers: header,
  })
