/** @jsxImportSource @emotion/react */
import { jsx } from '@emotion/react/macro'
import { useEffect, useRef, useState } from 'react'
import { uniqueId } from 'lodash'
import { Button, Menu, MenuItem, Tooltip, styled } from '@material-ui/core'
import { ErrorOutline } from '@material-ui/icons'
// import { Notifications } from 'models'

import { AlertSession } from 'lib/types'
import { Orgs } from 'models'
import { AppNotification, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { onAlertNotificationClick } from './utils'
import { useHistory } from 'react-router'
import { getAppColors } from 'lib/constants'
import { AlertDetailDrawer } from 'components/partials/DrawerDetails'
import { AlertSessions, DetailsDrawer, PopUpNotifications } from 'models'
import { AlertsColumnKeys, ALERTS_PATH } from 'lib/constants'
import { useQuery } from 'models/modelUtils'
import { Howl, Howler } from 'howler'
import alertSoundFile from 'static/audio/alert-sound.mp3'
import { playAlertSound } from 'models/Sound'
import { useGlobal } from 'contexts/GlobalProvider'

const notificationIconContent = {
  color: getAppColors('--color-secondary'),
  marginRight: '20px',
}

const activeAlertStyle = {
  backgroundColor: getAppColors('--color-error'),
  color: getAppColors('--color-white'),
  animation: 'blinker 2s linear infinite',
  '@keyframes blinker': {
    '50%': {
      opacity: '0.2',
    },
  },
  '&:hover': {
    backgroundColor: getAppColors('--color-primary'),
  },
}

const menuTitle = {
  padding: '0px 10px 5px 18px',
  width: '300px',
  fontFamily: 'Montserrat',
  fontWeight: 800,
}

const notificationIcon = {
  color: 'inherit',
  marginRight: '5px',
}

const notificationMenuItem = {
  cursor: 'pointer',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  display: 'flex',
  color: 'white',
  width: '100%',
  background: getAppColors('--color-error'),
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px',
  '&:hover': {
    filter: 'grayscale(50%)',
  },
}

const notificationInfo = {
  flex: '1 1 auto',
  padding: '10px 0px 10px 20px',
}

const notificationTitle = {
  fontSize: '1.8rem',
}

const notificationOrg = {
  fontSize: '1.2rem',
}

const NotificationCenter = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  // const notifications = Notifications.use(({ notifications }) => notifications)
  // const count = notifications.length
  const query = useQuery()
  const history = useHistory()
  const translation: TranslationGroup = trans.merge(TranslationKey.HEADER)
  const [alarmLoopOn, setAlarmLoopOn] = useState(false)
  const { setRefreshFlag } = useGlobal()

  const alertSessions = AlertSessions.use().alertSessions

  const filtered = alertSessions?.filter(as => as.status === 'open') || []

  const count = filtered.length

  const repeatRef = useRef<any>(null)
  useEffect(() => {
    const areOpenAlarms = filtered.length > 0
    if (areOpenAlarms && !alarmLoopOn) {
      console.log('Audio - areOpenAlarms and alarm loop off')
      if (Howler.ctx.state !== 'suspended') setAlarmLoopOn(true)
      console.log('Audio - creating alarm loop')
      repeatRef.current = setInterval(() => {
        console.log('Audio repeat playing')
        if (Howler.ctx.state !== 'suspended') {
          playAlertSound()
        }
      }, 10000)

      document.addEventListener(
        'mousemove',
        () => {
          clearInterval(repeatRef.current)
          setAlarmLoopOn(false)
          console.log('Audio loop listener removed by mouse move')
        },
        { once: true },
      )
    } else if (filtered.length === 0 && alarmLoopOn) {
      clearInterval(repeatRef.current)
      console.log('Audio loop killed by no open alerts')
      setAlarmLoopOn(false)
    }
  }, [alertSessions])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const colorModStyle = count > 0 ? activeAlertStyle : ''

  // this was the only way to simply achieve the intended behavior
  const StyledMenuItem = styled(MenuItem)(() => ({
    cursor: 'default',
    '&:hover': {
      background: 'none',
    },
  }))
  function handleDrawerClose() {
    history.push({
      pathname: `${ALERTS_PATH}`,
      search: query.toString(),
    })
    DetailsDrawer.close()
  }

  const AlertMenuItem = ({ alertSession }: { alertSession: AlertSession }) => {
    return (
      <StyledMenuItem>
        <div
          css={notificationMenuItem}
          onClick={() => {
            handleClose()
            onAlertNotificationClick(alertSession, history)
            localStorage.setItem('selected_org_id', alertSession.orgId)
            setRefreshFlag(true)
            DetailsDrawer.show({
              drawerComponent: AlertDetailDrawer,
              drawerProps: {
                alertSession: alertSession,
                close: handleDrawerClose,
              },
            })
          }}
        >
          <span css={notificationInfo}>
            <div css={notificationTitle}>{translation.alert}</div>
            <div css={notificationOrg}>{Orgs.getOrg(alertSession.orgId)?.name || '[name not found]'}</div>
          </span>
        </div>
      </StyledMenuItem>
    )
  }

  return (
    <>
      <Tooltip title={translation.notifications}>
        <div css={notificationIconContent}>
          <Button
            name="Open Notifications"
            aria-label="Notification Icon"
            onClick={handleClick}
            css={{
              ...notificationIconContent,
              ...colorModStyle,
            }}
          >
            <ErrorOutline css={notificationIcon} />
            {count}
          </Button>
        </div>
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <div css={menuTitle}>{`${translation.notifications.toUpperCase()} (${count})`}</div>
        {filtered?.map(as => (
          <AlertMenuItem key={uniqueId()} alertSession={as} />
        ))}
      </Menu>
    </>
  )
}

export default NotificationCenter
