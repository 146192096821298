import { Charts as AlertsCharts } from 'components/partial-pages/AlertsView'
import { Charts as DevicesCharts } from 'components/partial-pages/ButtonsDevicesPage'
import { Charts as BeaconsCharts } from 'components/partial-pages/BeaconsPage'
import { Charts as RespondersCharts } from 'components/partial-pages/RespondersPage'
import { AlertSessions, Beacons, Devices, Responders } from 'models'
import { trans, TranslationGroup, TranslationKey, ucfirst } from 'lib/types'
import { useGlobal } from 'contexts/GlobalProvider'

export enum HealthKey {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
  TOTAL = 'total',
}
export interface LegendLabel {
  [HealthKey.GREEN]: string
  [HealthKey.YELLOW]: string
  [HealthKey.RED]: string
  [HealthKey.TOTAL]: string
}

export function ChartsSection({ currentPath }: { currentPath: string }) {
  // const alerts = AlertSessions.useSelectedOrgsAlertSessions()
  // const devices = Devices.useSelectedOrgsDevices(false)
  // const responders = Responders.useSelectedOrgsResponders()
  // const beacons = Beacons.useSelectedOrgsBeacons()

  const { reportStatus } = useGlobal()
  const pathComponents = currentPath.split('/')
  if (pathComponents.length < 2) return null
  const currentSection = pathComponents[2]
  const translation: TranslationGroup = trans.merge(TranslationKey.HEALTH_LABELS)
  const offline = ucfirst(translation.offline)
  const moderate = ucfirst(translation.moderate)
  const healthy = ucfirst(translation.healthy)
  const critical = ucfirst(translation.critical)
  const total = ucfirst(translation.total)
  const active_no_response = ucfirst(translation.active_no_response)
  const active_in_progress = ucfirst(translation.active_in_progress)
  const resolved = ucfirst(translation.resolved)

  switch (currentSection) {
    case 'alerts':
      return (
        <AlertsCharts
          alerts={reportStatus ? reportStatus.alerts : []}
          legendLabel={{
            [HealthKey.RED]: active_no_response,
            [HealthKey.YELLOW]: active_in_progress,
            [HealthKey.GREEN]: resolved,
            [HealthKey.TOTAL]: total,
          }}
        />
      )
    case 'devices':
      return (
        <DevicesCharts
          devices={reportStatus ? reportStatus.devices : []}
          legendLabel={{
            [HealthKey.RED]: critical,
            [HealthKey.YELLOW]: moderate,
            [HealthKey.GREEN]: healthy,
            [HealthKey.TOTAL]: total,
          }}
        />
      )
    case 'responders':
      return (
        <RespondersCharts
          responders={reportStatus ? reportStatus.responders : []}
          legendLabel={{
            [HealthKey.RED]: offline,
            [HealthKey.YELLOW]: moderate,
            [HealthKey.GREEN]: healthy,
            [HealthKey.TOTAL]: total,
          }}
        />
      )
    case 'beacons':
      return (
        <BeaconsCharts
          beacons={reportStatus ? reportStatus.beacons : []}
          legendLabel={{
            [HealthKey.RED]: critical,
            [HealthKey.YELLOW]: moderate,
            [HealthKey.GREEN]: healthy,
            [HealthKey.TOTAL]: total,
          }}
        />
      )
    default:
      return null
  }
}
