import {
  AUTH_CONFIRM_EMAIL,
  AUTH_LOGIN,
  AUTH_REGISTER,
  AUTH_RESEND_PASSWORD,
  AUTH_RESEND_CONFIRM,
  AUTH_UPDATE_PASSWORD,
  REDIRECT_URL_PARAM,
  JWT_TOKEN,
} from 'lib/constants'
import { postRequest, putRequest } from 'models/api/rest'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'

export const loginUser = (email: string, password: string): Promise<string> => {
  return postRequest({
    endpoint: AUTH_LOGIN,
    data: { user: { email, password } },
  }).then(({ data }) => {
    localStorage.setItem(JWT_TOKEN, data.token)
    return data.token
  })
}

export const registerUser = (email: string, password: string): Promise<string> => {
  return postRequest({
    endpoint: AUTH_REGISTER,
    data: { user: { email, password } },
  }).then(({ data }) => data.token)
}

export const resendAuthEmail = (email: string, url: string, forgotPassword?: boolean): Promise<string | number> => {
  const translation: TranslationGroup = trans.group(TranslationKey.AUTH)
  const endpoint = forgotPassword ? AUTH_RESEND_PASSWORD : AUTH_RESEND_CONFIRM
  return postRequest({
    endpoint: `${endpoint}/?${REDIRECT_URL_PARAM}=${url}`,
    data: { user: { email } },
  })
    .then(() => translation.email_resent)
    .catch(({ response: { status } }) => status)
}

export const updatePassword = (
  password: string,
  confirmation: string,
  token?: string,
  firstName?: string,
  lastName?: string,
  reset?: boolean,
): Promise<boolean> => {
  const endpoint = reset ? AUTH_UPDATE_PASSWORD : AUTH_CONFIRM_EMAIL
  const func = reset ? putRequest : postRequest
  if (token) {
    return func({
      endpoint: `${endpoint}/${token}`,
      data: { user: { firstName, lastName, password, passwordConfirmation: confirmation } },
    }).then(() => true)
  } else {
    return new Promise(resolve => resolve(false))
  }
}
