/** @jsxImportSource @emotion/react */
import { PropertyGroupAssignment, GroupPropertyAssignment, OrganizationDetailsForm, OrgUsersTable } from './Partials'
import { StyledHeader, StyledMetaData } from 'components/widgets'
import { ALL_ORGS_SELECTED, getAppColors } from 'lib/constants'
import { Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ChevronLeft } from '@material-ui/icons'
import { NavState, Orgs } from 'models'
import { useGlobal } from 'contexts/GlobalProvider'
import { useAdmin } from 'contexts/AdminProvider'
interface EditOrgViewProps {
  org: Organization
}

export const EditOrgView = ({ org }: EditOrgViewProps) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN)

  const { setSelectedAdminOrgId } = useAdmin()

  const orgIsGroup = Orgs.isGroup(org)
  return (
    <>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            flex: '1',
          }}
        >
          <StyledHeader>{org.name}</StyledHeader>
          <StyledMetaData>id: {org.id}</StyledMetaData>
        </div>
        <div css={{ display: 'flex', flexDirection: 'column', padding: '13px' }}>
          <div
            css={{
              display: 'flex',
              color: getAppColors('--color-brand'),
              cursor: 'pointer',
              justifyContent: 'flex-end',
              ':hover': { textDecoration: 'underline' },
            }}
            onClick={() => {
              // NavState.setSelectedAdminOrgId()
              setSelectedAdminOrgId(ALL_ORGS_SELECTED)
            }}
          >
            <ChevronLeft css={{ margin: 'auto 0' }} />
            <div css={{ margin: 'auto 0' }}>
              {translation.back_to_all} {orgIsGroup ? translation.groups : translation.properties}
            </div>
          </div>
        </div>
      </div>

      <OrganizationDetailsForm organization={org} editAsGroup={orgIsGroup} />
      {orgIsGroup ? (
        <GroupPropertyAssignment selectedGroup={org} />
      ) : (
        <PropertyGroupAssignment selectedProperty={org} />
      )}
      <OrgUsersTable selectedOrg={org} />
    </>
  )
}
