import { getRequest, putRequest, postRequest, deleteRequest, getRequestV5 } from './rest'
import { Organization } from 'lib/types'
import { API_ORGANIZATIONS, API_V5_ORGANIZATIONS } from 'lib/constants'
import { getChildOrgIdsByParentId } from './childOrgs'
import { decodeJsonApiObject } from './ApiLib'
import { SortOrder } from 'lib/types/tableTools'

export const getOrganization = (id: string): Promise<Organization> =>
  getRequest({ endpoint: `${API_V5_ORGANIZATIONS}/${id}?summary=true` }).then(({ data }) =>
    data?.data || []
  )

export const updateOrganization = (org: Organization): Promise<any> =>
  putRequest({ 
    endpoint: `${API_V5_ORGANIZATIONS}/${org.id}`,
    data: org 
  }).then(({ data }: any) => data)

export const getAllOrganizationsRaw = (): Promise<any[]> =>
  getRequest({ endpoint: `${API_ORGANIZATIONS}` }).then(({ data }) => data?.data || [])

export const getAllOrganizations = (): Promise<Organization[]> =>
  getAllOrganizationsRaw().then(rawOrgs => rawOrgs.map(a => decodeJsonApiObject<Organization>(a)))

export const getOrganizationsV5 = (search?: string): Promise<Organization[]> =>
  getRequestV5({
    endpoint: '/orgs',
    headers: {},
    params: {
      sortBy: { field: 'name', order: SortOrder.Ascending },
      searchBy: search ? { value: search } : undefined,
      custom: {
        summary: 'true',
      },
    },
  }).then(response => response.data.data) // Extract data from the Axios response

export const createOrUpdateOrg = (orgWithId: Organization): Promise<Organization> => {
  const { id, ...org } = orgWithId
  return (
    id
      ? putRequest({
          endpoint: `${API_ORGANIZATIONS}/${id}`,
          data: { org },
        })
      : postRequest({
          endpoint: `${API_ORGANIZATIONS}`,
          data: { org },
        })
  ).then(({ data }) => decodeJsonApiObject<Organization>(data?.data || []))
}

export const getChildOrgsByParentId = (parentId: string): Promise<Organization[]> =>
  getChildOrgIdsByParentId(parentId).then(childIds => Promise.all(childIds.map(getOrganization)))

export const deleteOrg = (orgId: string) =>
  deleteRequest({
    endpoint: `${API_ORGANIZATIONS}/${orgId}`,
  })

export const getAllOrganizationsNames = (): Promise<Organization[]> =>
  getRequest({ endpoint: `${API_ORGANIZATIONS}` })
    .then(({ data }) => data?.data || [])
    .then(rawOrgs =>
      rawOrgs.map((a: Organization) => {
        const org = decodeJsonApiObject<Organization>(a)
        return { id: org.id, name: org.name }
      }),
    )
