import { CreateProperty } from 'components/partials/CreateProperty'
import React, { useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import {
  StyledDialogTitle,
} from 'components/widgets'
import {  trans, TranslationGroup, TranslationKey } from 'lib/types'
import { group } from 'console'

type Props = {
  open: boolean
  setModalOpen: Function
  groupsMode: boolean
  closeModal: Function
}

const CreateOrgModal = ({ open, setModalOpen, groupsMode = false}: Props) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.ADMIN)

  return (
      <Dialog style={{ animationFillMode: 'forwards'}}  open={open} onClose={() => setModalOpen(false)} maxWidth="sm" fullWidth>
        <StyledDialogTitle>{`${translation.create} ${groupsMode ? translation.group : translation.property}`}</StyledDialogTitle>
        <DialogContent>    
          <CreateProperty groupMode={groupsMode} closeModal={() => setModalOpen(false)} />
        </DialogContent>
      </Dialog>

  )
}

export default CreateOrgModal