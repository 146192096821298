import alertSoundFile from 'static/audio/alert-sound.mp3'
import { Howl, Howler } from 'howler'

const alertSound = new Howl({
  src: [alertSoundFile],
})

// export const playAlertSound = () => alertSound.play()

/** New sound function */
export const playAlertSound = () => {
  if (Howler.ctx.state !== 'suspended') alertSound.play() // initial sound
  // const repeat = setInterval(() => {
  //   if (Howler.ctx.state !== 'suspended') alertSound.play()
  // }, 10000)
  // document.addEventListener(
  //   'mousemove',
  //   () => {
  //     clearInterval(repeat)
  //   },
  //   { once: true },
  // )
}

Howler.ctx.onstatechange = () => {
  console.log('AudioContext changed: ', Howler.ctx.state)
  if (Howler.ctx.state === 'suspended') Howler.ctx.resume()
}
