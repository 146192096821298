import { SetStateAction, useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { PRIMARY_COLOR } from 'lib/constants'
import { MenuItem, Select, FormControl, Button, FormControlLabel, Switch, FormLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Organization, trans, TranslationGroup, TranslationKey } from 'lib/types'
import { Loader, LoaderType, StyledInput } from 'components/widgets'
import { isNumber } from 'lib/utils'
import { createOrUpdateOrg } from 'models/api'
import { StyledH2 } from 'components/legacy-poorly-defined/Common'
import { reload } from 'models/Orgs'

// import { StyledTitleText } from './Common'

const StyledContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  span: {
    flex: 1,
  },
})
const StyledBoldText = styled.div({
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '1.2rem',
  lineHeight: '1.9rem',
  color: '#242424',
})

const StyledTitleText = styled.div({
  color: PRIMARY_COLOR,
  fontFamily: 'Montserrat',
  fontSize: '1.8rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '2.9rem',
  letterSpacing: '0',
  textAlign: 'left',
})

const StyledButton = styled(Button)({
  color: PRIMARY_COLOR,
  padding: '8px 20px',
  border: `2px solid ${PRIMARY_COLOR}`,
  boxSizing: 'border-box',
  fontFamily: 'Montserrat',
  boxShadow: '0px 4px 8px rgba(20, 48, 72, 0.1)',
  fontSize: '1.0rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  lineHeight: '1.2rem',
  letterSpacing: '0.1rem',
  textAlign: 'left',
  marginRight: '5px',
  cursor: 'pointer',
  borderRadius: '2px',
})
const SwitchLabel = styled.div({
  fontSize: '1.0rem',
})

const Toggle = styled.div({
  flex: 1,
})

const Toggles = styled.div({
  display: 'flex',
  margin: '10px',
})

const ToggleSwitch = withStyles({
  switchBase: {
    color: 'white',
    '&$checked': {
      color: 'rgba(48, 188, 176, 1)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgba(48, 188, 176, 0.33)',
    },
  },
  checked: {},
  track: {},
})(Switch)

type Props = {
  org: Organization | null
}

const GeofenceSettings = ({ org }: Props) => {
  const translation: TranslationGroup = trans.merge(TranslationKey.SETTINGS)

  const [enabled, setEnabled] = useState(org?.geofencingEnabled || false)
  const [radius, setRadius] = useState<string | number | undefined>(org?.responderRadiusInMiles)
  const [lat, setLat] = useState<string | number | undefined>(org?.lat || '')
  const [long, setLong] = useState<string | number | undefined>(org?.long || '')
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [successMsg, setSuccessMsg] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const handleApply = async () => {
    setLoading(true)
    setError(false)
    setErrorMsg('')
    setSuccessMsg(null)

    // validation
    if (!isNumber(radius) || Number(radius) < 0) {
      setError(true)
      setErrorMsg('Radius must be a number and be greater than 0')
      return
    }
    if (!isNumber(lat)) {
      setError(true)
      setErrorMsg('Latitude must be a number')
      return
    }
    if (!isNumber(long)) {
      setError(true)
      setErrorMsg('Longitude must be a number')
      return
    }
    console.log({
      ...org,
      geofencingEnabled: enabled,
      lat: lat,
      long: long,
      responderRadiusInMiles: radius,
    })
    org &&
      createOrUpdateOrg({
        ...org,
        geofencingEnabled: enabled,
        lat: Number(lat),
        long: Number(long),
        responderRadiusInMiles: Number(radius),
      }).then(res => {
        setEnabled(Boolean(res?.geofencingEnabled))
        setRadius(res?.responderRadiusInMiles)
        setLat(res?.lat)
        setLong(res?.long)
        reload().then(res => {
          setLoading(false)
          setSuccessMsg('Successfully updated')
        })
      })
    return
  }

  const handleCancel = () => {
    setRadius(org?.responderRadiusInMiles)
    setLat(org?.lat || '')
    setLong(org?.long || '')
    setSuccessMsg(null)
    setError(false)
    setErrorMsg('')
    return
  }

  useEffect(() => {
    console.log('lat', lat)
  }, [lat])
  useEffect(() => {
    console.log('long', long)
  }, [long])

  return (
    <StyledContainer>
      <StyledH2>Responder Geofence</StyledH2>
      <StyledBoldText>
        Enable and set responders online or offline when entering or leaving the geofence radius
      </StyledBoldText>
      <Toggles>
        <Toggle>
          <FormControlLabel
            control={<ToggleSwitch checked={enabled} onChange={() => setEnabled(!enabled)} name="activate" />}
            label={<SwitchLabel>{enabled ? 'Enabled' : 'Disabled'}</SwitchLabel>}
          />
        </Toggle>
      </Toggles>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledInput
          style={{ width: '200px', margin: 0 }}
          disableUnderline
          placeholder="radius"
          value={radius}
          onChange={e => setRadius(e.target.value)}
        />
        <FormLabel style={{ marginLeft: '10px' }}>miles</FormLabel>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledInput
          style={{ width: '200px' }}
          disableUnderline
          placeholder="latitude"
          value={lat}
          onChange={e => setLat(e.target.value)}
        />
        <FormLabel style={{ marginLeft: '10px' }}>latitude</FormLabel>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledInput
          style={{ width: '200px' }}
          disableUnderline
          placeholder="longitude"
          value={long}
          onChange={e => setLong(e.target.value)}
        />
        <FormLabel style={{ marginLeft: '10px' }}>longitude</FormLabel>
      </div>
      <div>
        <StyledButton onClick={handleCancel}>{translation.cancel}</StyledButton>
        <StyledButton onClick={handleApply}>{translation.apply_changes}</StyledButton>
      </div>
      {loading && (
        <div style={{ marginTop: '10px' }}>
          <Loader type={LoaderType.ROTATING_LINES} width="20px" height="20px" />
        </div>
      )}
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{errorMsg}</div>}
      {successMsg && <div style={{ color: PRIMARY_COLOR, marginTop: '10px' }}>{successMsg}</div>}
    </StyledContainer>
  )
}

export default GeofenceSettings
