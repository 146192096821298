import { shallowEqual } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { ReactComponent as BestLocationIcon } from 'static/images/dashboard/Best_Location.svg'
import { ReactComponent as HotelIcon } from 'static/images/dashboard/Hotel.svg'
import { TableFilterHeader } from 'components/partials/TableFilterHeader'
import OrgGlobalCard from './OrgGlobalCard/OrgGlobalCard'
import {
  getBestHealthLocation,
  sortOrganizations,
} from 'components/partials/_legacy/MainDashboardPage/components/utils'
import styles from './GlobalDashboard.module.css'
import { ChildOrgMap, NavState, Orgs } from 'models'
import { trans, TranslationGroup, TranslationKey } from 'lib/types'
import { ALL_ORGS_SELECTED } from 'lib/constants'
import { RenderCounter } from 'components/widgets/RenderCounter'
import { useGlobal } from 'contexts/GlobalProvider'
import { getRequestV5 } from 'models/api/rest'

function GlobalDashboard() {
  const { navItems, orgs, selectedOrg, setOrgSearchBar, loading } = useGlobal()
  const translation: TranslationGroup = trans.merge(TranslationKey.DASHBOARD)
  const options: Array<string> = JSON.parse(translation.sort_options)

  const handleSearchChange = (term: string) => {
    setOrgSearchBar(term.toLowerCase())
  }

  useEffect(() => {
    // cleanup to reset orgs when this component unmounts.
    return () => setOrgSearchBar(undefined)
  }, [])

  return (
    <>
      <RenderCounter name={'GlobalDashboard'} />
      <div className={styles.summaryContainer}>
        <div className={styles.summaryWrapper}>
          <HotelIcon className={styles.iconStyle} />
          {navItems.length} {translation.properties}
        </div>
        {orgs.length > 0 && (
          <div className={styles.summaryWrapper}>
            <BestLocationIcon className={styles.iconStyle} />
            {translation.best_health} : {getBestHealthLocation(orgs)}
          </div>
        )}
      </div>

      <TableFilterHeader
        sortOptions={options}
        showOptions={[]}
        onSortChange={(term: string) => {
          NavState.setOrgSortOrder(term)
        }}
        onSearchChange={handleSearchChange}
      />

      {selectedOrg ? (
        <OrgGlobalCard key={selectedOrg.id} organization={selectedOrg} />
      ) : (
        orgs.map(org => <OrgGlobalCard key={org.id} organization={org} />)
      )}
    </>
  )
}

export default GlobalDashboard
