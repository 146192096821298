import { shallowEqual } from 'react-redux'
import NotAuthorizedPage from 'components/pages/NotAuthorizedPage'
import { Orgs } from 'models'
import { Loader } from 'components/widgets'
import { memo } from 'react'
import { useGlobal } from 'contexts/GlobalProvider'

export const PortalRequired = memo(({ children }: { children: React.ReactChild }) => {
  // const { orgs } = Orgs.use(({ orgs, loading }) => {
  //   return { orgs, loading }
  // }, shallowEqual)
  const { loading, orgs } = useGlobal()

  // const loading = false
  return loading ? <Loader /> : <> {children}</>
})

PortalRequired.displayName = 'PortalRequired'
