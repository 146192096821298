/** @jsxImportSource @emotion/react */
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import MainPage from 'components/pages/MainPage'
import { Notification } from 'components/partials/Notification'
import PrivateRoute from 'components/partials/PrivateRoute'
import LoginPage from 'components/pages/LoginPage'
import NotAuthorizedPage from 'components/pages/NotAuthorizedPage'
import LandingPageSideImage from 'components/partials/LandingPageSideImage'
import { DASHBOARD_PATH, MOBILE_APP_PATH, NOT_AUTHORIZED_PATH, EMAIL_ALERTS_CONFIRMATION } from 'lib/constants'
import { StylesProvider } from '@material-ui/core/styles'
import { Provider } from 'hooks-for-redux'
import { responsiveLoginWrapperStyles } from 'styles'
import * as Models from 'models'
import MobileApp from 'components/pages/MobileApp'
import { useTranslation } from 'react-i18next'
import './App.css'
import EmailAlertsConfirmation from 'components/pages/EmailAlertsConfirmation'
import { GlobalProvider } from 'contexts/GlobalProvider'

let _global: any = window
_global.Models = Models

function App() {
  window._env_ ? console.log(`REACT_APP_ENV: `, window._env_.REACT_APP_ENV) : console.log(`No window._env_`)
  const { ready } = useTranslation('translations', { useSuspense: false })
  return (
    <>
      {ready && (
        <Provider>
          <StylesProvider injectFirst>
            <Notification />
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <div css={responsiveLoginWrapperStyles}>
                    <LandingPageSideImage />
                    <LoginPage />
                  </div>
                </Route>
                <Route path={`${MOBILE_APP_PATH}/:section?`}>
                  <div css={responsiveLoginWrapperStyles}>
                    <LandingPageSideImage />
                    <MobileApp />
                  </div>
                </Route>
                <Route path={NOT_AUTHORIZED_PATH}>
                  <div css={responsiveLoginWrapperStyles}>
                    <LandingPageSideImage />
                    <NotAuthorizedPage />
                  </div>
                </Route>
                <Route path={EMAIL_ALERTS_CONFIRMATION}>
                  <div css={responsiveLoginWrapperStyles}>
                    <LandingPageSideImage />
                    <EmailAlertsConfirmation />
                  </div>
                </Route>
                <GlobalProvider>
                  <PrivateRoute path={DASHBOARD_PATH} component={MainPage} />
                </GlobalProvider>
              </Switch>
            </BrowserRouter>
          </StylesProvider>
        </Provider>
      )}
    </>
  )
}

export default App
